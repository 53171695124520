import { ReactNode, useState } from "react"
import { Tooltip } from "@mui/material"
import ButtonEdit from "../../ui/buttons/button-edit"
import VisibilityIcon from '@mui/icons-material/Visibility'

interface IUserToolGridMenuComponent
{
    deleteEvent: any
    editEvent?: any
    buttonDeck?: JSX.Element | ReactNode | string
    data?: any
    className?: string
    readOnly?: boolean
}

export const UserToolGridMenuComponent = ({ className, deleteEvent, buttonDeck, editEvent, readOnly }: IUserToolGridMenuComponent) => {
    const [ toggleDelete, setToggleDelete ] = useState<boolean>(false)
    return (
        <div className={className || 'd-flex justift-content-end'}>
            { toggleDelete? 
            <>
                <Tooltip title="Really delete?"><button className="no-appearance" onClick={ deleteEvent }><i className="far fa-trash-alt red"></i></button></Tooltip>
                <Tooltip title="Cancel"><button className="no-appearance" onClick={() => setToggleDelete(false)}><i className="fas fa-ban"></i></button></Tooltip>
            </>
            : 
            <>
                { buttonDeck || null }
                { editEvent && !readOnly?
                <Tooltip title="Edit"><button className="no-appearance" onClick={ editEvent }><ButtonEdit text={<></>} /></button></Tooltip>
                : null }
                { editEvent && readOnly?
                <Tooltip title="View"><button className="no-appearance stnd black hover-opacity" onClick={ editEvent }><VisibilityIcon /></button></Tooltip>
                : null }
                { !readOnly? <Tooltip title="Delete"><button className="no-appearance" onClick={() => setToggleDelete(true)}><i className="far fa-trash-alt"></i></button></Tooltip> : null }
            </> }
        </div> 
    )
}