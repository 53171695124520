import { useState } from "react"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IContextChildren } from "../../interfaces/context-children.inteface"
import { empty } from "../../helpers/util.helper"

export interface IUploadCsvAlert extends IContextChildren
{
    errorMessage?: string | JSX.Element | React.ReactNode | null,
    errorClass: string | 'danger' | 'warning' | 'info' | 'success',
    onClick?: () => void,
    onCloseEvent?: () => void,
    alert?: boolean,
    allowClose?: boolean
    centered?: boolean
    icon?: JSX.Element
    className?: string
    bubble?: 'left' | 'right' | 'center'
    size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
}

export default function AlertBox( {
    children,
    errorMessage,
    errorClass,
    onClick,
    onCloseEvent,
    alert,
    allowClose,
    centered,
    icon,
    className,
    bubble,
    size
} : IUploadCsvAlert)
{
    const [ closed, setClosed ] = useState<boolean>(false);
    const exists: boolean = !empty(errorMessage) || !empty(children);
    return (
      exists && !closed &&  (
        <div
            className={`alert alert-${errorClass} ${ allowClose? 'buttoned' : '' } ${centered && 'align-middle'} ${className || ''} ${bubble? `bubble-${bubble}` : ''} ${size? `alert-${size}` : ''}`}
            onClick={ onClick? onClick : () => null }
            role="alert"
        >
            <div>
                { alert && <>{icon || <ErrorOutlineIcon /> }&nbsp;</> }
                { errorMessage || '' }
                { children || '' }
            </div>
            { allowClose &&
            <div className="align-middle"><i className="fas fa-times-circle" onClick={() => {
                if(onCloseEvent)
                    onCloseEvent()
                setClosed(!closed)
            }}></i></div> }
        </div>
        )
    )
}