import { Button, TextField } from "@mui/material"
import { UserToolsTagManagerUploaderBlock } from "./uploader.component"
import { UploadAnyFileComponent } from "../../upload"
import { nowDate } from "../../../helpers/util.helper"
import { useFormProcessingHook } from "../../../hooks/uploads.hook"
import { useContext, useState } from "react"
import { IAnyType } from "../../../interfaces/form-builder.interface"
import { AlertBase } from "../../ui/views/alerts"
import { IResponse } from "../../../interfaces/response.interface"
import { AlertContext } from "../../../providers/alert.provider"

interface ITagManagerCreateComponent
{
    title?: string
    curr: string
    hideUpload?: boolean
    hideTitle?: boolean
    tid?: string
    action?: 'update' | 'create' | 'upload_tags'
    onSuccessfulEvent?: (r: any) => void
    onFailedEvent?: (r: any) => void
    csvEnabled?: boolean
}

export const UserToolsTagManagerCreateComponent = ({
    hideTitle,
    title,
    curr,
    hideUpload,
    tid,
    action,
    onSuccessfulEvent,
    onFailedEvent,
    csvEnabled = true
}: ITagManagerCreateComponent) => {
    const [ formData, setFormData ] = useState<IAnyType<string>>({title: title || `Tags ${nowDate()}`});
    const [ resetForm, setResetForm ] = useState<boolean>(false);
    const [ submitSuccess, setSubmitSuccess ] = useState<IResponse>({} as IResponse);
    const { setType, setMsg } = useContext(AlertContext);
    const { setSubmitEvent, formSubmitState } = useFormProcessingHook({
        backend: true,
        uri: `tag-manager${curr === ''? (action || '') : `/${curr}/`}`,
        onSuccessEvent: (r) => {
            if(r.success) {
                setSubmitSuccess(r);
                setResetForm(true);
                onSuccessfulEvent && onSuccessfulEvent(r);
            } else {
                onFailedEvent && onFailedEvent(r);
                setMsg(r.error || 'An error occurred while processing your request.');
                setType(false);
            }
        },
        validate: { tag_start: (v: string) => v.length === 15, tag_end: (v: string) => v.length === 15 }
    });
    const updateFormData = (e: any) => setFormData({...formData, [e.target.name]: e.target.value});
    const clearForm = () => {
        const title: string = formData?.title || '';
        setFormData({ title })
    };
    return (
        <form onSubmit={ setSubmitEvent }>
            { tid && <input name='tag_group_id' value={ tid } type="hidden" /> }
            <input name='action' value={ action || 'create' } type="hidden" />
            { !formSubmitState?.loading && formSubmitState?.data?.error && <AlertBase type='danger' buttoned={true} text={ formSubmitState?.data?.error } /> }
            { submitSuccess?.success && <AlertBase type='success' buttoned={ true } text={ 'Tags created successfully.' } /> }
            { !hideTitle  && 
            (<div className="col-count-2 gapped col-c1-md">
                <TextField name='title' label='Tag Description' placeholder={`Tags ${nowDate()}`} value={ formData?.title || ''} onChange={ updateFormData } className='my-3' />
            </div>)}
            <UserToolsTagManagerUploaderBlock
                clearForm={ clearForm }
                useTabs
                resetForm={ resetForm }
                setResetForm={ setResetForm }
                csvEnabled={ csvEnabled }
            />
            { !hideUpload && (
            <div>
                <UploadAnyFileComponent
                    label={ "Upload Evidence of Ownership" }
                    id="tag-file"
                    fileTypes={ ['.jpg', '.jpeg', '.png', '.pdf'] }
                    ready={ !formSubmitState?.loading }
                    resetField={ resetForm }
                    setResetField={ setResetForm }
                    required
                    multiple
                />
            </div>)}
            <Button variant='contained' className='corp mt-4' type='submit' disabled={ formSubmitState.loading }>Submit</Button>
        </form>
    )
}