import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './account.slicer';
import notificationReducer from './internal.slicer';
import breedsAvailableReducer from './breeds.slicer';
//import groupsReducer from './group.slicer';
//import tokensReducer from './tokens.slicer';

const store = configureStore({
    reducer: {
        account: accountReducer,
        notification: notificationReducer,
        breedsAvailable: breedsAvailableReducer,
        // groups: groupsReducer,
        // tokens: tokensReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export default store;