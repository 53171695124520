import { createContext, useContext, useEffect, useState } from "react"
import { IContextChildren } from "../interfaces/context-children.inteface"
import { CalvingGroupsGetService } from "../services/calving.groups.service"
import { AccountContext } from "./account.provider"
import { empty } from "../helpers/util.helper"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { setBreeds } from "../redux/breeds.slicer"
import { BreedsGetAvailableService } from "../services/breeds"

interface IApplicationContextCurrenApp
{
    application?: any
    answers?: any
    flattened?: any
}

interface IApplicationContext
{
    currentApp: IApplicationContextCurrenApp
    setCurrentApp?: any
    editable: boolean | undefined
    appLoaded: boolean
    toggleForm: boolean
    setFormId?: any
    setFormState?: any
    setFormContent: any
    setToggleForm: any
    formState: IComponentState<any>,
    formId: string
    setAppLoaded: any
    formContent: JSX.Element
    setClearForm: any
    formWrapperStyle?: string
    setFormWrapperStyle?: any
    setReload: any
    setEditable?: any
    otherAppData?: any
    setOtherAppData?: any
    setBreedState?: (state: IComponentState<any>) => void
    breedState?: any
    breeds?: any
}

export const ApplicationContext = createContext<IApplicationContext>({
    formId: '',
    formState: ComponentStateDto,
    currentApp: {},
    editable: false,
    appLoaded: false,
    toggleForm: false,
    formContent: <></>,
    formWrapperStyle: '',
    otherAppData: {},
    breedState: ComponentStateDto,
    breeds: {},
    setToggleForm: () => {}, 
    setFormContent: () => {},
    setFormState: () => {},
    setAppLoaded: () => {},
    setClearForm: () => {},
    setFormWrapperStyle: () => {},
    setReload: () => {},
    setOtherAppData: () => {},
    setBreedState: () => {},
})

export const ApplicationProvider = ({ children }: IContextChildren) => {
    const [ formState, setFormState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ formId, setFormId ] = useState<string>('')
    const [ appLoaded, setAppLoaded ] = useState<boolean>(false)
    const [ currentApp, setCurrentApp ] = useState<IApplicationContextCurrenApp>({})
    const [ editable, setEditable ] = useState<boolean>(true)
    const [ toggleForm, setToggleForm ] = useState<boolean>(false)
    const [ formContent, setFormContent ] = useState<JSX.Element>(<></>)
    const [ clearForm, setClearForm ] = useState<boolean>(false)
    const [ reload, setReload ] = useState<boolean>(false)
    const [ formWrapperStyle, setFormWrapperStyle ] = useState<string>('')
    const [ otherAppData, setOtherAppData ] = useState<any>({})
    const { account } = useContext(AccountContext);

    const dispatch = useDispatch()
    const $_breedsState = useSelector((state: RootState) => state.breedsAvailable.state);
    const $_setBreedState = (state: IComponentState<any>) => dispatch(setBreeds(state))

    useEffect(() => {
        if(!$_breedsState.ready) {
            if(!$_breedsState.loading) {
                $_setBreedState(ComponentStateLoadingDto);
                BreedsGetAvailableService().then(r => {
                    if(r.success) {
                        $_setBreedState({...ComponentStateReadyDto, data: r.data});
                    } else {
                        $_setBreedState(ComponentStateReadyDto);
                    }
                })
            }
        }
    }, []);
    const setGroupEvent = () => {
        if(!empty(formId)) {
            setAppLoaded(false)
            setFormState(ComponentStateLoadingDto)
            CalvingGroupsGetService({gid: formId}).then(r => {
                if(r.success) {
                    setCurrentApp({ answers: r.data })
                    setAppLoaded(true)
                }
                setFormState(ComponentStateReadyDto)
                setReload(false)
            })
        } else {
            setFormState(ComponentStateReadyDto)
            setReload(false)
            setAppLoaded(true)
        }
    }

    if(clearForm) {
        setFormId('')
        setCurrentApp({answers: {}})
        setAppLoaded(true)
        setFormState(ComponentStateDto)
        setReload(true)
        setClearForm(false)
        setOtherAppData({})
    }

    useEffect(() => {
        if(!toggleForm && !empty(formId)) {
            setFormId('')
            setCurrentApp({answers: {}})
            setAppLoaded(true)
            setFormState(ComponentStateDto)
            setReload(true)
        }
    }, [ toggleForm ])

    useEffect(() => {
        if(reload) {
            setGroupEvent()
        }
    }, [ clearForm, reload ])

    useEffect(() => {
        if(account.isAdmin) {
            setEditable(false)
        }
    }, [ account.isAdmin ])

    return (
    <ApplicationContext.Provider value={{
        currentApp,
        setCurrentApp,
        editable,
        appLoaded,
        setEditable,
        setFormId,
        setFormState,
        setFormContent,
        setToggleForm,
        setAppLoaded,
        setClearForm,
        formContent,
        toggleForm,
        formState,
        formId,
        formWrapperStyle,
        setFormWrapperStyle,
        setReload,
        otherAppData,
        setOtherAppData,
        setBreedState: $_setBreedState,
        breedState: $_breedsState,
        breeds: $_breedsState.data
    }}>
        { children }
    </ApplicationContext.Provider>
    )
}