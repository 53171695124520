import { useEffect, useState } from "react"
import { IGroupsHook, useGroupTypesHook } from "../../../hooks/groups.hook"
import { groupStatusEnum } from "../../../enums/group.status.enum"
import { DataGrid, GridColDef, GridRowModel, GridRowModesModel } from "@mui/x-data-grid"
import { Button, Tooltip } from "@mui/material"
import { count as counter } from "../../../helpers/util.helper"
import { UploadSupportDocsComponent } from "./upload-support-docs-component"

export const GroupUploadSupportDocsComponent = ({ groups, count }: IGroupsHook) => {
    const [ unlocked, setUnlocked ] = useState<any[]>([])
    const { groupTypes, getItemValue } = useGroupTypesHook()
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({})
    const [ toggleUpload, setToggleUpload ] = useState<any>({})
    const updateAttribute = (e: any) => e

    useEffect(() => {
        const c: any[] = []
        if(count > 0) {
            groups.map(v => {
                if(v.status === groupStatusEnum.pending) {
                    c.push(v)
                }
            })
        }
        setUnlocked(c)
    }, [ groups ])

    const columns: GridColDef[] = [
        { field: 'group_type', headerName: '', width: 60, renderCell: (e) =>  <Tooltip title={getItemValue(e.row.group_type, groupTypes, 'description')}><div className={`abbr-dot color-${e.row.group_type}`}>{getItemValue(e.row.group_type, groupTypes, 'shortcode')?.substring(0,1).toUpperCase()}</div></Tooltip> },
        { field: 'group_name', headerName: 'Group Name', renderCell: (e) =>  <strong>{ e.row.group_name }</strong>, flex: 2 },
        { field: 'actions', headerName: 'Group Actions', minWidth: 130, renderCell: (e: any) => (<div> <Button onClick={() => setToggleUpload(e.row)}>Review</Button></div>)}
    ]

    return (
        unlocked.length > 0?
        <div className="mb-4 pb-4">
            <h6 className="uppercase span2 mb-1"><strong>Support Documents</strong></h6>
            <p>You have herd groups that may require support documents to be uploaded. Please review the groups below for upload requests.</p>
            { counter(toggleUpload) > 0? <UploadSupportDocsComponent  group={toggleUpload} onCancelEvent={() => setToggleUpload({})} /> : 
            <DataGrid
                getRowId={(e) => e.ID}
                //onProcessRowUpdateError={(e) => e}
                rowModesModel={ rowModesModel }
                onRowModesModelChange={ setRowModesModel }
                // onCellEditStart={(e) => {
                //     setDataTableTarget(e.field)
                // }}
                processRowUpdate={(e: GridRowModel) => {
                    updateAttribute(e)
                    return e
                }}
                rows={ count > 0? unlocked : [] }
                columns={ columns }
                checkboxSelection={false}
            />
            }
        </div>
        : null
    )
}