import { createContext, useEffect, useState } from "react"
import { IRouterModal } from "../router"
import { IContextChildren } from "../interfaces/context-children.inteface"
import { CheckoutProvider } from "./checkout.provider"
import { isBool } from "../helpers/util.helper"
import { cache, cacheGet } from "../services/cache"
import IComponentState, { ComponentStateDto } from "../interfaces/component-state.interface"
import './styles/modal.styles.scss'

interface IModalContext
{
    modalAttr: IRouterModal
    modalActive: boolean | any
    modalState: IComponentState<any>
    setModalBody: React.Dispatch<React.SetStateAction<string | JSX.Element>>
    setModalTitle: React.Dispatch<React.SetStateAction<string | JSX.Element>>
    setModalButtons: React.Dispatch<React.SetStateAction<string | JSX.Element>>
    setModalEvent?: React.Dispatch<React.SetStateAction<void | React.Dispatch<any> | undefined>>
    setModalState?: any
    setModalAttr?: any
    setModalActive?: any
}

export const ModalContext = createContext<IModalContext>({
    modalAttr: {},
    modalActive: false,
    setModalBody: () => {},
    setModalTitle: () => {},
    setModalButtons: () => {},
    setModalEvent: () => {},
    modalState: {
        ready: false,
        loading: true
    } })

const defaultModalState = ComponentStateDto

export const ModalWindowProvider = ({ children }: IContextChildren) => {
    const [ modalAttr, setModalAttr ] = useState<IRouterModal>({})
    const [ modalActive, setModalActive ] = useState<boolean | any>(false)
    const [ modalState, setModalState ] = useState<IComponentState<any>>(defaultModalState)
    
    const [ modalBody, setModalBody ] = useState<JSX.Element | string>('')
    const [ modalTitle, setModalTitle ] = useState<JSX.Element | string>('')
    const [ modalButtons, setModalButtons ] = useState<JSX.Element | string>('')
    const [ modalEvent, setModalEvent ] = useState<any | void >()
    const [ expand, setExpand ] = useState<boolean>(cacheGet('setting.modal.expand') === '1')

    const modalClassName: string = 'modal-body'
    
    useEffect(() => {
        if(!modalActive) {
            setModalTitle('')
            setModalBody('')
            setModalButtons('')
            setModalEvent(null)
            setModalState(defaultModalState)
            setModalAttr({})
        }
        if(modalActive && !isBool(modalActive)) {
            modalActive()
        }
    }, [ modalActive, modalState.ready ])

    const closeModalByClickOut = (e: any) => {
        if(e.target.classList.value === modalClassName) {
            setModalActive(false);
        }
        if(modalAttr.closeEvent) {
            modalAttr.closeEvent();
        }
    }

    return (
        <ModalContext.Provider
            value={{
                modalState,
                modalActive,
                modalAttr,
                setModalAttr,
                setModalState,
                setModalActive,
                setModalBody,
                setModalTitle,
                setModalButtons,
                setModalEvent
            }}>
            <CheckoutProvider>
                <>
                { children }
                { modalActive && isBool(modalActive) && !modalAttr.fullScreen?
                <div className={modalClassName} onClick={closeModalByClickOut}>
                    <div className={`modal-box ${expand? 'expanded' : ''} ${modalAttr.width? modalAttr.width : '' }`} style={modalAttr?.style || {}}>
                        <div className="modal-head">
                            <h6 className='m2 uppercase'>{ modalTitle }</h6>

                            <div className='align-middle pointer'>
                                <div className="col-count-2 gapped">
                                    <a href="#" className="m-0 zoom-in-out" onClick={ () => {
                                        const e: boolean = !expand
                                        cache('setting.modal.expand', e? '1' : '')
                                        setExpand(!expand)
                                    } }><i className={`fas fa-${expand? 'compress' : 'expand'}`}></i></a>
                                    <a href="#" className="m-0 zoom-in-out" onClick={ () => {
                                        if(modalEvent)
                                            modalEvent()
                                        setModalActive(false)
                                    } }><i className="fas fa-times"></i></a>
                                </div>
                            </div>

                        </div>
                        <div className="modal-content">
                            { modalBody }
                        </div>
                        <div className="modal-footer" id='modal-footer'>
                            { modalButtons }
                        </div>
                    </div>
                </div> : null }
                { modalActive && isBool(modalActive) && modalAttr.fullScreen? <div className="full-screen">{ modalBody }</div> : null }
                </>
            </CheckoutProvider>
        </ModalContext.Provider>
    )
}