import { CreateNewGroupButtonComponent } from "../../ui/buttons";

export const TagManagerPageNoRowsComponent = ({ onClickEvent }: { onClickEvent: () => void }) => (
    <div className='align-middle'>
        <CreateNewGroupButtonComponent
            resetGroup={ () => null }
            title={`Create Tags`}
            onClickEvent={ onClickEvent }
        />
    </div>
)