import { useContext, useEffect, useState } from "react"
import { UploadCsvAny } from "."
import { count, isNumeric } from "../../helpers/util.helper"
import { arrayKeys, arrayValues, inArray, loop } from "../../helpers/arrayworks.helper"
import { Button, Checkbox } from "@mui/material"
import { ButtonForward } from "../ui/buttons"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../interfaces/component-state.interface"
import HttpClient from "../../helpers/client"
import ButtonBack from "../ui/buttons/button-back"
import { AlertContext } from "../../providers/alert.provider"

const filterCols: string[] = [ 'PCT EID (Required)', 'Non-PCT Number (Optional)', 'Breed (Required)', 'Location (Required)', 'Tag Color (Optional)' ]

interface IUploadCsvCompareImportComponent
{
    gid: string
    setToggleUi: any
    onSuccessEvent?: any
}

interface IUploadCsvCompareImportDataComponent
{
    fromColumns: string[]
    fileName: string
    has_header_row?: boolean
}
interface IUploadCsvCompareImportStateComponent extends IComponentState<any>
{
    data: IUploadCsvCompareImportDataComponent
}

export const UploadCsvCompareImportComponent = ({ gid }: IUploadCsvCompareImportComponent) => {
    const defState = {...ComponentStateDto, data: { has_header_row: true, fromColumns: [], fileName: ''}}
    const [ state, setState ] = useState<IUploadCsvCompareImportStateComponent>(defState)
    const [ matchCols, setMatchCols ] = useState<string[]>(filterCols)
    const [ selected, setSelected ] = useState<string>('')
    const { setMsg, setType } = useContext(AlertContext)

    const onSubmitEvent = (formData: any, func: any) => {
        setMsg('')
        if(!formData)
            console.log(formData)
        if(!state.ready) {
            if(!state.loading) {
                setState((arr: any) => ({...arr, ...ComponentStateLoadingDto}))
                const formData = new FormData()
                const fileInput: any = document.getElementById('csv-uploader');
                if(fileInput && fileInput.files) {
                    formData.append('upload', fileInput?.files[0] || '')
                }
                formData.append('gid', gid);
                const fetchItem = async () => await (new HttpClient).upload(`import/inventory/compare`, formData).then((resp: any) => resp.json())
                fetchItem().then(r => {
                    func(!!r.data.success)
                    if(!r.data.success) {
                        setMsg(r.data.error)
                        setType(false)
                    }
                    setState((arr: any) => ({ ...arr, ...ComponentStateReadyDto, data: r.data }))
                })
            }
        }
    }

    const moveElement = (elementToMove: string, newIndex: number) => {
        const updatedElements = [...matchCols];
        const currentIndex = updatedElements.indexOf(elementToMove);
    
        if (currentIndex !== -1) {
          // Remove the element from its current position
          updatedElements.splice(currentIndex, 1);
    
          // Insert the element at the new position
          updatedElements.splice(newIndex, 0, elementToMove);
    
          // Update the state with the new array
          setMatchCols(updatedElements);
        }
      };

    const onSubmitMatchEvent = () => {
        if(!state.loading) {
            setState((arr: any) => ({...arr, ...ComponentStateLoadingDto}));
            (new HttpClient).post(`import/inventory/compare`, { matchCols, gid, fileName: state.data.fileName }).then((resp: any) => {
                setState((arr: any) => ({ ...arr, ...ComponentStateReadyDto, data: resp.data }))
            })
        }
    }

    const isMatching: boolean = !!state?.data?.fromColumns && count(state?.data?.fromColumns) > 0

    return (
        <div>
            { isMatching? 
                <div>
                    <p>Move the CPV Columns up or down to match your data columns on the left so the data matches in the system.</p>
                    <p><strong>Does your CSV file have a header row? <Checkbox value={state.data?.has_header_row || false} onChange={(e) => setState((arr: any) => ({ ...arr, data: {...state.data, has_header_row: e.target.checked } })) } /></strong><br /><span className="legal">If it does have a header row, we don't want to import it so make sure this is checked off so we can remove it on import. Likewise, if you do not have a header row on your CSV and you check this, you will remove the first animal on your CSV, but fear not, you can add it back individually or just reimport with the checkbox unchecked.</span></p>
                    
                    <table className="standard">
                        <tbody>
                            <tr>
                                <td style={{verticalAlign: 'top', width: '50%'}}>
                                    <p className="my-0 mb-3"><strong>Your CSV Columns</strong></p>
                                    <div className="left-columns border" style={{maxHeight: (5*33), overflow: 'auto'}}>{
                                        loop(state.data.fromColumns, (v: string, k: string) => (
                                            <div key={`csv-col-compare-${k}`} className=" p-1 border-bottom" style={{minHeight: 33}}><strong>{v}</strong></div>
                                        )) }
                                    </div>
                                </td>
                                <td style={{verticalAlign: 'top', width: '50%'}}>
                                    <p className="my-0 mb-3"><strong>CPV Columns</strong></p>
                                    <div className="right-columns border">
                                        {
                                            matchCols.map((r, k) => (
                                            <div key={`name-${k}`} className="d-flex justify-content-start p-1 border-bottom" style={{backgroundColor: selected === r? '#EBEBEB' : 'transparent'}}>
                                                <div className="d-flex justify-content-start gapped">
                                                    <Button size="small" variant="outlined" style={{minWidth: 0}} onClick={() => {
                                                        moveElement(r, k - 1)
                                                        setSelected(r)
                                                    }}><i className="fas fa-angle-up pointer"></i></Button>
                                                    <Button size="small" variant="outlined" style={{minWidth: 0}} onClick={() => {
                                                        moveElement(r, k + 1)
                                                        setSelected(r)
                                                    }}><i className="fas fa-angle-down pointer"></i></Button>
                                                    <strong>{r}</strong>
                                                </div>
                                            </div>))
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                : null
            }

            { !isMatching? <UploadCsvAny onSubmit={ onSubmitEvent } /> :
            <div className="align-middle">
                <div className="col-count-2 gapped mt-4">
                    <Button variant="outlined" onClick={() => setState(defState)}><ButtonBack text='Cancel' /></Button>
                    <Button variant="outlined" onClick={onSubmitMatchEvent}><ButtonForward text='Import' /></Button>
                </div>
            </div> }
        </div>
    )
}

export const UploadCsvCompareImportSelectorComponent = ({ gid, setToggleUi, onSuccessEvent }: IUploadCsvCompareImportComponent) => {
    const defSelected = {left: '', right: ''}
    const defState = {...ComponentStateDto, data: { has_header_row: true, fromColumns: [], fileName: ''}}
    const [ state, setState ] = useState<IUploadCsvCompareImportStateComponent>(defState)
    const [ matchCols, setMatchCols ] = useState<{[k: string]: number }>({})
    const [ selected, setSelected ] = useState<any>(defSelected)
    const { setMsg, setType } = useContext(AlertContext)

    const toMessage = (msg: string, type: boolean) => {
        setMsg(msg)
        setType(type)
    }

    const filterColors: string[] = [
        '#A7E8B4', // Pastel Green
        '#F9D6E1', // Pastel Pink
        '#B4D7E7', // Pastel Blue
        '#FBD3B0', // Pastel Orange
        '#E6C8F4', // Pastel Purple
        '#FFECB3'  // Pastel Yellow
      ]


    const getColorFrom = (name: any, def: string) => {
        let color = def
        if(isNumeric(name)) {
            return filterColors[name]
        }
        loop(filterCols, (v: string, k: number) => {
            if(v === name)
                color = filterColors[k]
        })
        return color
    }

    const onSubmitEvent = (formData: any, func: any, idVal?: string) => {
        if(!formData)
            console.log(formData)
        //if(!state.ready) {
            if(!state.loading) {
                setState((arr: any) => ({...arr, ...ComponentStateLoadingDto}))
                const formData = new FormData()
                const fileInput: any = document.getElementById(idVal || 'csv-uploader');
                if(fileInput && fileInput.files) {
                    formData.append('upload', fileInput?.files[0] || '')
                }
                formData.append('gid', gid);
                const fetchItem = async () => await (new HttpClient).upload(`import/inventory/compare`, formData).then((resp: any) => resp.json())
                fetchItem().then(r => {
                    func(!!r.data?.success, r)
                    setState((arr: any) => ({ ...arr, ...ComponentStateReadyDto, data:  { ...state.data, ...r.data} }))
                })
            }
        //}
    }

    const onSubmitMatchEvent = () => {
        if(count(matchCols) < 3) {
            toMessage('You have not matched enough columns to properly import animals.', false)
            return false
        }
        if(!state.loading) {
            setState((arr: any) => ({...arr, ...ComponentStateLoadingDto}));
            (new HttpClient).post(`import/inventory/compare`, { matchCols, gid, fileName: state.data.fileName, has_header_row: state.data?.has_header_row || false }).then((resp: any) => {
                if(resp.success) {
                    if(onSuccessEvent)
                        onSuccessEvent()
                    toMessage('Your file has been successfully uploaded.', true)
                } else {
                    toMessage(resp.error, false)
                }
                setState((arr: any) => ({ ...arr, ...ComponentStateReadyDto, data: { ...state.data, ...resp.data} }))
            })
        }
    }

    const isMatching: boolean = !!state?.data?.fromColumns && count(state?.data?.fromColumns) > 0
    
    useEffect(() => {
        if(selected.left !== '' && selected.right !== '') {
            setMatchCols((arr: any) => ({...arr, [selected.left]: selected.right }))
            setSelected(defSelected)
        }
    }, [ selected ])

    useEffect(() => {
        setToggleUi(isMatching)
    }, [isMatching])

    return (
        <div>
            { isMatching && state.ready? 
                <div>
                    <p>Select a value in the left column then select it's matching type in the right column. You are required to provide the following for each animal: breed, PCT EID, animal location.</p>
                    <p><strong>Does your CSV file have a header row? <Checkbox checked={ state.data?.has_header_row || false} value={ state.data?.has_header_row || false } onChange={(e) => setState((arr: any) => ({ ...arr, data: {...state.data, has_header_row: e.target.checked } })) } /></strong><br /><span className="legal">If it does have a header row, we don't want to import it so make sure this is checked off so we can remove it on import. Likewise, if you do not have a header row on your CSV and you check this, you will remove the first animal on your CSV, but fear not, you can add it back individually or just reimport with the checkbox unchecked.</span></p>
                    <table className="standard">
                        <tbody>
                            <tr>
                                <td style={{verticalAlign: 'top'}}>
                                    <p className="my-0 mb-3"><strong>Your CSV Columns</strong></p>
                                    <div className="left-columns border" style={{maxHeight: (5*45), overflow: 'auto'}}>{
                                        loop(state.data.fromColumns, (v: string, k: string) => (
                                            <div key={`csv-col-compare-${k}`} className=" p-1 border-bottom" style={{minHeight: 41, backgroundColor: (k !== '') && (selected.left === k || inArray(k, arrayKeys(matchCols, (val: any) => parseInt(val))))? getColorFrom(matchCols[k], '#EBEBEB') : 'transparent'  }}>
                                                <Button
                                                    fullWidth
                                                    variant="text"
                                                    className="justify-content-start"
                                                    style={{color: !matchCols[k]? 'rgb(25, 118, 210)' : '#000'}}
                                                    onClick={() => {
                                                    if(!inArray(k, arrayKeys(matchCols, (val: any) => parseInt(val))))
                                                        setSelected((arr: any) => ({...arr, left: k}))
                                                    else {
                                                        setSelected((arr: any) => ({...arr, left: ''}))
                                                        const f = {...matchCols}
                                                        delete f[k]
                                                        setMatchCols(f)
                                                    }
                                                } }>{v}{ matchCols[k]? <>&nbsp;<i className="fas fa-arrow-right"></i>&nbsp;{matchCols[k]}</> : '' }</Button>
                                            </div>
                                        )) }
                                    </div>
                                </td>
                                <td style={{verticalAlign: 'top', maxWidth: '50%'}}>
                                    <p className="my-0 mb-3"><strong>CPV Columns</strong></p>
                                    <div className={`right-columns border ${selected.left === ''? 'disabled' : ''}`}>
                                        { filterCols.map((r, k) => (
                                            <div key={`name-${k}`} className="d-flex justify-content-start p-1 border-bottom" style={{backgroundColor: selected.right === r || inArray(r, arrayValues(matchCols))? getColorFrom(k, '#EBEBEB') : 'transparent'}}>
                                                <Button className="justify-content-start" fullWidth variant="text" onClick={() => {
                                                    if(!inArray(r, arrayValues(matchCols)))
                                                        setSelected((arr: any) => ({...arr, right: r}))
                                                    else {
                                                        const f: any = {}
                                                        loop(f, (v: any, k: any) => {
                                                            if(v !== r) {
                                                                f[k] = v
                                                            }
                                                        })
                                                        setMatchCols(f)
                                                    }
                                                } }>{r}</Button>
                                            </div>)) }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                : null
            }

            { !isMatching? <UploadCsvAny onSubmit={ onSubmitEvent } /> :
            <div className="align-middle">
                <div className="col-count-2 gapped mt-4">
                    <Button variant="outlined" onClick={() => setState(defState)}><ButtonBack text='Cancel' /></Button>
                    <Button variant="outlined" onClick={onSubmitMatchEvent}><ButtonForward text='Import' /></Button>
                </div>
            </div> }
        </div>
    )
}