import HttpClient from "../helpers/client";
import { IResponse } from "../interfaces/response.interface";

interface IContactsGetService
{
    type: string
    destination_id?: string
}

export const ContactsGetService = (body: IContactsGetService) => (new HttpClient()).get<IResponse<any>>(`contacts/${body.type}${body?.destination_id? `/${body.destination_id}/` : ''}`)

export const ContactsSaveService = (body: any) => (new HttpClient()).post<IResponse<any>>(`contacts`, body)