export enum uploadStatusEnum
{
    approved,
    denied
}

export const getStatusCode = (status: any) => {
    if(!!status.accepted_at || !!status.denied_at)
        return status.accepted_at? 'Approved' : 'Denied'
    return 'Pending'
}