export const GroupsLeftOpen = (groups: any[]): boolean =>
{
    const allowed: number[] = []
    !!groups && groups.map((dto) => {
        if(dto.status && (dto.status !== 2 && dto.status !== 1)) {
            allowed.push(dto.ID)
        } else {
            if(!dto.status) {
                allowed.push(dto.ID)
            }
        }
        return dto
    })
    return allowed.length > 0
}

export const appGroupsComplete = (groups: any[]): boolean =>
{
    let allowed: boolean = true
    if(!groups)
        return allowed
    groups.map((dto) => {
        if(dto.map_id !== '') {
            if(dto.decentralized !== 1) {
                allowed = false
            }
        }
        return dto
    })
    return allowed
}