import { Tooltip } from "@mui/material";
import { empty } from "../../helpers/util.helper";
import { CowHeadIcon, FasBlockChain, FasFaReceipt } from "../ui/fontawesome";
import { groupStatusEnum } from "../../enums/group.status.enum";

interface IDropdownGroupStatusRow
{
    status: groupStatusEnum
    group_name: string
    herd_count: number
    certified_at: string | null
    paid: boolean | number | null
    decentralized: boolean | number | null 
}

export type IDropdownGroupStatus = {
    row: IDropdownGroupStatusRow
}

export default function DropdownGroupStatus({ row }: IDropdownGroupStatus)
{
    const { status, group_name, herd_count, certified_at, paid, decentralized } = row
    const locked = [groupStatusEnum.pending, groupStatusEnum.approved].includes(status)

    return (
        <div className="d-flex flex-props flex-row gapped align-items-center">
            <div className="pr-3">{ group_name }</div>
            <Tooltip title={`Herd is ${ locked? 'not' : ''} editable`}>
                <i className={`fas fa-lock${ locked? '' : '-open green' }`}></i>
            </Tooltip>
            <Tooltip title="Animals in current herd">
                <span><CowHeadIcon color={ herd_count && herd_count > 0? 'green' : 'grey'} before={`${herd_count || '0'} x `} size="xsm" /></span>
            </Tooltip>
            <Tooltip title={`Herd is ${ !empty(certified_at)? '' : 'not'} certified`}>
                <i className={`fas fa-award ${ !empty(certified_at)? 'green' : 'grey' }`}></i>
            </Tooltip>
            <Tooltip title={`Herd is ${ paid? '' : 'not'} paid for`}>
                <span><FasFaReceipt color={ paid? 'green' : 'grey' } /></span>
            </Tooltip>
            <Tooltip title={`Herd is ${ decentralized? '' : 'not'} decentralized`}>
                <span><FasBlockChain color={ decentralized? 'green' : 'grey'} /></span>
            </Tooltip>
        </div>
    )    
}