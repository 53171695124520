import { LoginResponse } from "../interfaces/account.login.interface"
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from "react"
import { Button } from "@mui/material"
import { environment } from "../environmentals/config"
import { cache } from "./cache"
import { BootStrapSpinner } from "../components/ui/views/spinner"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import useToken from "../helpers/token"
import HttpClient from "../helpers/client"
import ViewMainWindow from "../components/ui/views/main-window"
import ButtonBack from "../components/ui/buttons/button-back"

export type ISSORequest = {
    setToken: any,
    loggedIn: boolean,
    ssoLoggedIn: any,
    setAccount: any
}
/**
 * @description Fetches and stores accounts into cache
 */
export default function SSO()
{
    const [ searchParams ] = useSearchParams()
    const [ success, setSuccess ] = useState(false)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ nonce ] = useState(searchParams.get("nonce"))
    const { setToken } = useToken()
    // Fetch token
    const ssoToken = nonce
    // Fetch timestamp
    const time = searchParams.get("t")
    // If there is a token, try to log in
    if (nonce) {
        if(!state.ready) {
            if(!state.loading) {
                setState(ComponentStateLoadingDto)
                HttpClient.init().get<LoginResponse>(`sso`, { nonce: ssoToken, t: time }).then(r => {
                    if (r.success) {
                        // Set token for state
                        setToken(r.jwt)
                        // Set for reload use
                        cache('token', r.jwt)
                        // Make sso success
                        setSuccess(true)
                    }
                    setState(ComponentStateReadyDto)
                })
            }
        }
    }

    useEffect(() => {
        if(state.ready) {
            if(!nonce || success) {
                window.location.href = environment.defaultHome
            }
        }
    })

    return (
        state.ready && !success? <ViewMainWindow logoSize="small"><SingleSignOnFailed /></ViewMainWindow> : <ViewMainWindow logoSize="small"><BootStrapSpinner /></ViewMainWindow>
    )
}

export function SingleSignOnFailed()
{
    const homeLink = import.meta.env.VITE_ACCOUNT_LINK? import.meta.env.VITE_ACCOUNT_LINK : ''
    return (
    <div className="text-center maxw-sm">
        <h4>Invalid Single Sign On Attempt</h4><p>You may have used this single sign on token already. Go back to the account page and reload the page.</p>
        <div className="align-middle mt-3">
            <Button variant="outlined" onClick={() => window.location.replace(homeLink) }><ButtonBack /></Button>
        </div>
    </div>
    )
}