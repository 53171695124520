import HttpClient from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"

export interface ICertificationList
{
    ID: number
    name: string
    shortcode: string
    description: string
    angus_attr: string
    calving_attr: string
    status: string
    deleted_at: string
    updated_at: string
    created_at: string
    image: string
}

export interface ICertificationListObj
{
    [key: string]: ICertificationList
}

export const CertificationListService = () => (new HttpClient()).get<IResponse<ICertificationList[]>>('certifications')

export const CertificationObjListService = () => (new HttpClient()).get<IResponse<ICertificationListObj>>('certifications', { object: true })