import { Component, ErrorInfo, ReactNode } from 'react'
import { Button } from "@mui/material"
import ImageCompanyLogo from "../ui/image-company-logo"

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: '',
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.message || 'An error occurred' };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error Boundary caught an error:', error, errorInfo);
  }

  render() {
    try {
      if (this.state.hasError) {
        return (
          <ErrorMessage errorText={this.state.error} children={this.props.children} />
        );
      }
      return this.props.children;
    } catch (error: any) {
      console.error('Error Boundary caught an error:', error);
      return (
        <ErrorMessage errorText={error.message} children={this.props.children} />
      );
    }
  }
}

interface ErrorMessageProps {
  errorText: string;
  children: ReactNode;
}

function ErrorMessage({ errorText, children }: ErrorMessageProps) {

  let msg: string = errorText

  if(errorText.match('undefined is not')) {
    msg = 'There was an application error that occurred trying to attempt this action.'
  }

  return (
    <>
      { children }
      <div className="modal-body">
        <div className="modal-box">
          <div className="modal-head"></div>
          <div className="modal-content">
            <div className="align-middle mb-5 logo-central small">
              <ImageCompanyLogo />
            </div>
            <div className="align-middle">
              <h4>Whoops! My Bad.</h4>
              <h6><i className="fas fa-exclamation-circle"></i>&nbsp;{ msg }</h6>
              <p className='legal mt-3' style={{textAlign: 'center'}}>What we need to know if you contact us regarding this error: <code>{ errorText }</code></p>
              <Button className="mt-3" variant="contained" onClick={() => window.location.href = '/'}>Okay</Button>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </>
  )
}
