import { useContext, useEffect, useState } from "react"
import { ApplicationContext } from "../providers/application.provider"
import { SsoCreateService } from "../services/sso.service"
import { environment } from "../environmentals/config"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto } from "../interfaces/component-state.interface"

export const SSOHook = () => {
    const [ triggerSso, setTriggerSso ] = useState<boolean>(false)
    const [ uri, setUri ] = useState<string>('')
    const [ ssoState, setSsoState ] = useState<IComponentState<any>>(ComponentStateDto)
    const { setToggleForm } = useContext(ApplicationContext)

    useEffect(() => {
        if(!ssoState.loading && triggerSso) {
            setSsoState(ComponentStateLoadingDto)
            setToggleForm(false)
            SsoCreateService().then(r => {
                if(r.success && r.data !== '') {
                    window.location.href = `${environment.account}${uri? `${uri}/` : ''}?sso=${r.data}`
                }
                setSsoState(ComponentStateDto)
            })
        }
    }, [ triggerSso ])

    return {
        setTriggerSso, ssoState, setUri
    }
}