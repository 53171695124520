import { Button, Typography } from "@mui/material"
import { createContext, useContext, useState } from "react"
import { count, randomStr } from "../../../helpers/util.helper"
import { IAnyType } from "../../../interfaces/form-builder.interface"
import { ModuleComponentsActionsComponent } from "../components/actions"
import { ApplicationContext } from "../../../providers/application.provider"
import { IContextChildren } from "../../../interfaces/context-children.inteface"
import { BootStrapSpinner } from "../../ui/views/spinner"
import IComponentState from "../../../interfaces/component-state.interface"
import Alert from "../../upload/alert"

interface IModuleTemplatesWizardProviderComponent extends IContextChildren
{
    onSubmitForm: any
    onBackEvent?: any
    nextButton?: JSX.Element
    title?: string | JSX.Element
    description?: string | JSX.Element
    hidden?: boolean
    state: IComponentState<any>
}

interface IModuleTemplatesWizardProviderComponentContext
{
    setError: any
    setNextEvent: any
    nextEvent: IAnyType<any>
}

export const ModuleTemplatesWizardProviderComponentContext = createContext<IModuleTemplatesWizardProviderComponentContext>({setError: () => {}, setNextEvent: () => {}, nextEvent: {}})

export const ModuleTemplatesWizardProviderComponent = ({
    children,
    onSubmitForm,
    onBackEvent,
    nextButton,
    title,
    description,
    hidden,
    state
}: IModuleTemplatesWizardProviderComponent) => {
    const [ error, setError ] = useState<string>('')
    const [ nextEvent, setNextEvent ] = useState<IAnyType<any>>({})
    const { currentApp } = useContext(ApplicationContext)
    const idName = `submit-form-${randomStr(10)}`
    return (
    <ModuleTemplatesWizardProviderComponentContext.Provider
        value={{ setError, setNextEvent, nextEvent }}>
        <div hidden={ hidden || false}>
            <form
                action='#'
                id={idName}
                className='wizard-template col-count- gapped'
                onSubmit={ onSubmitForm }
            >
                <ModuleComponentsActionsComponent
                    appid={ currentApp?.application?.ID }
                    uid={currentApp?.application?.uid }
                />

                { title? (typeof title !== "string"? title : <Typography variant='h5' className='uppercase mb-0'>{title}</Typography>) : null }
                { description? (typeof description !== "string"? description : <p className='mt-0'>{description}</p>) : null }
                { children }
                { error !== '' && <Alert errorClass={ 'danger' } errorMessage={ error } /> }
                { state.loading? 
                <div className="align-middle">
                    <BootStrapSpinner />
                </div> :
                <div className="d-flex justify-content-center gapped mt-4">
                    { onBackEvent? <Button variant='outlined' onClick={ onBackEvent }><i className="fas fa-chevron-left"></i>&nbsp;Prev</Button> : null }
                    { nextButton? nextButton : <Button variant='contained' className='corp' type='submit' onClick={(e) => {
                        setError('')
                        if(count(nextEvent) > 0) {
                            const f: any = document.getElementById(idName)
                            const form = f? new FormData(f) : null
                            if(form) {
                                let allow = true
                                Object.keys(nextEvent).map(r => {
                                    const func = nextEvent[r]
                                    if(allow)
                                        allow = func(form)
                                })
                                if(!allow)
                                    e.preventDefault()
                            }
                        }
                    }} >Next&nbsp;<i className="fas fa-chevron-right"></i></Button>}
                </div>}
            </form>
        </div>
    </ModuleTemplatesWizardProviderComponentContext.Provider>
    )
}