import { useState, useEffect } from "react"
import { cache, cacheGet } from "../../../../services/cache"
import { Switch } from "@mui/material"

export default function ButtonNightShift(props?: any)
{
    const featureName = 'nightshift'
    const interfaceName = `interface.${featureName}`
    const [ nightShift, setNightShift ] = useState('0')
    const [ stateOfNightshift, setStateOfNightshift ] = useState((cacheGet(interfaceName) === '1')? '1' : '0')

    const setCache = (mode: string) => {
        setNightShift(mode)
        setStateOfNightshift(mode)
        cache(interfaceName, mode)
    }

    const switchMode = (mode: string) => {
        const body = document.body.classList
        if (mode === '1')
            body.add(featureName)
        else
            body.remove(featureName)
    }

    if(stateOfNightshift !== nightShift)
        setNightShift(stateOfNightshift)

    switchMode(nightShift)

    useEffect(() => {
        switchMode(nightShift)
    })

    return (
        props.hide?  null : <span>
            <Switch checked={nightShift === '1'} onChange={() => {
            const v = nightShift === '0'? '1' : '0'
            setCache(v)
        }} />&nbsp;{ nightShift === '1'? <i className="fas fa-sun"></i> : <i className="fas fa-moon"></i> }
        </span>
    )
}

export const switchMode = (mode: string) => {
    const body = document.body.classList
    if (mode === '1')
        body.add('nightshift')
    else
        body.remove('nightshift')
}