import { createContext, useContext, useEffect, useState } from "react"
import { InvoicesPayGeneral, InvoicesPayHeartlandDto } from "../services/invoice"
import { ModalContext } from "./modal.provider"
import { HeartlandIFrameComponent } from "../components/cpv-store/heartland-iframe"
import { IContextChildren } from "../interfaces/context-children.inteface"
import { count } from "../helpers/util.helper"

interface ICheckoutContext<T>
{
    setOrder: any
    order?: T
    setOnCancelEvent?: any
    setOnSuccessEvent?: any
    setOrderOpen?: any
}

export const CheckoutContext = createContext<ICheckoutContext<any>>({setOrder: () => {}})

export const CheckoutProvider = ({ children }: IContextChildren) => {
    const [ orderOpen, setOrderOpen ] = useState<boolean>(false)
    const [ order, setOrder ] = useState<any>({})
    const [ onCancelEvent, setOnCancelEvent ] = useState<any>(() => {})
    const [ onSuccessEvent, setOnSuccessEvent ] = useState<any>(() => {})
    const { setModalActive, setModalAttr, setModalBody } = useContext(ModalContext)

    if(count(order) > 0 && !orderOpen) {
        setOrderOpen(true)
        InvoicesPayGeneral<InvoicesPayHeartlandDto>(order).then(r => {
            if(r.success) {
                if(onSuccessEvent)
                    onSuccessEvent(r)
                else {
                    setModalAttr({fullScreen: true})
                    setModalActive(true)
                    setModalBody(<HeartlandIFrameComponent
                        cart={ {...r.data || {}, order_type: order.order_type || 'subscription', orderRequest: order } }
                        onCancelEvent={ onCancelEvent }
                    />)
                }
            }
            else
                throw new Error('An error occurred trying to create a payment.')
        })
    }

    useEffect(() => {
        if(count(order) === 0) {
            setModalActive(false)
            setOrderOpen(false)
        }
    }, [ order, orderOpen ])

    return (
        <CheckoutContext.Provider value={{
            setOrder,
            setOnCancelEvent,
            setOnSuccessEvent,
            setOrderOpen
        }}>
            { children }
        </CheckoutContext.Provider>
    )
}