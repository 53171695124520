import { IContextChildren } from "../interfaces/context-children.inteface"
import { empty } from "../helpers/util.helper"

export enum groupStatusEnum
{
    ready,
    approved,
    pending,
    certified,
    paid,
}

interface IGroupStatus extends IContextChildren
{
    status: number
    requires?: any
    enableOverrideOn?: any
}

interface IIsGroupStatus extends IGroupStatus
{
    groupStatus: number
}

interface IGroupStatusCertifiedAt extends IContextChildren
{
    group: any
}

export const IsGroupStatus = ({ groupStatus, status, requires, enableOverrideOn, children }: IIsGroupStatus) => {
    const alsoRequires: boolean = (requires)? requires() : true
    const isAllowed: boolean = (typeof enableOverrideOn === "function")? enableOverrideOn() : undefined
    if(!empty(isAllowed))
        return children
    return (groupStatus === status && alsoRequires)? children : <div className="disabled">{ children }</div>
}

export const GroupStatusReady = ({ children, status, requires, enableOverrideOn }: IGroupStatus) => {
    return <IsGroupStatus groupStatus={status} status={groupStatusEnum.ready} requires={requires} enableOverrideOn={enableOverrideOn}>{ children }</IsGroupStatus>
}

export const GroupStatusPending = ({ children, status, requires, enableOverrideOn  }: IGroupStatus) => {
    return <IsGroupStatus groupStatus={status} status={groupStatusEnum.pending} requires={requires} enableOverrideOn={enableOverrideOn}>{ children }</IsGroupStatus>
}

export const GroupStatusApproved = ({ children, status, requires, enableOverrideOn  }: IGroupStatus) => {
    return <IsGroupStatus groupStatus={status} status={groupStatusEnum.approved} requires={requires} enableOverrideOn={enableOverrideOn}>{ children }</IsGroupStatus>
}

export const GroupStatusPaid = ({ children, status, requires, enableOverrideOn  }: IGroupStatus) => {
    return <IsGroupStatus groupStatus={status} status={groupStatusEnum.paid} requires={requires} enableOverrideOn={enableOverrideOn}>{ children }</IsGroupStatus>
}

export const GroupStatusCertified = ({ children, status, requires, enableOverrideOn  }: IGroupStatus) => {
    return <IsGroupStatus groupStatus={status} status={groupStatusEnum.certified} requires={requires} enableOverrideOn={enableOverrideOn}>{ children }</IsGroupStatus>
}

export const GroupStatusCertifiedAt = ({ group, children }: IGroupStatusCertifiedAt) => {
    return (typeof group.certified_at === "string" && group.certified_at !== '')? <div className="disabled">{ children }</div> : children
}