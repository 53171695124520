import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { Button, CircularProgress, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material'
import { IShoppingCartPaymentRequest, ShoppingCartPaymentService } from '../services/shopping-cart.service'
import { CPVStoreOrderSuccessComponent } from '../order-success'
import { ICart } from '../helpers/cart.helper'
import { usd } from '../helpers/storefront.helper'
import { useRegionsHook } from '../../../hooks/regions.hook'
import { empty } from '../../../helpers/util.helper'
import { BootStrapSpinner } from '../../ui/views/spinner'
import { IAnyType } from '../../../interfaces/form-builder.interface'
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from '../../../interfaces/component-state.interface'
import ViewMainWindow, { viewMainWindowGreyBackgroundPreset } from '../../ui/views/main-window'
import 'https://js.globalpay.com/v1/globalpayments.js'
import './styles.scss'
import UploadCsvAlert from '../../upload/alert'

const requiredFields = [
    'address_1',
    'region',
    'postcode',
    'city'
]

export interface IPaymentResponse
{
    cardBin: number
    cardLast4: number
    cardNumber: string
    cardSecurityCode: boolean
    cardType: string
    cardholderName: string
    expiryMonth: number
    expiryYear: number
}

export const HeartlandIFrameComponent = ({ cart, onSubmitSuccessEvent, onCancelEvent }: ICart) => {
    const [ orderSuccess, setOrderSuccess ] = useState<any>({summary: {}, ready: false})
    const [ data, setData ] = useState<any>({order_type: cart.order_type || 'subscription'}) 
    const [ errors, setErrors ] = useState<string[]>([])
    const [ msg, setMsg ] = useState<any>({msg: '', type: false})
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ buttonState, setButtonState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ paymentState, setPaymentState ] = useState<IComponentState<any>>(ComponentStateDto)
    const { regions } = useRegionsHook()
    
    const onFreeProductSubmit = () => {
        const f: any = {...data, ...cart};
        f.order_type = 'freeherd';
        if(!buttonState.loading) {
            setButtonState(ComponentStateLoadingDto);
            ShoppingCartPaymentService(f).then((r: any) => {
                if(r.success) {
                    if(onSubmitSuccessEvent)
                        onSubmitSuccessEvent({ summary: r.data, ready: true })
                    else
                        setOrderSuccess({ summary: r.data, ready: true })
                } else {
                    setMsg({msg: r.error, type: false})
                }
                setButtonState(ComponentStateDto)
            });
        }
    }

    const cardForm = (error: any, success: any, GlobalPayments: any) => {
        const f = GlobalPayments.creditCard.form("#credit-card")
        f.on("token-success", (resp: any) => {
            // add payment token to form as a hidden input
            const token = document.createElement("input")
            token.type = "hidden"
            token.name = "payment_token"
            token.value = resp.paymentReference
            const form: any = document.getElementById("payment-form")
            form.appendChild(token)
            success(new FormData(form), resp.details)
        })
    
        f.on("token-error", (resp: any) => {
            error(resp)
        })
    }

    const error = (e: { error: boolean, reasons: {code: number, message: string }[] } ) => {
        const errors: string[] = []
        e.reasons.map((v) => {
            errors.push(v.message)
        })
        setMsg({type: false, msg: errors.join("\r\n")})
    }
    
    const success = (form: FormData, details: IPaymentResponse) => {
        setErrors([])
        setMsg({msg: '', type: false})
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            let allow: boolean = true
            const f: any | IShoppingCartPaymentRequest = { ...cart, ...details }

            form.forEach((v, k: any) => {
                if(requiredFields.includes(k) && v === '') {
                    allow = false
                    setErrors(arr => [...arr, k])
                } else if(k === 'region' && v === 'Select') {
                    setErrors(arr => [...arr, k])
                    allow = false
                }
                f[k] = v
            })

            if(!f.cardholderName || empty(f.cardholderName.trim())) {
                allow = false
            }

            if(!allow) {
                setMsg({msg: 'You are missing required fields.', type: false})
                setState(ComponentStateDto)
            } else {
                // Run the payment action
                ShoppingCartPaymentService(f).then((r: any) => {
                    if(r.success) {
                        if(onSubmitSuccessEvent)
                            onSubmitSuccessEvent({ summary: r.data, ready: true })
                        else
                            setOrderSuccess({ summary: r.data, ready: true })
                    } else {
                        setMsg({msg: r.error, type: false})
                    }
                    setState(ComponentStateDto)
                })
            }
        }
    }

    useEffect(() => {
        if(!paymentState.ready) {
            const form: HTMLElement | null = document.getElementById("credit-card")
            if(form?.childElementCount === 0) {
                // @ts-ignore
                if(GlobalPayments) {
                    // @ts-ignore
                    GlobalPayments.configure({
                        publicApiKey: "pkapi_cert_VfZGEaJq4GRaf8VGeo"
                    })
                    // @ts-ignore
                    cardForm(error, success, GlobalPayments)
                    setPaymentState(ComponentStateReadyDto)
                }
            }
        }
    }, [])

    const onChangeEvent = (e: any, transformer?: any) => {
        const { name, value } = e.target
        setData((arr: any) => ({...arr, [name]: typeof transformer === "function"? transformer(value) : value }))
    }

    return (
        orderSuccess.ready? 
        <ViewMainWindow
            hideLogo
        >
            <CPVStoreOrderSuccessComponent {...orderSuccess} />
        </ViewMainWindow>
        :
        <ViewMainWindow
            {...{...viewMainWindowGreyBackgroundPreset}}
        >
            <div className="col-count-3 gapped-2 col-c1-lg" id='cart-onetime-payment' style={{minHeight: cart.total <= 0? '100vh' : '0'}}>
                <div className='span2 span1-lg'>
                    <h4 className='main-header mb-4 uppercase'>Cart Summary</h4>
                    <div className='shadow p-3 bg-white rounded border' id="cart-summary-item-list">
                        { cart.item_codes.map((v, k) => (
                        <div key={`cart-item-${k}`} className="cart-summary-wrapper">
                            <div className="cart-thumbnail">
                                <img src={ v.image } alt={`${v.title} Image`} />
                            </div>
                            <div className="cart-body d-flex align-items-center">
                                <div>
                                    <Typography className='m-0' variant='h6'>{ v.title }</Typography>
                                    { typeof v.description === "string"? <p className='m-0' dangerouslySetInnerHTML={{__html: v.description }} ></p> :  v.description }
                                </div>
                            </div>
                            <div className="cart-subtotal col-count-3 gapped">
                            <span></span><span><b>{v.qty}QTY</b></span><span><b>{ usd(v.subtotal) }</b></span>
                            </div>
                        </div>
                        )) }
                        <div className="cart-summary-wrapper cart-summary-totals">
                            <div className='col-count-2 col-c1-lg'>
                                <div></div>
                                <div>
                                    <div className='cart-subtotal'>
                                        <div>Subtotal</div>
                                        <div>{ usd(cart.subtotal) }</div>
                                    </div>
                                    { cart?.fees? 
                                    (<div className='cart-fees'>
                                        <div>Fees</div>
                                        <div>{ usd(cart.fees || 0) }</div>
                                    </div>) : null }
                                    <div className='cart-tax'>
                                        <div>Taxes</div>
                                        <div>{ usd(cart.tax) }</div>
                                    </div>
                                    <div className='cart-shipping'>
                                        <div>Shipping &amp; Handling</div>
                                        <div>{ usd(cart.shipping) }</div>
                                    </div>
                                    <div className='cart-total'>
                                        <div>Total</div>
                                        <div>{ usd(cart.total) }</div>
                                    </div>
                                </div>
                            </div>
                            { onCancelEvent? <div className="align-middle">
                                <Button variant='outlined' size='small' className='corp' onClick={onCancelEvent} disabled={state.loading}>Cancel</Button>
                            </div> : null }
                        </div>
                    </div>
                </div>
                <div>
                    <h4 className='main-header mb-4 uppercase'>Credit Card Payment</h4>
                    <div className='rel'>
                        { state.loading && <div className="rounded full-cover align-middle bkg-semitrans"><BootStrapSpinner /></div> }

                        { !state.loading && cart.total > 0 && (
                        <div className={`shadow p-3 pb-5 bg-white rounded border ${state.loading? 'disabled' : ''}`}>
                            <div>
                                <HeartlandIFrameFormComponent
                                    data={ data }
                                    onChangeEvent={ onChangeEvent }
                                    errors={ errors }
                                    regions={ regions }
                                    setData={ setData }
                                    msg={ msg }
                                />
                            </div>
                        </div>)
                        }

                        { !state.loading && cart.total <= 0 && (
                        <div className="shadow p-3 pb-5 bg-white rounded border">
                            <UploadCsvAlert errorClass={ `info` } errorMessage={ `It looks like you have an order that is free!` } />
                            <HeartlandIFrameFormComponent
                                id="free-payment-form"
                                data={ data }
                                onChangeEvent={ onChangeEvent }
                                errors={ errors }
                                regions={ regions }
                                setData={ setData }
                                msg={ msg }
                                useCreditCard={ false }
                            />
                            <div className="align-middle mt-4">
                                <Button variant={buttonState?.loading? 'text' : 'outlined'} size='small' className={buttonState?.loading? 'no-appearance' : 'corp'} onClick={ onFreeProductSubmit } disabled={ buttonState?.loading }>{buttonState.loading? <CircularProgress /> : 'Complete Order'}</Button>
                            </div>
                        </div>)
                        }
                    </div>
                </div>
            </div>
        </ViewMainWindow>
    )
}

export const HeartlandIFrameFormComponent = (
    { children, data, onChangeEvent, errors, regions, setData, msg, onSubmitEvent, useCreditCard = true, id = 'payment-form' } :
    { children?: JSX.Element | ReactNode, id?: string, data: IAnyType<any>, onChangeEvent: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>, f?: (v: string) => string) => void, errors: any[], regions: IComponentState<any>, setData: (data: any) => void, onSubmitEvent?: (e: React.FormEvent<HTMLFormElement>) => void, msg: {msg: string, type: boolean }, useCreditCard?: boolean }) => {

    return (
    <form id={ id } onSubmit={(e) => { if(typeof onSubmitEvent === "function") onSubmitEvent(e); } } method='post'>
        <div className="col-count-">
            <div>
                <InputLabel size='small' className='uppercase bold required'>Address 1</InputLabel>
                <TextField
                    className='mt-0 pt-0'
                    variant='outlined'
                    fullWidth
                    required
                    name='address_1'
                    value={data.address_1 || ''}
                    onChange={onChangeEvent}
                />
            </div>
            <div>
                <InputLabel size='small' className='uppercase bold'>Billing Suite / Box #</InputLabel>
                <TextField
                    className='mt-0 pt-0'
                    variant='outlined'
                    fullWidth
                    name='address_2'
                    value={data.address_2 || ''}
                    onChange={onChangeEvent}
                />
            </div>
            <div>
                <InputLabel size='small' className='uppercase bold required'>Billing City</InputLabel>
                <TextField
                    className='mt-0 pt-0'
                    variant='outlined'
                    fullWidth
                    name='city'
                    value={ data.city || '' }
                    onChange={ onChangeEvent }
                    error={errors.includes('city')}
                    helperText={errors.includes('city')? "Your city is required" : ''}
                />
            </div>
            <div>
                <InputLabel size='small' className='uppercase bold'>Billing Country</InputLabel>
                <Select
                    fullWidth
                    variant='outlined'
                    name='country'
                    value={data.country || 'US'}
                    onChange={e => onChangeEvent(e)}
                    required
                >
                    <MenuItem value=''>Select</MenuItem>
                    <MenuItem value='US'>United States</MenuItem>
                </Select>
            </div>
            <div>
                <InputLabel size='small' className='uppercase bold required'>Billing State</InputLabel>
                { regions.ready? 
                <Select
                    fullWidth
                    name='region'
                    value={data.region || ''}
                    onChange={ e => onChangeEvent(e) }
                    required
                    error={errors.includes('region')}
                >
                    {
                        regions.data.map((r: any, k: number) => (
                            <MenuItem key={`region-${k}`} value={ r.value }>{ r.label }</MenuItem>
                        ))
                    }
                </Select> : <BootStrapSpinner /> }
            </div>
            <div>
                <InputLabel size='small' className='uppercase bold required'>Billing Post Code</InputLabel>
                <TextField
                    className='mt-0 pt-0'
                    variant='outlined'
                    fullWidth
                    name='postcode'
                    value={ data.postcode || '' }
                    onChange={e => onChangeEvent(e, (value: string) => value.replace(/[^\d]/gi, '').substring(0, 5))}
                    error={errors.includes('postcode')}
                    helperText={errors.includes('postcode')? "A post code is required" : ''}
                />
            </div>

            <div className='mb-4' hidden>
                <InputLabel size='small' className='uppercase bold'>Store payment for later?</InputLabel>
                <FormControlLabel
                    name='store_card'
                    value={data.store_card || false}
                    control={ <Switch /> }
                    label="Yes, save for later."
                    onChange={(e: any) => setData({...data, store_card: e.target?.checked || false})}
                />
            </div>

        </div>
        { useCreditCard && <div id="credit-card"></div> }
        { msg.msg !== '' && <UploadCsvAlert errorClass={ msg.type? 'success' : 'danger' } errorMessage={ msg.msg } /> }
        { children || null}
    </form>)
}