import HttpClient from "../helpers/client";

export function LocationsGetService(gid: string)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).get<any>(`locations/${gid}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}