import { Button, Typography } from "@mui/material";
import { GridColDef, GridRowModesModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { DataGridRowsBlockComponent } from "../../ui/blocks/data-grid.component";
import { filterMuiColumns } from "../../../helpers/arrayworks.helper";
import { toFileSize } from "../../../helpers/util.helper";
import { UploadAnyFileComponent } from "../../upload";
import { download } from "../../../helpers/files";
import { ButtonsTableMiniActions } from "../../ui/buttons";
import { TagManagerDeleteDocumentService } from "./tags.services";
import { useFormProcessingHook } from "../../../hooks/uploads.hook";
import BlockLoader from "../../ui/loader";

const TagDocsList = ({ tagGroup, docs, className, disabled, reloadList }: { reloadList: () => void, tagGroup?: any, disabled: boolean, docs: any[], className?: string }) => {
    const [ tag, setTag ] = useState<any>(undefined);
    const [ documents, setDocuments ] = useState<any[]>([]);
    const [ rowModesModel ] = useState<GridRowModesModel>({});
    const [ resetImage, setResetImage ] = useState<boolean>(false);

    const { setSubmitEvent, formSubmitState } = useFormProcessingHook({
        backend: true,
        uri: `tag-manager/update`,
        onSuccessEvent: (r) => {
            setDocuments(r.data?.rows || []);
            setResetImage(true);
            reloadList();
        },
    });

    const tagsColumns: GridColDef[] = [
        { field: 'created_at', headerName: 'Date', minWidth: 200, },
        { field: 'real_name', headerName: 'Name', flex: 1, minWidth: 200, },
        { field: 'size', headerName: 'Size', minWidth: 100, renderCell: (e: any) => <div>{ toFileSize(e.value) }</div> },
        { field: 'accepted_at', headerName: 'Verified', minWidth: 60, renderCell: (e: any) => <div className={ `badge bkg-${ e.value ? 'green' : 'red' }` }>{ e.value ? '' : 'UN' }VERIFIED</div> },
        { field: 'actions', headerName: '', cellClassName: 'justify-content-end', renderCell: (e: any) => (
            <ButtonsTableMiniActions
                onDownloadEvent={ () => download(e.row.ID) }
                onDeleteEvent={ () => {
                    TagManagerDeleteDocumentService({id: e.row.ID, action: 'doc', tid: tag.ID}).then(r => {
                        setDocuments(r.data?.rows || []);
                        reloadList();
                    });
                } }
                disableDelete={ disabled }
            />
        )},
    ];

    useEffect(() => {
        setTag(tagGroup);
    }, [ tagGroup ])
    

    useEffect(() => {
        setDocuments(docs);
    }, [ docs ]);

    useEffect(() => {
        if(resetImage) {
            setResetImage(false);
        }
    }, [ resetImage ]);
    

    return (
    <div className={className || ''}>
        <BlockLoader loading={ formSubmitState.loading }>
            { !disabled && (
            <div className="upload-more-docs mb-4">
                <Typography variant="h6" className="mb-1">Upload More Documents</Typography>
                    <form onSubmit={ setSubmitEvent } className="d-flex justify-content-start gapped align-items-center">
                        { tag?.ID && (
                            <>
                            <input type="hidden" name="tid" value={ tag.ID } />
                            </>
                        ) }
                        <UploadAnyFileComponent
                            id="evidence-tag-file"
                            fileTypes={ ['.jpg','.pdf','.jpeg'] }
                            resetField={ resetImage }
                            ready
                            required
                        />
                        <div className="align-middle">
                            <Button type="submit" size="small" variant="contained">Upload</Button>
                        </div>
                    </form>
            </div>) }
            { documents && documents.length > 0 && 
            <>
            <DataGridRowsBlockComponent
                processRowUpdate={ (e: any) => e }
                onRowsModelChange={ (e: any) => e }
                rowModesModel={ rowModesModel }
                columns={ filterMuiColumns(tagsColumns, documents || [], ['actions', 'verified_at']) }
                rows={ documents || [] }
                label={ '' }
            >
            </DataGridRowsBlockComponent>
            </>
            }
        </BlockLoader>
    </div>
    )
}

export default TagDocsList;