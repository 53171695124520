import { createContext, useState } from "react"
import { IContextChildren } from "../../../interfaces/context-children.inteface"

interface IModuleComponentsIncrementProviderComponent extends IContextChildren
{
    increment: string
    data: any
}

interface IModuleComponentsIncrementProviderComponentContext
{
    i: string
    defaultData: any
}

export const ModuleComponentsIncrementProviderComponentContext = createContext<IModuleComponentsIncrementProviderComponentContext>({i: '', defaultData: undefined })


export const ModuleComponentsIncrementProviderComponent = ({ children, increment, data }: IModuleComponentsIncrementProviderComponent) => {
    const [ i ] = useState<string>(increment)
    const [ defaultData ] = useState<any>(data)

    return (
    <ModuleComponentsIncrementProviderComponentContext.Provider
        value={{ i, defaultData }}
    >
        { children }
    </ModuleComponentsIncrementProviderComponentContext.Provider>
    )
}