import HttpClient from "../helpers/client"


export default function WizardService<T>(service: string): Promise<T>
{
    return new Promise<T>((success, fail) => {
        try {
            (new HttpClient()).get<T>(`wizard/${service}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function WizardServiceSave<T>(service: string, body: any): Promise<T>
{
    return new Promise<T>((success, fail) => {
        try {
            (new HttpClient()).post<T>(`wizard/${service}`, body).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function WizardServiceTesting<T>(service: string, body: any): Promise<T>
{
    return new Promise<T>((success, fail) => {
        try {
            (new HttpClient()).get<T>(`testing/${service}`, body).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function WizardServiceTestingSave<T>(service: string, body: any): Promise<T>
{
    return new Promise<T>((success, fail) => {
        try {
            (new HttpClient()).post<T>(`testing/${service}`, body).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}
