import { Button, Typography } from "@mui/material"
import { BootStrapSpinner } from "../../ui/views/spinner"

interface ICPVStoreOrderSuccessComponent
{
    summary: any
    ready: boolean
    onDoneEvent?: any
}

export const CPVStoreOrderSuccessComponent = ({ summary, ready, onDoneEvent }: ICPVStoreOrderSuccessComponent) => {
    return (
        ready? 
        <div>
            <div className="border rounded p-4">
                <Typography variant='h5' className='mb-3'>Thank you for your order!</Typography>
                <p>Your Payment ID is <strong>{ summary.payment_id }</strong>. A copy of your order has been sent to the email address on file.</p>
            </div>
            <div className="align-middle">
                <Button variant='contained' className='corp mt-4' onClick={() => (onDoneEvent)? onDoneEvent() : window.location.reload() }>Continue</Button>
            </div>
        </div> : <BootStrapSpinner />
    )
}