import { FormLabel } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useContext, useEffect, useState } from "react"
import { getVal } from "../submodule.base"
import dayjs from "dayjs"
import { ApplicationContext } from "../../../providers/application.provider"

interface IModuleComponentsDatePickerComponent
{
    required?: boolean
    label: string | JSX.Element
    name: string
    defaultValue?: string
}

export const ModuleComponentsDatePickerComponent = ({
    required,
    label,
    name,
    defaultValue
}: IModuleComponentsDatePickerComponent) => {
    const [ form, setForm ] = useState<any>({})
    const { editable } = useContext(ApplicationContext)
    const [ firstRun, setFirstRun ] = useState<boolean>(true)
    useEffect(() => {
        if(firstRun) {
            setForm({[name]: defaultValue || ''})
            setFirstRun(false)
        }
    }, [ firstRun ])

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel component="legend" className={`${required? 'required' : ''}`}>{ label }</FormLabel>
            <DatePicker
                value={ dayjs(getVal(form, name)) }
                onChange={ v => setForm((arr: any) => ({ ...arr, [name]: v?.toString() || ''}))}
                format="YYYY-MM-DD"
                slotProps={{
                    textField: {
                        helperText: 'YYYY-MM-DD',
                        required: true,
                        name: name
                    }
                }}
                disabled={ !editable }
            />
        </LocalizationProvider>
    )
}