import { useState } from "react"
import { FormDropdownSettings } from "../services/options"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"

export const useFormControlHook = () => {
    const [ formControl, setFormControl ] = useState<any>({})
    const [ formControlState, setFormControlState ] = useState<IComponentState<any>>(ComponentStateDto)

    if(!formControlState.ready) {
        if(!formControlState.loading) {
            setFormControlState(ComponentStateLoadingDto)
            FormDropdownSettings().then(r => {
                setFormControl(r.data)
                setFormControlState(ComponentStateReadyDto)
            })
        }
    }

    return {
        formControl,
        formControlState
    }
}