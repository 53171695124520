import { ReactNode, useContext, useEffect } from "react"
import { CreateNewGroupButtonComponent } from "../../components/ui/buttons"
import { ApplicationContext } from "../../providers/application.provider"
import { ComponentStateReadyDto } from "../../interfaces/component-state.interface"
import { BootStrapSpinner } from "../../components/ui/views/spinner"
import { AccountContext } from "../../providers/account.provider"
import { useNavigate } from "react-router-dom"
import { cacheGet } from "../../services/cache"
import TemplatesCattleProofCenterBlock from "./center-block"
import BlockLoaderTransparency from "../../components/ui/loader"

interface ITemplate
{
  children: any
  id?: string
  title?: JSX.Element | ReactNode | string
  description?: JSX.Element | ReactNode | string
  createButton?: JSX.Element | ReactNode | string
  backButtonLabel?: JSX.Element | ReactNode | string
  backButton?: string
  backButtonEvent?: any
  loading?: boolean
}

export default function Template({
  title,
  description,
  id,
  createButton,
  backButton,
  backButtonLabel,
  backButtonEvent,
  children,
  loading,
}: ITemplate)
{
  // const { setModalBody, setModalActive, setModalTitle, setModalAttr } = useContext(ModalContext)
  const {
    toggleForm,
    appLoaded,
    formContent,
    setFormContent,
    setFormState,
    setFormWrapperStyle,
    formWrapperStyle,
    formState
  } = useContext(ApplicationContext)
  const { account } = useContext(AccountContext)
  const nav = useNavigate()

  useEffect(() => {
    if(!toggleForm) {
      if(formWrapperStyle) {
        setFormWrapperStyle('start2')
        setFormContent(<BootStrapSpinner />)
      }
    }
  }, [ toggleForm ])

  useEffect(() => {
    if(!formState.ready && !formState.loading) {
      setFormState(ComponentStateReadyDto)
    }
  })

  return (
    <BlockLoaderTransparency loading={ loading || false }>
      <div id={ id || 'main-page-element' } className="main-block">
          {/* <div className="flipper-wrapper">
              <div className={`flip-container ${toggleForm? 'active' : ''}`}>
                  <div className="flipper">
                      <div className="front">
                          { children }
                      </div>
                      <div className="back">
                          <div className="col-count-3 offset gapped">
                              <div className="start2">
                                  { formContent }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
          <div className="fade-wrapper">
              <div className={`fade-container ${toggleForm? 'active' : ''}`}>
                  <div className="front">  
                    <div className="click-head-row">
                      <div className="d-flex justify-content-between mb-4 pb-2">
                        { title? <h4 className="main-header span2 mb-1">{ (backButton || backButtonEvent)? <button className="m-0 p-0 ignore-nightshift transparent stnd" onClick={() => backButtonEvent? backButtonEvent() : nav(backButton || '')}><span style={backButtonLabel? { color: cacheGet('interface.nightshift') === '1'? '#FFF' : '#999', fontSize: '1.2rem', textTransform: 'uppercase', fontWeight: 600, fontFamily: '"Montserrat", sans-serif', marginRight: '.5em' } : {}}>{ backButtonLabel || '' }</span><i className={`fas fa-chevron-left ${backButtonLabel? 'fa-sm' : ''}`}></i>&nbsp;</button> : null }{ title }</h4> : null }
                        { !account.isAdmin && account?.account_type !== 'buyer'? createButton || <CreateNewGroupButtonComponent resetGroup={() => {window.location.reload()}} /> : null }
                      </div>
                      { description? <p className="mt-2 mb-4 span2">{ description }</p> : null }
                    </div>
                    <div>
                        { children }
                    </div>
                  </div>
                  <div className="back">
                    <TemplatesCattleProofCenterBlock startClass={ formWrapperStyle !== ''? formWrapperStyle : "start2" }>
                      { appLoaded? formContent : <div className="align-middle p4 my-4 start2"><BootStrapSpinner size="x-sm" /></div> }
                    </TemplatesCattleProofCenterBlock>
                  </div>
              </div>
          </div>
          {/* <ResizeListener /> */}
      </div>
      </BlockLoaderTransparency>
  )
}