export function toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    )
  }

export const isString = (str: any): boolean => typeof str === 'string'

export const filterNumber = (str: string, length?: number): string => str.replace(/[^0-9]/g, '').substring(0, length? length : str.length)

export const highlighter = (str: string, highlight: string): JSX.Element => {
    if(highlight === '')
      return <>{str}</>;
    const regex = new RegExp(`(${highlight})`, 'gi')
    return (<span dangerouslySetInnerHTML={{__html: str.replace(regex, `<span class="bkg-light-grey txt-black">$1</span>`) }}></span>);
}