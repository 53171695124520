import { Button, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useContext, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import ICattleFormSingleViewDto, { CattleFormSingleViewDto } from "../../../dto/animal-record"
import { AngusDeleteService, AngusGetService, AngusSaveService, AngusUpdateService } from "../../../services/angus"
import { Animal, AnimalCreateService, AnimalDeleteService } from "../../../services/herd-groups"
import { GetOptionsService } from "../../../services/options"
import { AlertYinYang } from "../../ui/views/alerts"
import { BreedsGetAvailableService } from "../../../services/breeds"
import { LocationsGetService } from "../../../services/locations"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { ModalContext } from "../../../providers/modal.provider"
import { isAdmin } from "../../../helpers/account"
import { empty } from "../../../helpers/util.helper"
import { filterNumber } from "../../../helpers/stringworks.helper"
import { environment } from "../../../environmentals/config"
import { UpdateAnimalTagService } from "./services"
import { AlertContext } from "../../../providers/alert.provider"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import dayjs from 'dayjs'
import ButtonAdmin from "../../ui/buttons/button-admin"
import ButtonBack from "../../ui/buttons/button-back"
import ButtonDelete from "../../ui/buttons/button-delete"
import ButtonSave from "../../ui/buttons/button-save"
import AlertBox from "../../upload/alert"
import Alert from "../../ui/notifications/alerts.component"
import __ from "../../../services/translator"

export interface IBreed
{
    ID?: string
    hid?: string
    percent?: number
    dna_test_at?: string
    dna_test_number?: string
    dna_test_provider?: number
    sire_certified_at?: string
    created_at?: string
    deleted_at?: string
}

export interface ICattleRecordFormSingleView 
{
    animal: CattleFormSingleViewDto
    setUpdate: any
    reloadHerd: any
    gid?: string
    isNew?: boolean
}

export const CattleRecordFormSingleViewComponent = ({ animal, reloadHerd, gid, isNew }: ICattleRecordFormSingleView) => {
    const [ deceased ] = useState<boolean>(animal.death_date? true : false);
    const [ helpersLoaded, setHelpersLoaded ] = useState<any>({breeds: ComponentStateDto, locations: ComponentStateDto })
    const [ data, setData ] = useState<ICattleFormSingleViewDto>(animal)
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ breeds, setBreeds ] = useState<any[]>([])
    const [ locations, setLocations ] = useState<string[]>([])
    const [ closeWindow, setCloseWindow ] = useState<boolean>(false)
    const [ deleteAnimal, setDeleteAnimal ] = useState<boolean>(false)

    const [ replacementId, setReplacementId ] = useState<string>(data?.has_replacement_tag || '');
    const [ toggleReplace, setToggleReplace ] = useState<boolean>(false);
    const [ toggleConfirm, setToggleConfirm ] = useState<boolean>(false);

    const [ submittingUpdate, setSubmittingUpdate ] = useState<boolean>(false);
    
    const { handleSubmit } = useForm();
    const { setModalActive } = useContext(ModalContext);
    const { setMsg, setType } = useContext(AlertContext);

    const setHelperState = (name: string, status: IComponentState<any>) => {
        setHelpersLoaded((arr: any) => ({...arr, [name]: status }))
    }

    const onSubmit: SubmitHandler<any> = () => {
        setSuccessMessage('')
        setErrorMessage('')
        let b: string = data.breed || ''
        breeds.map((v: {ID: string, sortOrder: number, value: string, label: string}) => {
            if(data.breed === v.label) {
                b = v.value
            }
        })
        const finalData: ICattleFormSingleViewDto = (!empty(data.ID))? data : ({...data, gid: gid });
        // Make sure the breed is set correctly
        finalData.breed = b
        const action = (!empty(data.ID))? Animal(finalData) : AnimalCreateService(finalData)
        action.then(r => {
            if(r.success) {
                setSuccessMessage('Animal saved successfully')
                reloadHerd(true)
                if(isNew) {
                    setData({});
                }
            } else {
                setErrorMessage(r.error? r.error : '');
            }
        })
    }

    const toForm = (k: string, v: any) => {
        const f = JSON.parse(JSON.stringify(data))
        f[k as keyof typeof f] = v
        setData(new CattleFormSingleViewDto(f))
    }

    const goBack = () => {
        setCloseWindow(true)
    }
    
    const animalStatus = (!empty(data.death_date) || !empty(data.harvest_date))? (!empty(data.death_date)? 'died' : 'harvested') : 'alive'
    // Set new or update gid
    const useGid = (data.gid === '0')? gid : data.gid
    if(empty(breeds) && !helpersLoaded.breeds.ready) {
        if(!helpersLoaded.breeds.loading) {
            setHelperState('breeds', ComponentStateLoadingDto)
            BreedsGetAvailableService().then(r => {
                if(r.success) {
                    setBreeds(r.data.allBreeds)
                }
                setHelperState('breeds', ComponentStateReadyDto)
            })
        }
    }

    if(empty(locations) && !helpersLoaded.locations.ready) {
        if(!helpersLoaded.locations.loading) {
            setHelperState('locations', ComponentStateLoadingDto)
            LocationsGetService(useGid || '').then(r => {
                if(r.success) {
                    setLocations(r.data)
                }
                setHelperState('locations', ComponentStateReadyDto)
            })
        }
    }

    const getShortCode = (val: string): string => {
        let v: string = val
        breeds.map(r => {
            if(val === r.label) {
                v = r.value
            }
        })
        return v
    }
    
    useEffect(() => {
        if(deleteAnimal) {
            goBack()
        }
    })
    
    useEffect(() => {
        if(closeWindow) {
            reloadHerd(true)
            setModalActive(false)
            // setCloseWindow(false)
        }
    }, [ closeWindow ])
    
    const frozen = data?.decentralized_at || data?.decentralizing? true : false;

    const pendingChange: boolean = data?.has_replacement_tag? true : false;

    return (
        <div id="cattle-form-single-view" className={!empty(data.eid) && !isNew? "mt-4" : ''}>
            <AlertYinYang success={ successMessage } error={ errorMessage } />
            {!empty(data.eid) && !isNew? <h5 className="">{`Editing Animal: ${data.eid}`}</h5> : null }
            {!empty(data.eid) && !isNew?
                <div className="d-flex flex-row mb-4 pb-4 pt-3 uppercase">
                    <i className={(animalStatus !== 'alive')? `fas fa-${(animalStatus === 'died')? 'skull-crossbones txt-green' : 'box txt-blue'} fa-lg` : 'fas fa-heartbeat txt-red fa-lg' }></i>&nbsp;<div className={`tag ${animalStatus !== 'alive'? (animalStatus === 'died'? 'bkg-green' : 'bkg-blue') : 'bkg-red' }`}>{animalStatus}</div>
                </div>
            : null }

            <form onSubmit={ handleSubmit(onSubmit) } className="col-count-2 col-c2-lg col-c1-md gapped">
                <div className="span2 span1-md d-flex flex-column">
                    <div className={`tag-container ${frozen && !deceased && !data?.decentralizing && 'border p-4'}`}>
                        <TextField
                            disabled={isAdmin() || frozen }
                            label="Program Compliant Tag (PCT) ID"
                            variant="outlined" value={data.eid || ''}
                            onChange={e => toForm('eid', e.target.value)}
                            fullWidth
                        />
                        { frozen && !deceased && !data?.decentralizing && (
                        <div className="mt-4">
                            { !pendingChange && (
                            <Button variant="outlined" onClick={() => setToggleReplace(!toggleReplace)}>{ toggleReplace? 'Cancel' : 'Replace Id' }</Button>)}
                            { toggleReplace && !pendingChange && (
                            <div style={{width: '100%'}}>
                                <Typography variant="body1" className="mt-3">{ data.visual_tag? "You are able to update the tag without approval because you have already elected to add a secondary tag." : `${ pendingChange? 'There is a change pending currently. ' : ''}A replacement approval audit is required since you did not provide a secondary tag.` }</Typography>
                                <TextField
                                    fullWidth
                                    disabled={ isAdmin() || pendingChange }
                                    label="Replacement Program Compliant Tag (PCT) ID"
                                    className="mt-3"
                                    variant="outlined" value={ replacementId || '' }
                                    onChange={ e => setReplacementId(filterNumber(e.target.value, 15)) }
                                />
                                { toggleConfirm && (
                                    <AlertBox errorClass="warning" className="mt-4">You are attempting to update the tag associated with an animal. If approved, it will invalidate the current tag and create a history chain on the {environment.cpvChainName}.</AlertBox>
                                )}
                                <Alert className="mt-3" />
                                <div className="align-middle mt-4">
                                    { !toggleConfirm && <Button disabled={ pendingChange || !replacementId || replacementId.length !== 15 } variant="contained" onClick={() => setToggleConfirm(true) }>Update Tag</Button> }
                                    { toggleConfirm && (
                                    <div className="col-count-2 gapped col-c1-md">
                                        <Button
                                            disabled= { submittingUpdate }
                                            variant="contained"
                                            onClick={() => {
                                                setSubmittingUpdate(true);
                                                UpdateAnimalTagService(data.ID || '', replacementId).then(r => {
                                                    setSubmittingUpdate(false);
                                                    if (r.success) {
                                                        reloadHerd(true)
                                                        setMsg('Tag was submitted successfully');
                                                        setType(true);
                                                        setModalActive(false);
                                                    } else {
                                                        setMsg(r.error || 'There was an error updating the tag');
                                                        setType(false);
                                                    }
                                                });
                                            }}
                                        >Confirm?</Button>
                                        <Button variant="outlined" onClick={() => setToggleConfirm(false) }>Cancel</Button>
                                    </div>
                                    ) }
                                </div>
                            </div>) }
                        </div>
                        ) }
                    </div>
                    <TextField
                        disabled={isAdmin() || frozen }
                        label="Non-PCT Id"
                        variant="outlined"
                        value={data.visual_tag || ''}
                        onChange={e => toForm('visual_tag', e.target.value)}
                        className="mt-3"
                    />
                </div>

                <div className="d-flex flex-column start1">
                    <TextField
                        disabled={isAdmin() || frozen }
                        label="Tag Colors"
                        variant="outlined"
                        value={data.tag_color || ''}
                        onChange={e => toForm('tag_color', e.target.value)}
                    />
                </div>
                <div className="d-flex flex-column start1">
                    { (helpersLoaded.breeds.ready)?
                    <>
                        <InputLabel>Breed Type</InputLabel>
                        <Select
                            disabled={isAdmin() || frozen }
                            labelId="breed-name-select"
                            id="breed-name-select"
                            required
                            value={ getShortCode(data?.breed || '') }
                            onChange={(e) => {setData((arr: any) => ({...arr, breed: e.target.value}))}
                        }>
                            { breeds.map((v, k) => (<MenuItem key={`breeds-select-${k}`} value={v.value}>{v.label}</MenuItem>)) }
                        </Select>
                    </>
                     : <BootStrapSpinner size="x-sm" /> }
                </div>
                <div className="d-flex flex-column">
                    { (locations.length > 0)? 
                    <>
                        <InputLabel>Location Type</InputLabel>
                        <Select
                            disabled={isAdmin() || frozen }
                            labelId="locations-name-select"
                            id="locations-name-select"
                            value={ data.location || 'Select Location' }
                            required
                            onChange={(e) => {
                                setData((arr: any) => ({...arr, location: e.target.value}))
                            }}>
                            <MenuItem key={-1} value={'Select Location'} disabled={true}>Select Location</MenuItem>
                            { locations.map((v, k) => ( <MenuItem key={k} value={v}>{v}</MenuItem> )) }
                        </Select>
                    </>
                     : <BootStrapSpinner size="x-sm" /> }
                </div>

                {/* <div className="start1 d-flex flex-column">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Born"
                            readOnly={isAdmin()}
                            value={ dayjs(data.birth_date || '') }
                            onChange={ v => toForm('birth_date', v) }
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    helperText: 'YYYY-MM-DD',
                                },
                            }}
                        />
                    </LocalizationProvider>
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        readOnly={isAdmin()}
                        label="Harvested"
                        value={ dayjs(data.harvest_date || '') }
                        onChange={ v => toForm('harvest_date', v) }
                        format="YYYY-MM-DD"
                        slotProps={{
                            textField: {
                                helperText: 'YYYY-MM-DD',
                            },
                        }}
                    />
                </LocalizationProvider>

                 */}

                { frozen && (
                <div>
                    <InputLabel>Dispose Date</InputLabel>
                    <LocalizationProvider
                        dateAdapter={ AdapterDayjs }
                    >
                        <DatePicker
                            value={ dayjs(data.death_date || '') }
                            onChange={ v => toForm('death_date', v) }
                            format="YYYY-MM-DD"
                            disabled= { deceased || toggleReplace }
                            // slotProps={{
                            //     textField: {
                            //         helperText: 'YYYY-MM-DD',
                            //     },
                            // }}
                        />
                    </LocalizationProvider>
                </div>)}

                { !isAdmin() && !frozen && !deceased && !toggleReplace && !pendingChange && (
                <div className="span3 span2-lg span1-md align-middle mt-4 pt-4">
                    <div className={`${isNew? 'col-count-2 col-c1-sm' : 'col-count-3 col-c2-md col-c1-sm'} gapped`}>
                        <Button variant="outlined" onClick={ goBack }><ButtonBack /></Button>
                        { !isNew? <Button variant="outlined" onClick={() => {
                            AnimalDeleteService(data.ID || '').then(r => {
                                if(r.success) {
                                    setDeleteAnimal(true)
                                }
                            })
                            } }><ButtonDelete /></Button> : null }
                        <Button type="submit" variant="contained"><ButtonSave text={ isNew? 'Create' : null } /></Button>
                    </div>
                </div>) }
                { frozen && !deceased && !toggleReplace && !pendingChange && (
                <div className="span2 span1-md align-middle mt-4 pt-4">
                    <AlertBox errorClass="warning">{ empty(data?.decentralizing)? `Saving will write the animal to the ${environment.cpvChainName} and add to it's history chain.` : `You can not make changes until the previous change decentralizes.`}</AlertBox>
                    <Button type="submit" variant="contained" disabled={ !empty(data?.decentralizing) }><ButtonSave /></Button>
                </div>
                ) }
            </form>

            { isAdmin() && (data.breed || '').match(/angus/gi) && (
                <CattleRecordsFormAminCertification
                    setCloseWindow={ setCloseWindow }
                    data={data}
                />
            ) }
        </div>
    )
}

interface ICattleRecordsFormAminCertification
{
    data: any
    setCloseWindow: any
}

const CattleRecordsFormAminCertification = ({ data, setCloseWindow }: ICattleRecordsFormAminCertification) => {
    const toDate = () => (new Date()).toISOString().slice(0, 10)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ verificationError, setVerificationError ] = useState('')
    const [ verificationSuccess, setVerificationSuccess ] = useState('')
    const [ dnaProviders, setDnaProviders ] = useState<any>([])
    const [ dnaProvidersState, setDnaProvidersState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ formData, setFormData ] = useState<IBreed>({dna_test_at: toDate()})

    // Fetch dna providers if admin
    if(!dnaProvidersState.ready) {
        if(!dnaProvidersState.loading) {
            setDnaProvidersState(ComponentStateLoadingDto)
            // Fetch the dropdowns for the dna vendors
            GetOptionsService('dna_vendor').then(r => {
                if(r.success) {
                    setDnaProviders(r.data)
                }
                setDnaProvidersState(ComponentStateReadyDto)
            })
        }
    }
    if(!state.ready) {
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            AngusGetService(data.ID).then(r => {
                if(r.success) {
                    setFormData(r.data)
                }
                setState(ComponentStateReadyDto)
            })
        }
    }

    if(!formData.dna_test_at && state.ready) {
        setFormData(arr => ({...arr, dna_test_at: toDate() }))
    }
    
    const deleteVerification = () => {
        AngusDeleteService(formData.ID? formData.ID : '0').then(r => {
            if(r.success) {
                setVerificationSuccess('Verification successfully removed.')
                setFormData({})
            }
        })
    }

    const submitBreed = () => {
        setVerificationError('')
        setVerificationSuccess('')
        if(!formData.dna_test_at || formData.dna_test_at === '') {
            setVerificationError('Invalid test date.')
            return false
        }

        if(!formData.dna_test_number || formData.dna_test_number === '') {
            setVerificationError('Invalid test number.')
            return false
        }

        if(!formData.dna_test_provider || formData.dna_test_at.toString() === '-1') {
            setVerificationError('Please select a provider.')
            return false
        }

        const percent = formData.percent?.toString().replace(/[^\d.]/gi, '')

        if(!percent || (+percent < 0 || +percent > 100) || percent === '') {
            setVerificationError('Invalid percentage.')
            return false
        }
        
        const action = formData.ID? AngusUpdateService(({ ...formData, hid: data.ID })) : AngusSaveService(({ ...formData, hid: data.ID }))
        action.then(r => {
            if(r.success) {
                setFormData(r.data)
                setVerificationSuccess('Breed verification saved to record.')
            } else {
                setVerificationError(r.error)
            }
        })
    }

    return (
        <div className="mt-4">
            <h5 className="txt-corp"><ButtonAdmin onClickEvent={() => {}} text="Angus Verification" /></h5>
            <div className="admin-section rounded">
                { (verificationError !== '' || verificationSuccess !== '')? <AlertYinYang success={ verificationSuccess } error={ verificationError } /> : null }
                <div className="col-count-3 col-c2-lg col-c1-md gapped">
                    
                    {/* <FormControlLabel control={ <Checkbox checked={ formData.sire_certified_at && formData.sire_certified_at !== ''? true : false } onChange={(e) => setFormData((attr: any) => ({...attr, sire_certified_at: e.target.checked? toDate() : '' })) } /> } label="Sire Certified?" /> */}

                    <TextField
                        value={ formData.dna_test_number? formData.dna_test_number : '' }
                        onChange={(e) => setFormData((attr: any) => ({...attr, dna_test_number: e.target.value })) }
                        label="DNA Test Number"
                    />

                    <TextField value={ formData.percent? formData.percent : '' } onChange={(e) => setFormData((attr: any) => {
                        let p = e.target.value.replace(/[^\d^.]/gi, '')
                        if(+p > 100)
                            p = '100'
                        if(+p < 0)
                            p = '0'
                        return ({...attr, percent: p })
                    }) } label="Percent (%) Angus DNA" />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="DNA Tested At"
                            value={ dayjs(formData.dna_test_at) }
                            onChange={ v => setFormData((attr: any) => ({...attr, dna_test_at: v })) }
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    helperText: 'YYYY-MM-DD',
                                },
                            }}
                        />
                    </LocalizationProvider>

                    <Select value={ formData.dna_test_provider? formData.dna_test_provider : -1  } onChange={(e) => setFormData((attr: any) => ({...attr, dna_test_provider: e.target.value })) }>
                        <MenuItem value={-1}>Select DNA Provider</MenuItem>
                        {
                            Object.keys(dnaProviders).map((v, k) => {
                                return (
                                    <MenuItem key={dnaProviders[k].name+v} value={dnaProviders[k].name}>{dnaProviders[k].value}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>
                <div className="align-middle">
                    <div className="d-flex flex-row gapped mt-3 py-2">
                        <Button variant="outlined" onClick={() => {
                            setCloseWindow(true)
                        }}><ButtonBack /></Button>
                        { formData.ID? <Button variant="outlined" onClick={deleteVerification}><ButtonDelete text={__('Clear', 'clear')} /></Button> : null }
                        <Button variant="contained" onClick={submitBreed}><ButtonSave text={__('Save Verification', 'save_verification')} /></Button>
                    </div>
                </div>

            </div>
        </div> 
    )
}