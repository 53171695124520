import { GridPreProcessEditCellProps } from "@mui/x-data-grid"

export function isNumeric(value: any): boolean {
    if([ null, undefined ].includes(value))
        return false
    value = value.toString().trim()
    return /^-?\d+(\.\d+)?$/.test(value)
}

export const count = (obj: any): number => {
    if(obj === null || typeof obj === "undefined")
        return 0
    if(typeof obj === "object")
        return Object.keys(obj).length
    else if(Array.isArray(obj))
        return obj.length
    return 0
}

export const scrollToElement = (elem: string) => {
    const element = document.getElementById(elem)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
}

export const equals = (val1: any, val2: any): boolean => val1 === val2

export function nowDate(onlyReturn: string = '')
{
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    if(onlyReturn !== '') {
        switch (onlyReturn.toLocaleLowerCase()) {
            case 'm':
                return month
            case 'd':
                return day
            default:
                return year
        }
    }

    return `${year}-${month}-${day}`
}

export function toDate(datetime: string, includeTime: boolean = true): string
{
    // Parse the input date string
    const date = new Date(datetime)
    // Define months array for mapping
    const months = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
    ]
    // Get the month, day, year, hours, and minutes
    const month = months[date.getMonth()]
    const day = date.getDate()
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    // Determine whether it's AM or PM
    const ampm = hours >= 12 ? 'pm' : 'am'
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12
    // Pad the minutes with leading zeros if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    // Create the formatted date string
    return `${month} ${day}, ${year} ${ includeTime? `${formattedHours}:${formattedMinutes} ${ampm}` : '' }`
}
export const randomStr = (len: number): string => {
    let arr = 'abcdefghijklmnopqrstuvwxyz'
    arr += arr.toUpperCase()
    arr += '1234567890'

    let ans = '';
    for (let i = len; i > 0; i--) {
        ans +=
            arr[(Math.floor(Math.random() * arr.length))];
    }
    return ans
}
export const empty = (val: any): boolean => {
    if(typeof val === "undefined")
        return true
    else if(typeof val === "object") {
        return val === null || Object.keys(val).length === 0
    }
    switch(val) {
        case(null):
            return true
        case(0):
            return true
        case('0'):
            return true
        case(false):
            return true
        case(''):
            return true
        default:
            return false
    }
}

export const validateDate = (e: GridPreProcessEditCellProps) => {
    const err = () => new Promise<any>((resolve) => {
        const val = e.props.value
        if(val === '') {
            resolve(false)
        } else {
            const matched = (val !== null)? val.match(/^[\d]{4}-[\d]{2}-[\d]{2}$/gi) : ''
            resolve(matched === null)
        }
    })
    const validate = async () => {
        const errorValidate = await err()
        return { ...e.props, error: errorValidate }
    }
    return validate()
}

export const validateEid = (e: GridPreProcessEditCellProps) => {
    const err = () => new Promise<any>((resolve) => {
        const val = e.props.value
        if(val === '') {
            resolve(false)
        } else {
            const matched = (val !== null)? val.match(/^[\d]{15}$/gi) : ''
            resolve(matched === null)
        }
    })
    const validate = async () => {
        const errorValidate = await err()
        return { ...e.props, error: errorValidate }
    }
    return validate()
}

export const url = (url: string, title: string) => window.history.pushState('', title, url);


export const columnToTitle = (str: string): string => {
    const words = str.split("_")
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    let val = capitalizedWords.join(" ")
    for(const i of ['Dob','Eid','Cid', 'Dna']) {
        val = val.replace(i, i.toUpperCase())
    }
    return val
}

export const dateNameToDate = (value: string) => {
    const dateParts = value.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
    const day = parseInt(dateParts[2], 10);

    const dateObj = new Date(Date.UTC(year, month, day));

    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const dayOfWeek = weekdays[dateObj.getUTCDay()];
    const dayOfMonth = dateObj.getUTCDate();
    const monthName = months[dateObj.getUTCMonth()];
    const yearUTC = dateObj.getUTCFullYear();

    return `${dayOfWeek}, ${dayOfMonth} ${monthName} ${yearUTC}`;
}

export const multiFormatter = (column: string, value: any) => {
    if(column.match(/.*_at|date$/gi)) {
        return dateNameToDate(value)
    } else if(column.match(/percent/gi)) {
        return `${value}%`
    }
    return value
}

export const toFileSize = (num: number) => `${(Math.round((num / 1000) * 100) / 100).toLocaleString()}KB`

export const isArray = (obj: any): boolean => Array.isArray(obj)

export const isObject = (obj: any): boolean => !Array.isArray(obj) && typeof obj === "object"


export const exists = (obj: any, value: any) => {
    let exists: boolean  = false
    if(empty(obj))
        return exists

    if(isArray(obj)) {
        return obj.includes(value)
    } else if(isObject(obj)) {
        return Object.keys(obj).map((v) => {
            if(value === v)
                exists = true
        })
    }
    return exists
}

export function swapElementInArray<T>(obj: T[], current: any, original: T, replace: T): T[]
{
    let n: T[] = [...obj]
    if(exists(obj, original) && current === replace) {
        n = n.filter((v: T) => v !== original)
        n.push(replace)
    } else if(exists(obj, replace) && current === original) {
        n = n.filter((v: T) => v !== replace)
        n.push(original)
    } 
    return n
}

export const isBool = (value: any): boolean => typeof value === "boolean"