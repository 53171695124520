import { http } from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"

export const MediaGetService = (gid: string) => {
    return http().get<IResponse<any>>(`media/group/${gid}/`)
}
export const MediaDownloadService = (filename: string): Promise<IResponse<any>> =>
{
    return http().get<IResponse<any[]>>(`media/file/${filename}/`)
}

export const MediaDeleteService = (filename: string): Promise<IResponse<any[]>> =>
{
    return http().delete<IResponse<any[]>>(`media/group/${filename}/`)
}

export const MediaUpdateService = (filename: string, newFileName: string): Promise<IResponse<any[]>> =>
{
    return http().patch<IResponse<any[]>>(`media/group/${filename}/`, { filename: newFileName })
}
export const AcceptUploadEvidenceService = (fid: string, type: string) =>
{
    return http().patch<any>(`evidence/${fid}/`, {type})
}