import { IButtonAny } from "..";
import __ from "../../../../services/translator";

export default function ButtonBack({ text, className }: IButtonAny)
{
    return (
        <div className={ `strong ${className || ''}` }>
            <i className="fas fa-angle-double-left"></i>&nbsp;{ text || __('Back', 'back') }
        </div>
    )
}