import { http } from "../../../helpers/client"
import { IResponse } from "../../../interfaces/response.interface"

interface ITagManagerDeleteDocumentService
{
    id: string,
    action: 'doc' | string,
    tid: string,
}

interface ITagManagerDeleteTagService extends ITagManagerDeleteDocumentService
{
    action: 'tag'
}

export const toResponseFromAPI = (r: any, error: string = 'An error occurred while completing the request.') => {
    if(r.success) {
        return r;
    } else {
        throw new Error(r?.error || error);
    }
}

export const TagManagerGetService = async (id?: string, filters?: {[k: string]: any }) => {
    const r = await http('b').get<IResponse<any>>(`tag-manager${id? `/${id}`: ''}`, filters);
    return toResponseFromAPI(r, 'An error occurred while receiving the tag groups.');
}

export const TagManagerPCTUpdateService = async (body: any) => {
    const r = await http('b').patch<IResponse<any>>(`tag-manager${body.id? `/${body.id}`: ''}`, body);
    return toResponseFromAPI(r, 'An error occurred while updating the PCT value.');
}

export const TagManagerDeleteDocumentService = async (body: ITagManagerDeleteDocumentService) => {
    const r = await http('b').delete<IResponse<any>>(`tag-manager${body.id? `/${body.id}`: ''}`, body);
    return toResponseFromAPI(r, 'An error occurred while deleting the document.');
}

export const TagManagerDeleteTagService = async (body: ITagManagerDeleteTagService) => {
    const r = await http('b').delete<IResponse<any>>(`tag-manager/delete-tag`, body);
    return toResponseFromAPI(r, 'An error occurred while deleting the tag.');
}

export const TagManagerDeleteTagGroupService = async (id: string, type: string) => {
    const r = await http('b').delete<IResponse<any>>(`tag-manager/delete/${id}`, { action: type });
    return toResponseFromAPI(r, 'An error occurred while deleting the tag group.');
}

export const TagManagerDeleteAllTagsService = async (body : {ids: string[], action: string}) => {
    const r = await http('b').delete<IResponse<any>>(`tag-manager/delete-tag`, body);
    return toResponseFromAPI(r, 'An error occurred while deleting the tags.');
}

export const UserToolsTagManagerGetService = (id?: string) => http().get<IResponse<any>>(`tag-manager${id? `/${id}`: ''}`)

export const UserToolsTagManagerCreateService = (body: any) => http().post<IResponse<any>>(`tag-manager`, {body})

export const UserToolsTagManagerUpdateService = (body: any) => http().patch<IResponse<any>>(`tag-manager`, {body})

export const UserToolsTagManagerDeleteService = (id: string) => http().delete<IResponse<any>>(`tag-manager/${id}`)
