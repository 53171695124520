import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import InventoryIcon from '@mui/icons-material/Inventory'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LockIcon from '@mui/icons-material/Lock'

export enum DispositionTypeEnum
{
    transport = 1,
    sale,
}

export enum DispositionStatusEnum
{
    off = 1,
    on,
    archived,
    locked
}

interface IDispositionStatusEnumBadge
{
    status: number
    size?: 'large' | 'small' | 'medium'
    onClick?: any
    className?: string
}

export const DispositionStatusEnumBadge = ({status, size}: IDispositionStatusEnumBadge) => {
    if(status <= DispositionStatusEnum.on)
        return status === DispositionStatusEnum.on? <ToggleOnIcon className='green' fontSize={ size || 'large'} /> : <ToggleOffIcon className='md-grey' fontSize={ size || 'large'} />
    switch (status) {
        case (DispositionStatusEnum.archived):
            return <InventoryIcon />
        case (DispositionStatusEnum.locked):
            return <LockIcon />
        default:
            return null
    }
}

export const DispositionTypeEnumBadge = ({ status, size, onClick, className }: IDispositionStatusEnumBadge) => {
    switch (status) {
        case (DispositionTypeEnum.sale):
            return <LocalOfferIcon className={ className || '' } fontSize={ size || "large" } onClick={onClick? onClick : () => null} />
        case (DispositionTypeEnum.transport):
            return <LocalShippingIcon className={ className || '' } fontSize={ size || "large" }  onClick={onClick? onClick : () => null} />
        default:
            null
    }
}