export function toForm<T>(data: any, k: string, v: any, setData: CallableFunction)
{
    const useData = (typeof data !== "undefined")? data : {}
    const f: T = JSON.parse(JSON.stringify(useData))
    f[k as keyof typeof f] = v
    setData(f)
}

export function toFormRaw<T>(data: any, k: string, v: any, setData: CallableFunction)
{
    const useData = (typeof data !== "undefined")? data : {}
    const f: T = useData
    f[k as keyof typeof f] = v
    setData(f)
}

export function toFormRawArray(add: any, setData: CallableFunction)
{
    setData((arr: any) => ({ ...arr, ...add }))
}

export function toDate(): string
{
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function yearFromToday(): string {
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() + 1)
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

export const onChangeEventHelper = (e: any, setForm: any, transformFunc?: any) => {
    const { name, value } = e.target
    setForm((arr: any) => ({...arr, [name]: (typeof transformFunc === "function")? transformFunc(value) : value }))
}

export const onCheckEventHelper = (e: any, setForm: any, transformFunc?: any) => {
    const { name, checked } = e.target
    setForm((arr: any) => ({...arr, [name]: (typeof transformFunc === "function")? transformFunc(checked) : checked }))
}