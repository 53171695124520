import { Button, MenuItem, Select, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useContext, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { CattleFormSingleViewDto } from "../../../dto/animal-record"
import { AngusDeleteService, AngusGetService, AngusSaveService, AngusUpdateService } from "../../../services/angus"
import { Animal, AnimalCreateService, AnimalDeleteService } from "../../../services/herd-groups"
import { GetOptionsService } from "../../../services/options"
import { AlertDanger, AlertSuccess, AlertYinYang } from "../../ui/views/alerts"
import { BreedsGetService } from "../../../services/breeds"
import { LocationsGetService } from "../../../services/locations"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { ModalContext } from "../../../providers/modal.provider"
import { isAdmin } from "../../../helpers/account"
import { empty } from "../../../helpers/util.helper"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import dayjs from 'dayjs'
import ButtonAdmin from "../../ui/buttons/button-admin"
import ButtonBack from "../../ui/buttons/button-back"
import ButtonDelete from "../../ui/buttons/button-delete"
import ButtonSave from "../../ui/buttons/button-save"
import __ from "../../../services/translator"

export type IBreed = {
    ID?: string
    hid?: string
    percent?: number
    dna_test_at?: string
    dna_test_number?: string
    dna_test_provider?: number
    sire_certified_at?: string
    created_at?: string
    deleted_at?: string
}

export type ICattleRecordFormSingleView = {
    animal: CattleFormSingleViewDto
    setUpdate: any,
    reloadHerd: any,
    gid?: string
    new?: boolean
}

export function CattleRecordFormSingleView(props: ICattleRecordFormSingleView)
{
    const toDate = () => (new Date()).toISOString().slice(0, 10)
    const [ closeWindow, setCloseWindow ] = useState<boolean>(false)
    const [ data, setData ] = useState(props.animal)
    const [ formData, setFormData ] = useState<IBreed>({dna_test_at: toDate()})
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ dnaProviders, setDnaProviders ] = useState<any>([])
    const [ dnaProvidersState, setDnaProvidersState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ verificationLoaded, setVerificationLoaded ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ verificationError, setVerificationError ] = useState('')
    const [ verificationSuccess, setVerificationSuccess ] = useState('')
    const [ breeds, setBreeds ] = useState<string[]>([])
    const [ locations, setLocations ] = useState<string[]>([])

    const { handleSubmit } = useForm()
    const { setModalActive } = useContext(ModalContext)

    // Fetch dna providers if admin
    if(isAdmin()) {
        if(!dnaProvidersState.ready) {
            if(!dnaProvidersState.loading) {
                setDnaProvidersState(ComponentStateLoadingDto)
                // Fetch the dropdowns for the dna vendors
                GetOptionsService('dna_vendor').then(r => {
                    if(r.success) {
                        setDnaProviders(r.data)
                    }
                    setDnaProvidersState(ComponentStateReadyDto)
                })
            }
        }
        if(!verificationLoaded.ready) {
            if(!verificationLoaded.loading) {
                setVerificationLoaded(ComponentStateLoadingDto)
                AngusGetService(data.ID).then(r => {
                    if(r.success) {
                        setFormData(r.data)
                    }
                    setVerificationLoaded(ComponentStateReadyDto)
                })
            }
        }

        if(!formData.dna_test_at && verificationLoaded.ready) {
            setFormData(arr => ({...arr, dna_test_at: toDate() }))
        }
    }

    const deleteVerification = () => {
        AngusDeleteService(formData.ID? formData.ID : '0').then(r => {
            if(r.success) {
                setVerificationSuccess('Verification successfully removed.')
                setFormData({})
            }
        })
    }

    const submitBreed = () => {
        setVerificationError('')
        setVerificationSuccess('')
        if(!formData.dna_test_at || formData.dna_test_at === '') {
            setVerificationError('Invalid test date.')
            return false
        }

        if(!formData.dna_test_number || formData.dna_test_number === '') {
            setVerificationError('Invalid test number.')
            return false
        }

        if(!formData.dna_test_provider || formData.dna_test_at.toString() === '-1') {
            setVerificationError('Please select a provider.')
            return false
        }

        const percent = formData.percent?.toString().replace(/[^\d.]/gi, '')

        if(!percent || (+percent < 0 || +percent > 100) || percent === '') {
            setVerificationError('Invalid percentage.')
            return false
        }
        
        const action = formData.ID? AngusUpdateService(({ ...formData, hid: data.ID })) : AngusSaveService(({ ...formData, hid: data.ID }))
        action.then(r => {
            if(r.success) {
                setFormData(r.data)
                setVerificationSuccess('Breed verification saved to record.')
            } else {
                setVerificationError(r.error)
            }
        })
    }

    const onSubmit: SubmitHandler<any> = () => {
        setSuccessMessage('')
        setErrorMessage('')
        const finalData: CattleFormSingleViewDto = (!empty(data.ID))? data : new CattleFormSingleViewDto(({...data, gid: props.gid }))
        const action = (!empty(data.ID))? Animal(finalData) : AnimalCreateService(finalData)
        action.then(r => {
            if(r.success) {
                setSuccessMessage('Animal saved successfully')
                props.reloadHerd(true)
                if(props.new) {
                    setData(new CattleFormSingleViewDto({}))
                }
            } else {
                setErrorMessage(r.error? r.error : '')
            }
        })
    }

    const toForm = (k: string, v: any) => {
        const f = JSON.parse(JSON.stringify(data))
        f[k as keyof typeof f] = v
        setData(new CattleFormSingleViewDto(f))
    }

    const deleteAnimal = (hid: string) => {
        AnimalDeleteService(hid).then(r => {
            if(r.success) {
                goBack()
            }
        })
    }

    const goBack = () => {
        setCloseWindow(true)
    }
    
    const animalStatus = (!empty(data.death_date) || !empty(data.harvest_date))? (!empty(data.death_date)? 'died' : 'harvested') : 'alive'
    // Set new or update gid
    const useGid = (data.gid === '0')? props.gid : data.gid
    if(empty(breeds)) {
        BreedsGetService(useGid || '').then(r => {
            if(r.success) {
                setBreeds(r.data)
            }
        })
    }

    if(empty(locations)) {
        LocationsGetService(useGid || '').then(r => {
            if(r.success) {
                setLocations(r.data)
            }
        })
    }

    useEffect(() => {
        if(closeWindow) {
            setModalActive(false)
            setCloseWindow(false)
        }
    }, [ closeWindow ])

    return (
        <div id="cattle-form-single-view" className={!empty(data.eid) && !props.new? "mt-4" : ''}>
            { successMessage !== ''? <AlertSuccess text={successMessage} /> : null }
            { errorMessage !== ''? <AlertDanger text={errorMessage} /> : null }
            {!empty(data.eid) && !props.new? <h5 className="">{`Editing Animal: ${data.eid}`}</h5> : null }
            {!empty(data.eid) && !props.new?
                <div className="d-flex flex-row mb-4 pb-4 pt-3 uppercase">
                    <i className={(animalStatus !== 'alive')? `fas fa-${(animalStatus === 'died')? 'skull-crossbones txt-green' : 'box txt-blue'} fa-lg` : 'fas fa-heartbeat txt-red fa-lg' }></i>&nbsp;<div className={`tag ${animalStatus !== 'alive'? (animalStatus === 'died'? 'bkg-green' : 'bkg-blue') : 'bkg-red' }`}>{animalStatus}</div>
                </div>
            : null }
            <form onSubmit={ handleSubmit(onSubmit) } className="col-count-3 col-c2-lg col-c1-md gapped">

                <div className="span2 span1-lg d-flex flex-column">
                    <TextField disabled={isAdmin()} label="Animal Identification Tag Id" variant="outlined" value={data.eid || ''} onChange={e => toForm('eid', e.target.value)} />
                </div>

                <div className="d-flex flex-column start1">
                    <TextField disabled={isAdmin()} label="Tag Colors" variant="outlined" value={data.tag_color || ''} onChange={e => toForm('tag_color', e.target.value)} />
                </div>
                <div className="d-flex flex-column">
                    <TextField disabled={isAdmin()} label="Visual Tags" variant="outlined" value={data.visual_tag || ''} onChange={e => toForm('visual_tag', e.target.value)} />
                </div>
                <div className="d-flex flex-column start1">
                    { (breeds.length > 0)? <Select labelId="breed-name-select" id="breed-name-select" value={data.breed || 'Select Breed' } onChange={(e) => {setData((arr: any) => ({...arr, breed: e.target.value}))}}>
                        <MenuItem key={-1} value={'Select Breed'} disabled={true}>Select Breed</MenuItem>
                        {
                            breeds.map((v, k) => {
                                return (
                                    <MenuItem key={k} value={v}>{v}</MenuItem>
                                )
                            })
                        }
                    </Select> : <BootStrapSpinner /> }
                </div>
                <div className="d-flex flex-column">
                    { (locations.length > 0)? <Select labelId="locations-name-select" id="locations-name-select" value={data.location || 'Select Location' } onChange={(e) => {setData((arr: any) => ({...arr, location: e.target.value}))}}>
                        <MenuItem key={-1} value={'Select Location'} disabled={true}>Select Location</MenuItem>
                        {
                            locations.map((v, k) => {
                                return (
                                    <MenuItem key={k} value={v}>{v}</MenuItem>
                                )
                            })
                        }
                    </Select> : <BootStrapSpinner /> }
                </div>

                <div className="start1 d-flex flex-column">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Born"
                            readOnly={isAdmin()}
                            value={ dayjs(data.birth_date || '') }
                            onChange={ v => toForm('birth_date', v) }
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    helperText: 'YYYY-MM-DD',
                                },
                            }}
                        />
                    </LocalizationProvider>
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        readOnly={isAdmin()}
                        label="Harvested"
                        value={ dayjs(data.harvest_date || '') }
                        onChange={ v => toForm('harvest_date', v) }
                        format="YYYY-MM-DD"
                        slotProps={{
                            textField: {
                                helperText: 'YYYY-MM-DD',
                            },
                        }}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        readOnly={isAdmin()}
                        label="Died (Before being harvested)"
                        value={ dayjs(data.death_date || '') }
                        onChange={ v => toForm('death_date', v) }
                        format="YYYY-MM-DD"
                        slotProps={{
                            textField: {
                                helperText: 'YYYY-MM-DD',
                            },
                        }}
                    />
                </LocalizationProvider>

                { !isAdmin()? <div className="span3 span2-lg span1-md align-middle mt-4 pt-4">
                    <div className={`${props.new? 'col-count-2 col-c1-sm' : 'col-count-3 col-c2-md col-c1-sm'} gapped`}>
                        <Button variant="outlined" onClick={ goBack }><ButtonBack /></Button>
                        { !props.new? <Button variant="outlined" onClick={() => deleteAnimal(data.ID || '') }><ButtonDelete /></Button> : null }
                        <Button type="submit" variant="contained"><ButtonSave text={ props.new? 'Create' : null } /></Button>
                    </div>
                </div> : null }
            </form>

            { isAdmin()?
            <div className="mt-4">
                <h5 className="txt-corp"><ButtonAdmin onClickEvent={() => {}} text="Angus Verification" /></h5>
                <div className="admin-section rounded">
                    { (verificationError !== '' || verificationSuccess !== '')? <AlertYinYang success={ verificationSuccess } error={ verificationError } /> : null }
                    <div className="col-count-3 col-c2-lg col-c1-md gapped">
                        
                        {/* <FormControlLabel control={ <Checkbox checked={ formData.sire_certified_at && formData.sire_certified_at !== ''? true : false } onChange={(e) => setFormData((attr: any) => ({...attr, sire_certified_at: e.target.checked? toDate() : '' })) } /> } label="Sire Certified?" /> */}

                        <TextField
                            value={ formData.dna_test_number? formData.dna_test_number : '' }
                            onChange={(e) => setFormData((attr: any) => ({...attr, dna_test_number: e.target.value })) }
                            label="DNA Test Number"
                        />

                        <TextField value={ formData.percent? formData.percent : '' } onChange={(e) => setFormData((attr: any) => {
                            let p = e.target.value.replace(/[^\d^.]/gi, '')
                            if(+p > 100)
                                p = '100'
                            if(+p < 0)
                                p = '0'
                            return ({...attr, percent: p })
                        }) } label="Percent (%) Angus DNA" />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="DNA Tested At"
                                value={ dayjs(formData.dna_test_at) }
                                onChange={ v => setFormData((attr: any) => ({...attr, dna_test_at: v })) }
                                format="YYYY-MM-DD"
                                slotProps={{
                                    textField: {
                                        helperText: 'YYYY-MM-DD',
                                    },
                                }}
                            />
                        </LocalizationProvider>

                        <Select value={ formData.dna_test_provider? formData.dna_test_provider : -1  } onChange={(e) => setFormData((attr: any) => ({...attr, dna_test_provider: e.target.value })) }>
                            <MenuItem value={-1}>Select DNA Provider</MenuItem>
                            {
                                Object.keys(dnaProviders).map((v, k) => {
                                    return (
                                        <MenuItem key={dnaProviders[k].name+v} value={dnaProviders[k].name}>{dnaProviders[k].value}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="align-middle">
                        <div className="d-flex flex-row gapped mt-3 py-2">
                            <Button variant="outlined" onClick={() => {
                                setCloseWindow(true)
                            }}><ButtonBack /></Button>
                            { formData.ID? <Button variant="outlined" onClick={deleteVerification}><ButtonDelete text={__('Clear', 'clear')} /></Button> : null }
                            <Button variant="contained" onClick={submitBreed}><ButtonSave text={__('Save Verification', 'save_verification')} /></Button>
                        </div>
                    </div>

                </div>
            </div> : null }
        </div>
    )
}