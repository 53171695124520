import { memo } from "react";
import __ from "../../../../services/translator";

const ButtonSearch = memo(function ButtonSearch(props?: any)
{
    return (
        <span className="strong">
            <i className="fas fa-search"></i>&nbsp;{props.text? props.text : __('Search', 'search') }
        </span>
    )
})
export default ButtonSearch