import HttpClient from "../helpers/client"

export interface IAnimalSearchGet
{
    success?: boolean
    data?: any
    cid?: string | null
    found?: boolean
    url?: string
    error?: string
}

export function AnimalSearchGet(id: string | number, type: string): Promise<IAnimalSearchGet>
{
    return new Promise<IAnimalSearchGet>((success, fail) => {
        try {
            return (new HttpClient()).get<IAnimalSearchGet>(`animal-search/${type}/${id}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}