import { ReactNode, useContext } from "react"
import { IContextChildren } from "../../../interfaces/context-children.inteface"
import { Button, Typography } from "@mui/material"
import { AlertContext } from "../../../providers/alert.provider"
import ButtonBack from "../buttons/button-back"
import UploadCsvAlert from "../../upload/alert"

interface ICpvBlockHeader extends IContextChildren
{
    title: JSX.Element | ReactNode | string
    description?: JSX.Element | ReactNode | string
    addClass?: string
}

export const CpvBlockHeader = ({ children, title, description, addClass }: ICpvBlockHeader) => {
    return (
        <div className={`border cpv-block ${addClass}`}>
            <h3 className="block-header">{ title }</h3>
            { description? <div className="block-description">{ description }</div> : null }
            <div className="p-3 col-count-1 gapped">
                { children }
            </div>
        </div>
    )
}

interface ICreateButtonBlock
{
    createEvent?: any
    backButtonEvent?: any
    backButtonLabel?: JSX.Element | ReactNode | string
    label?: JSX.Element | ReactNode | string
    text?: JSX.Element | ReactNode | string
    useAlert?: boolean
}

export const CreateButtonBlock = ({ createEvent, label, text, useAlert, backButtonEvent, backButtonLabel }: ICreateButtonBlock) => {
    let c: number = 0
    if(backButtonEvent)
        c += 1
    
    if(createEvent)
        c += 1

    return (
        <div className={useAlert? 'alert alert-secondary' : '' }>
            <div className="align-middle">
                { label || null }
                { createEvent || backButtonEvent?
                <div className={`col-count-${c} gapped col-c1-md ${ label? 'mt-4' : '' }`}>
                    { createEvent? <Button variant="contained" className="corp" onClick={ createEvent }>{ text || 'Create'}</Button> : null }
                    { backButtonEvent? <Button variant="outlined" className="corp" onClick={ backButtonEvent }><ButtonBack text={backButtonLabel || 'Back'} /></Button> : null }
                </div> : null
                }
            </div>
        </div>
    )
}

interface IButtonAttr
{
    text?: JSX.Element | ReactNode | string
    onClickEvent?: any
    disabled?: boolean
}

interface IFormSubmitButtonsBlock
{
    children?: JSX.Element | ReactNode | string
    backButton?: IButtonAttr
    submitButton?: IButtonAttr
    className?: string
    isSubmit?: boolean
}

export const FormSubmitButtonsBlock = ({ children, backButton, submitButton, isSubmit, className }: IFormSubmitButtonsBlock) => {
    const type: any = (typeof isSubmit === "undefined" || isSubmit)? 'submit' : undefined
    return (
        <div className="align-middle mt-4">
            <div className={ className || "col-count-2 gapped" }>
                { backButton? <Button variant="outlined" className="corp" onClick={ backButton.onClickEvent }><ButtonBack text={ backButton.text || 'Back' } /></Button> : null }
                { children || null }
                <Button variant="contained" className="corp" disabled={ submitButton?.disabled } type={ type } onClick={ () => submitButton?.onClickEvent || null }>{submitButton?.text || 'Save'}</Button>
            </div>
        </div>
    )
}

interface IModalContentGeneric extends IContextChildren
{
    title?: JSX.Element | ReactNode | string
    description?: JSX.Element | ReactNode | string
    buttons?: JSX.Element | ReactNode
    onSubmitEvent?: (setType: (v: boolean) => void, setMsg: (v: any) => void) => void
    onCancelEvent?: (setType: (v: boolean) => void, setMsg: (v: any) => void) => void
    submitText?: string
}

export const ModalContentGeneric = ({ children, title, description, buttons, onCancelEvent, onSubmitEvent, submitText }: IModalContentGeneric) => {
    const isFunc = (v: any): boolean => typeof v === 'function'
    const isEvent = isFunc(onCancelEvent) || isFunc(onSubmitEvent);
    const { setType, setMsg, msg, type } = useContext(AlertContext);
    return (
    <div className="align-middle modal-content-generic-wrapper">
        { msg !== '' && <UploadCsvAlert errorClass={ type? 'success' : 'danger'} errorMessage={ msg } /> }
        { title && <Typography className="modal-content-generic-title" variant="h5">{ title }</Typography> }
        { description && <Typography variant="body1" className="mt-4 text-center modal-content-generic-description">{ description }</Typography> }
        { children || null }
        { buttons || isEvent && (
        <div className="align-middle pt-4 modal-content-generic-buttons">
            { buttons || null }
            { isEvent && (
            <div className="col-count-2 gapped col-c1-md">
                { isFunc(onCancelEvent) && <Button variant="outlined" onClick={ () => {
                    try {
                        if(onCancelEvent)
                            onCancelEvent(setType, setMsg);
                    } catch(e: any) {
                        setType(false);
                        setMsg(e.message || 'An error occurred.');
                    }
                } }>Cancel</Button> }
                { isFunc(onSubmitEvent) && <Button variant="contained" onClick={ () => {
                    try {
                        if(onSubmitEvent)
                            onSubmitEvent(setType, setMsg);
                    } catch(e: any) {
                        setType(false);
                        setMsg(e.message || 'An error occurred.');
                    }
                } }>{ submitText || 'Okay' }</Button> }
            </div>)}
        </div>)}
    </div>
)}