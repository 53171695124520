import { QueryClient, QueryClientProvider } from 'react-query'
import { AccountProvider } from './providers/account.provider';
import { HerdGroupsProvider } from './providers/herd-groups.provider';
import { ModalWindowProvider } from './providers/modal.provider'
import { ApplicationProvider } from './providers/application.provider';
import { AlertProvider } from './providers/alert.provider';
import { Provider as Redux } from 'react-redux';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import ErrorBoundary from './components/error-boundry';
import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './media/styles/global.scss'
import store from './redux/store';
// import * as Styles from '@mui/material/styles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Redux store={ store }>
      <ErrorBoundary>
        <AlertProvider>
          <ApplicationProvider>
            <ModalWindowProvider>
              <AccountProvider>
                <HerdGroupsProvider>
                  <QueryClientProvider client={queryClient}>
                    <Router />
                  </QueryClientProvider>
                </HerdGroupsProvider>
              </AccountProvider>
            </ModalWindowProvider>
          </ApplicationProvider>
        </AlertProvider>
      </ErrorBoundary>
    </Redux>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();