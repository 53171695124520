import { getToken, decodeJwt } from "../../helpers/token";
import { cacheDestory, cacheDestroyTargets, cacheResetRegList } from "../../services/cache";

// type LoginDto = {
//     token: string
// }

export function Logout(state: any)//props: LoginDto)
{
    const jwt = decodeJwt(getToken())
    
    if(jwt.expired && !!jwt.id) {
        state({valid: false})
        cacheDestory('cpv_loggedin')
        signOut()
        return true
    }
    return false
}

export function signOut()
{
    cacheDestroyTargets(true)
    cacheResetRegList()
    window.location.search = ''
    window.location.href = '/'
}