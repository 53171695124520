import { useState } from "react"
import { Button } from "@mui/material";
import { AlertNoNotifications } from "../views/alerts";
import { INotify, INote, INotes, Communique, CommuniqueDelete } from "../../../services/notification"
import { BootStrapSpinner } from "../views/spinner"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import ButtonBack from "../buttons/button-back"

type INotificationButtons = {
    setViewingNote: any,
    nid: string,
    setNotificationsLoaded: any
}
/**
 * @description Buttons to navigate the notes
 */
function NotificationButtons(props: INotificationButtons)
{
    const [ hide, setHide ] = useState(false)

    
    // Mark the current note as read
    // const setHidden = (nid: string) => {
    //     // Update the notification
    //     NotificationUpdate({nid, status: 'hide'}).then(() => {
    //         // Force reload of the notes in parent
    //         props.setNotificationsLoaded(false)
    //         // Hide the hide button
    //         setHide(true)
    //     })
    // }
    const setHidden = (nid: string) => {
        // Update the notification
        CommuniqueDelete(nid).then(() => {
            // Force reload of the notes in parent
            props.setNotificationsLoaded(false)
            // Hide the hide button
            setHide(true)
        })
    }
    
    return (
        <div className="align-middle mt-4">
            <div className={ hide? '' : 'col-count-2 gapped' }>
                { hide? '' :  <Button color="primary" variant="text" onClick={() => {
                    setHidden(props.nid)
                }}>Delete Message</Button> }
                <Button color="primary" variant="outlined" onClick={() => props.setViewingNote(false)}><ButtonBack /></Button>
            </div>
        </div>
    )
}

interface INotificationsComponent
{
    setNotificationCount: any
}

/**
 * @description Display for reading and modifying notes
 */
export default function NotificationsComponent({ setNotificationCount }: INotificationsComponent)
{
    const notes: INotify = {count:0, notes: []};
    const noteValue: INotes = new INote({})
    const [ notifications, setNotifications ] = useState(notes)
    const [ notificationsLoaded, setNotificationsLoaded ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ viewingNote, setViewingNote ] = useState(false)
    const [ note, setNote ] = useState(noteValue)
    const [ noteTitle, setNoteTitle ] = useState('')

    if(!notificationsLoaded.ready) {
        if(!notificationsLoaded.loading) {
            setNotificationsLoaded(ComponentStateLoadingDto)
            Communique().then(r => {
                const c: number = r.data.length
                setNotifications({count: c, notes: r.data})
                setNotificationCount(c)
                setNotificationsLoaded(ComponentStateReadyDto)
                setViewingNote(false)
            })
        }
    }

    return (
        <div id="notifications">
            { viewingNote?
                <Note
                    note={note}
                    title={noteTitle}
                    defaultContent=""
                    buttons={
                        <NotificationButtons setViewingNote={setViewingNote} setNotificationsLoaded={setNotificationsLoaded} nid={ note.ID} />
                    }
                /> : null
            }

            { !notificationsLoaded.ready? <div className="align-middle"><BootStrapSpinner /></div> : null }
            { notificationsLoaded.ready && notifications.count === 0? <AlertNoNotifications /> : null }
            { notificationsLoaded.ready && !viewingNote?
                <table className="standard table">
                    <tbody>
                        {
                        notifications.notes.map((v) => {
                            v = new INote(v)
                            const type = (v.type.replace('_', ' ')).toUpperCase()
                            return (
                            (!v.hidden_at)?
                            <tr key={v.ID}>
                                <td>{v.notified_at}</td>
                                <td>
                                    <strong>{type}</strong>
                                    {(v.note !== '')? <p>{v.note.substring(0, 100)}{v.note.length > 100? '...' : ''}</p> : ''}
                                </td>
                                <td><Button color="primary" variant="contained" onClick={() => {
                                    notifications.notes.map((val) => {
                                        val = new INote(val)
                                        if(v.ID === val.ID) {
                                            setNote(val)
                                        }
                                        return val
                                    })
                                    setViewingNote(true)
                                    setNoteTitle(type)
                                }} >Read</Button></td>
                            </tr> : null )
                        })
                        }
                    </tbody>
            </table>
            : null }
        </div>
    )
}

export interface INoteViewer
{
    note: INotes
    title: string
    defaultContent?: string
    buttons?: any
}

export function Note({ note, title, defaultContent, buttons }: INoteViewer)
{
    const def = defaultContent || `There is no content to this message. It is simply a notification regarding the status of a ${title}.`
    const body = (note.note !== '')? note.note : def
    return (
        <div id={`note-${note.ID}`} className="notification-wrapper">
            <div className="notification-header">
                <h5>{ title }</h5>
            </div>
            <div className="notification-body">
                { body }
            </div>
            <div className="notification-buttons">
                { buttons || null }
            </div>
        </div>
    )
}