import { AccountHelper } from "../helpers/account";
import HttpClient, { http } from "../helpers/client";
import { IResponse } from "../interfaces/response.interface";

export type ISiteSettings = {
    hide_on_decentralize?: ISetting
}

export type ISiteSettingsResponse = {
    success: boolean,
    data: ISetting[]
}

export type ISetting = {
    ID?: string,
    set_name?: string | null,
    type?: string,
    value?: any,
    uid?: string,
    hasOwner?: boolean
}

export async function SiteSettings() //Promise<ISiteSettingsResponse>
{
    const data = await GeneralAccountSettingsGetService(AccountHelper().id?.toString() || '')
    return data.data?.system || {}
    //return SettingsGetGroup<ISiteSettingsResponse>('site')
}

export function SettingsGetGroup<T>(key: string): Promise<T>
{
    return (new HttpClient()).get<T>(`setting/group/${key}`)
}

export function SettingsGetAttr(key: string): Promise<ISetting>
{
    return (new HttpClient()).get<ISetting>(`setting/attr/${key}`)
}

export function SettingsSetAttr(body: any): Promise<any>//Promise<ISetting>
{
    return GeneralAccountSettingsSaveService(body)
    // return (new HttpClient()).post<ISetting>(`setting/attr/${key}`, body)
}

export const SettingsRemoveAttr = (key: string) => {
    return (new HttpClient()).delete<{ success: boolean }>(`setting/attr/${key}`)
}

export const GeneralAccountSettingsGetService = (uid: string): Promise<IResponse<any>> => {
    return http().get<IResponse<any[]>>(`internal/settings/${uid}/`)
}

export const GeneralAccountSettingsSaveService = (settings: any): Promise<IResponse<any>> => {
    return http().post<IResponse<any>>('internal/settings/', settings)
}

export const SystemGetService = <T = any>(type?: string) => http().get<IResponse<T>>(`global-settings${type? `/${type}/`: ''}`)