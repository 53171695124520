import { decodeJwt, getToken } from "./token"
/**
 * @returns JWTI Token user data
 */
export function AccountHelper()
{
    return decodeJwt(getToken())
}
/**
 * @description Checks for admin id
 */
export function isAdmin(): boolean
{
    const admin = AccountHelper()
    if(typeof admin.admin_id === "undefined" || admin.admin_id === null)
        return false
    return [1,2].includes(admin.role || 3)
}
/**
 * @description Fetches the admin id if available
 */
export function getAdminId(): number | null
{
    if(!isAdmin())
        return null
    const a = AccountHelper()
    return a.admin_id? a.admin_id : null
}