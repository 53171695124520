import StorageRemove from "../cache.json";

const regKey: string = 'CATTLEPROOF_REG'

export default function CacheService(
    key: string,
    value: string,
    register: boolean = true,
    update: boolean = false
): void
{
    let updatedStore: boolean = false
    const storageListStr = cacheGet(regKey)
    // Check if a storage list is enabled
    const storageList: string[] = (storageListStr.length > 0)? JSON.parse(storageListStr) : []
    // Automatically add static keys
    for (const v of StorageRemove) {
        if(!storageList.includes(v)) {
            storageList.push(v)
        }
    }
    // See if the value is in the storage list
    const paramExists = storageList.includes(key)
    // If it's there
    if(paramExists) {
        // Remove it if unregistering it
        if(!register) {
            storageList.map((v, k) => {
                if(v === key) {
                    updatedStore = true
                    storageList.splice(k)
                }
                return v
            })
        }
    } else {
        // If registering, add to list
        if(register) {
            updatedStore = true
            storageList.push(key)
        }
    }
    // If the request it to update
    if(update) {
        updatedStore = true
        // Remove it first
        cacheDestory(key)
        // If registering, create the cached value
        if(register) {
            updatedStore = true
            cache(key, value)
        }
    }
    // Rebuild the reg
    if(updatedStore) {
        storageList.map((v, k) => {
            if(v === '' || v === null) {
                storageList.splice(k)
            }
            return v
        })
        cache(regKey, JSON.stringify(storageList))
    }
}

export function CacheServiceDestroyListed(
    destroySelf: boolean = false,
    reload: boolean = false
)
{
    const storageListStr = cacheGet(regKey)
    // Check if a storage list is enabled
    const storageList: string[] = (storageListStr.length > 0)? JSON.parse(storageListStr) : []
    if(storageList.length > 0) {
        storageList.map((v) => cacheDestory(v))
        if(destroySelf) {
            cacheDestory(regKey)
        }
    }
    if(reload) {
        window.location.reload()
    }
}

export function cacheRegListExists()
{
    return cacheExists(regKey)
}

export function cacheExists(
    key: string,
    destroy: boolean = false,
    reload: boolean = false
): boolean
{
    const item = cacheGet(key).trim()
    const exists = (item !== '')
    if(destroy && exists) {
        cacheDestory(key, reload)
    }
    return exists
}

export function cache(key: string, value: string, reload: boolean = false)
{
    localStorage.setItem(key, value)

    if(reload)
        window.location.reload()
}

export function cacheGet(key: string, clear: boolean = false): string
{
    const item = localStorage.getItem(key)
    
    if(clear)
        localStorage.removeItem(key)

    return (item)? item : ''
}

export function cacheDestory(item: string | boolean, reload: boolean = false)
{
    (typeof item === "boolean")? localStorage.clear() : localStorage.removeItem(item)
    
    if(reload)
        window.location.reload()
}
/**
 * @description Targets specific keys and removes them whilst leaving others
 */
export function cacheDestroyTargets(reload: boolean = false)
{
    CacheServiceDestroyListed(false, reload)
}

export function cacheResetRegList()
{
    cache(regKey, JSON.stringify(StorageRemove))
}

export function cacheFormData(formName: string, key: string | null = null, value: any | null = null)
{
     // Fetch from storage
    const fetch = cacheGet(formName)
    // Turn to json (or not)
    const form = (fetch !== '' && fetch !== null)? JSON.parse(fetch) : {}
    // If there is something to store
    if(key !== null) {
        // Add the value
        form[key as keyof typeof form] = value
    }
    // Save back
    CacheService(formName, JSON.stringify(form), true, true)
    // Return the stored
    return form
}