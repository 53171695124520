import { Button } from "@mui/material";

export const ModalContent = (props: any) =>
{
    return  (
    <div className="md-modal">
        <p>If you click okay, you will lock this herd and will not be able to make further changes. If you are satisfied that this Calving Group is accurate, you can click OKAY which will notify CattleProof that you are ready to commit the herd data to decentralized storage for public access.</p>
        <div className="align-middle">
            <p>Would you like to lock this Calving Group?</p>
            <div className="col-count-2 gapped col-c1-md">
                <Button variant="outlined" onClick={() => {props.lockHerdGroup(true); props.setGidLockPrompt(false)}}>Okay</Button>
                <Button variant="contained" onClick={() => props.setGidLockPrompt(false)}>Cancel</Button>
            </div>
        </div>
    </div>)
}