import ICattleFormSingleViewDto, { CattleFormSingleViewDto } from "../dto/animal-record"
import { BaseDto } from "../dto/base.dto"
import { GroupListDto } from "../dto/calving-group-form.dto"
import HttpClient from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"

export interface ICalvingGroupForm
{
    ID?: string | null
    unique_id?: string | null
    group_name?: string
    group_type?: string
    locations?: string[]
    tags_applied?: any
    calving_dates?: any
    calving_tags?: any
    id_description?: string
    approx_calves?: number
    cattle_left_origin?: string
    map_id?: string | null
    status?: number | null
    herd_count?: number | null
    paid?: number | null
    payment_id?: string | null
    decentralized?: number | null
    certified_at?: string | null
}

export type IGroupList = {
    valid: false,
    count: number,
    uid?: string,
    groups?: ICalvingGroupForm[]
}

export interface IGroupTypes
{
    ID?: string
    type_id?: number
    uid?: string
    name: string
    shortcode?: string | null
    group_type?: string,
    group_type_id?: string,
    description: string
    sort_order: number
    status: number
    deleted_at?: string
    updated_at?: string
    created_at: string
    certs_available: any[]
    matrix: { [key: string]: {
        enable: any[],
        disable: any[],
    }}
}

export const GroupTypesService = () => (new HttpClient()).get<IResponse<IGroupTypes[]>>('group/types')

export class GroupCreateDto extends BaseDto
{
    ID: string | null = null
    group_name: string = ''
    calving_dates: any[] = []
    tags_applied: any[] = []
    locations: string[] = []
    approx_calves: number = 0
    cattle_left_origin: string = ''
    id_description: string = ''
    calving_tags: any[] = []
    certified_at?: string | null = null

    constructor(obj: any)
    {
        obj.calving_dates = {
            start: obj.born_start,
            end: obj.born_end
        }
        super()
        if(typeof obj.tags_applied_desc !== "undefined" && obj.tags_applied_desc !== null) {
            obj.tags_applied.other = obj.tags_applied_desc
        }
        obj.calving_tags = obj.tag
        this.populate(obj, this)
    }
}

export type IGroupCreateResponse = {
    success: boolean,
    error?: string,
    group: GroupCreateDto
}

export default function Groups(getAll: boolean = true): Promise<GroupListDto>
{
    // Call the groups
    return new Promise<GroupListDto>((success, fail) => {
        try {
            (new HttpClient()).get<GroupListDto>(`groups${getAll? '?all='+(getAll? 'true' : 'false') : ''}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function GroupCreate(body: ICalvingGroupForm): Promise<IGroupCreateResponse>
{
    return (new HttpClient()).post<IGroupCreateResponse>(`groups`, body)
}

export function GroupUpdate(body: ICalvingGroupForm): Promise<IGroupCreateResponse>
{
    // Call the groups
    return (new HttpClient()).patch<IGroupCreateResponse>(`groups`, body)
}

export function GroupDelete(gid: string): Promise<IGroupCreateResponse>
{
    // Call the groups
    return (new HttpClient()).delete<IGroupCreateResponse>(`groups`, { gid })
}

type IHerd = {
    success: boolean,
    count: number,
    herd: any[],
    lockable: boolean
}
type IAnimal = {
    success: boolean,
    error?: string,
    animal: CattleFormSingleViewDto
}
/**
 * @description Fetches a herd based on the group id
 * @param groupId The id of the herd group 
 */
export function Herd(groupId: string, search?: string): Promise<IHerd>
{
    // Call the groups
    return new Promise<IHerd>((success, fail) => {
        try {
            (new HttpClient()).get<IHerd>(`inventory/${groupId}/`, search? {search} : undefined).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}
/**
 * @description Updates an animal
 * @param hid The id of the herd group 
 */
export function Animal(animalData: CattleFormSingleViewDto | ICattleFormSingleViewDto): Promise<IAnimal>
{
    return new Promise<IAnimal>((success, fail) => {
        try {
            (new HttpClient()).patch<IAnimal>(`animal/${animalData.ID}`, animalData).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}
/**
 * @description Creates an animal
 * @param hid The data of the herd group 
 */
export function AnimalCreateService(animalData: CattleFormSingleViewDto | ICattleFormSingleViewDto): Promise<IAnimal>
{
    return new Promise<IAnimal>((success, fail) => {
        try {
            (new HttpClient()).post<IAnimal>(`animal/create`, animalData).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}
/**
 * @description Creates an animal
 * @param hid The data of the herd group 
 */
export function AnimalDeleteService(hid: string): Promise<any>
{
    return new Promise<any>((success, fail) => {
        try {
            (new HttpClient()).delete<any>(`animal/delete`, {hid}).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function HerdAttribute<T>(groupId: string, rowId: string, attr: string, value: any): Promise<T>
{
    // Call the groups
    return new Promise<T>((success, fail) => {
        try {
            (new HttpClient()).post<T>(`inventory/attribute/${groupId}/`, { rowId,attr,value }).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function GroupLock<T>(groupId: string): Promise<T>
{
    // Call the groups
    return new Promise<T>((success, fail) => {
        try {
            (new HttpClient()).post<T>(`inventory/lock/`, { groupId }).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function GroupsRequirePayment(): Promise<any>
{
    return new Promise<any>((success, fail) => {
        try {
            (new HttpClient()).get<any>(`widget/payment/`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function InventoryPreflightService(gid: string): Promise<any>
{
    return (new HttpClient()).get<any>(`preflight/inventory/`, {gid})
}

// export function setAttr(key: string, value: any, http: HttpClient): Promise<ISetting>
// {
//     return http.post<ISetting>(`setting/${key}`, { value })
// }

// export function removeAttr(key: string, http: HttpClient)
// {
//     return http.delete<{success: boolean}>(`setting/${key}`)
// }