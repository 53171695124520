import { IButtonAny } from "..";
import __ from "../../../../services/translator";

export default function ButtonEdit({ text }: IButtonAny)
{
    return (
        <span className="strong">
            <i className="far fa-edit"></i>&nbsp;{ text || __('Edit', 'edit') }
        </span>
    )
}