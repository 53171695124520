import { useContext, useEffect, useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { TextField, Button, Checkbox, FormControlLabel, FormLabel, RadioGroup, Radio, FormGroup } from "@mui/material"
import { ICalvingGroupForm, GroupCreate, GroupUpdate, GroupDelete } from '../../../services/herd-groups'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AlertSuccess, AlertDanger } from "../../ui/views/alerts"
import { CalvingGroupFormCalvingTagsDto, CalvingGroupFormDto, CalvingGroupFormTagsAppliedDto } from "../../../dto/calving-group-form.dto"
import { CowHeadIcon } from "../../ui/fontawesome"
import { ModalContext } from "../../../providers/modal.provider"
import dayjs from 'dayjs'
import ButtonBack from "../../ui/buttons/button-back"
import ButtonDelete from "../../ui/buttons/button-delete"
import __ from "../../../services/translator"
import './styles.scss'
import { HerdGroupsContext } from "../../../providers/herd-groups.provider"
import { empty } from "../../../helpers/util.helper";
import { ModalContentGeneric } from "../../ui/blocks";

type ICalvingGroupFormCreate = {
    createNew: any,
    createNewState: boolean
    toEditGid: string
}

export default function CalvingGroupFormCreate({ createNew, createNewState, toEditGid }: ICalvingGroupFormCreate)
{
    const [ formData, setFormData ] = useState<ICalvingGroupForm>({})
    const { handleSubmit } = useForm();
    const [ error, setError ] = useState('')
    const [ isEditing, setIsEditing ] = useState(false)
    // const [ submitReady, setSubmitReady ] = useState(true)
    // const [ success, setSuccess ] = useState(false)

    const [ leftOrigin, setLeftOrigin ] = useState(false)

    const [ ranchTag, setRanchTag ] = useState(false)
    const [ eidTag, setEidTag ] = useState(false)
    const [ metallicTag, setMetallicTag ] = useState(false)
    const [ brandTag, setBrandTag ] = useState(false)
    const [ notchTag, setNotchTag ] = useState(false)
    const [ otherTag, setOtherTag ] = useState(false)

    const [ calving, setCalving ] = useState(false)
    const [ weaning, setWeaning ] = useState(false)
    const [ branding, setBranding ] = useState(false)
    const [ other, setOther ] = useState(false)

    const [ hideForm, setHideForm] = useState(false)
    const [ sectionError, setSectionError ] = useState<string>('')
    const clear = (key: string) => {
        const f = JSON.parse(JSON.stringify(formData || {}))
        f[key as keyof typeof f] = ''
        setFormData(new CalvingGroupFormDto(f))
    }

    const [ gid, setGid ] = useState<string>(toEditGid)
    const [ saving, setSaving ] = useState<boolean>(false)

    const { setModalActive, setModalBody, setModalTitle, setModalButtons, setModalEvent, setModalAttr } = useContext(ModalContext)
    const { groups, setGroupsState } = useContext(HerdGroupsContext)

    /**
     * @description     Generate values for tag rows
     */
    const updateAndStoreTags = (v: string, value: any) => {
        const list = formData?.calving_tags || []
        list[v as keyof typeof list] = value
        setFormData(toFormData('calving_tags', new CalvingGroupFormCalvingTagsDto(list)))
    }
    /**
     * @description     Generate values for location rows
     */
    const updateAndStoreLocations = (k: number = 0, v: string) => {
        const locationList: string[] = formData?.locations || []
        locationList[k] = v
        setFormData(toFormData('locations', locationList))
    }
    /**
     * @description     Generate values for tags_applied rows
     */
    const updateAndStoreTagsApplied = (k: string, value: any ) => {
        if(typeof formData.tags_applied !== "undefined") {
            const list = JSON.parse(JSON.stringify(formData.tags_applied))
            list[k as keyof typeof list] = value
            setFormData(toFormData('tags_applied', new CalvingGroupFormTagsAppliedDto(list)))
        }
    }
    /**
     * @description Removes out a value from locations based on the key value supplied by the paramter
     */
    const removeLocation = (key: any) => {
        const locationList: string[] = []
        formData?.locations?.map((v, k) => {
            if(key !== k) {
                locationList.push(v)
            }
            return v
        })
        setFormData(toFormData('locations', locationList))
    }
    /**
     *  @description Submit group creation
     */
    const onSubmit: SubmitHandler<ICalvingGroupForm> = () =>
    {
        setSaving(true)
        setSectionError('')
        // Notify of submission
        //setSubmitReady(false)
        // Clear errors
        setError('')
        if(formData?.locations?.length === 0) {
            setError('You need to have at least one location where you keep your herd. Click "Add New Location" to add your herd location.')
            setSectionError('locations')
            setSaving(false)
            return null
        }
        // Check for empty tag info
        let ct: number = 0
        let cta: number = 0
        const t = Object.keys(formData?.calving_tags)
        const ta = Object.keys(formData?.tags_applied)
        t.map((v) => {
            if(empty(formData?.calving_tags[v as keyof typeof t])) {
                ct += 1
            }
        })
        if(ct === t.length) {
            setError('At least one tag description is required.')
            setSectionError('tagstype')
            setSaving(false)
            return null
        }
        ta.map((v) => {
            if(empty(formData?.tags_applied[v])) {
                cta += 1
            }
        })
        if(cta === ta.length) {
            setError('You must supply the timeframe in which your tags are applied.')
            setSectionError('tagsapplied')
            setSaving(false)
            return null
        }
        // Try and create the group
        const groupSave = (formData?.ID !== null)? GroupUpdate(formData) : GroupCreate(formData)
        // Save back
        groupSave.then(r => {
            // Reset the form
            if(r.success) {
                //setSuccess(true)
                setHideForm(true)
                //setGroupsLoaded(false)
                // goBack()
            } else {
                //setSuccess(false)
                setError(r.error? r.error : '')
            }
            //setSubmitReady(true)
        })
    }

    // Allow for editing vs create
    if(gid !== '0' && !isEditing) {
        setIsEditing(true)
        groups.groups?.map((v: any) => {
            if(v.ID === gid) {
                setFormData(v)
            }
            return v
        })
    }

    const toFormData = (key: string, value: any): ICalvingGroupForm => {
        const f = ({...formData})
        f[key as keyof typeof formData] = value
        return f
    }

    const goBack = () => {
        createNew(false)
        setIsEditing(false)
    }

    const deleteGroup = (gid: string) => {
        GroupDelete(gid).then(r => {
            if(r.success) {
                setSaving(true)
                // Close the modal
                setModalActive(false)
                setGid('0')
                // Set the success
                //setSuccess(true)
                // Hide the form
                setHideForm(true)
                // Back out of the form
                goBack()
            }
        })
    }

    useEffect(() => {
        if(saving) {
            setGroupsState({ready: false, loading: false, completed: false})
            setSaving(false)
        }
    }, [ saving ])

    return ( hideForm? 
        <div className="align-middle">
            <AlertSuccess text={`You have successfully ${ (formData.ID !== null)? 'updated your' : 'created a new'} Calving Group.`} />
            <Button className="py-3 px-5" variant="outlined" onClick={() => { goBack(); setGid('0') } }><ButtonBack /></Button>
        </div> : 
        <form onSubmit={ handleSubmit(onSubmit) } className="col-count-2 col-c1-md gapped mt-4">
            <div className="span2 span1-md">
                { error? <AlertDanger text={error} /> : null }
            </div>

            <TextField
                className="span2 span1-md"
                label={__('Calving Group Name (example: Spring):', 'calving_group_name')}
                value={ formData?.group_name || '' }
                onChange={e => {
                    setFormData(toFormData('group_name', e.target.value))
                 }}
                variant="outlined"
                required
            />

            <FormLabel
                id="cattle-left-origin"
                className="span2 span1-md">
                    {__('Have the cattle to be enrolled left the ranch of origin at any time?:', 'describe_where_went')}
            </FormLabel>

            <RadioGroup aria-labelledby="cattle-left-origin" defaultValue="0" name="cattle_left_origin" className="span2 span1-md">
                <FormControlLabel value="0" control={<Radio checked={ !leftOrigin && empty(formData?.cattle_left_origin) } />} label="No" onClick={ () => {
                    setLeftOrigin(false)
                    clear('cattle_left_origin')
                } } />
                <FormControlLabel value="1" control={<Radio checked={ leftOrigin || !empty(formData?.cattle_left_origin)} />} label="Yes" onClick={() => setLeftOrigin(true)} />
            </RadioGroup>

            { (leftOrigin || (typeof formData.cattle_left_origin !== "undefined" && formData.cattle_left_origin !== ''))? 
                <TextField
                    className="span2 span1-md"
                    label={ __('Please describe when they left and where they went:', 'describe_where_went') }
                    value={ formData?.cattle_left_origin || '' }
                    onChange={ e => setFormData(toFormData('cattle_left_origin', e.target.value)) }
                    variant="outlined"
                    required={ true }
                /> : null }


            <FormLabel>{__('List all locations where cattle have been or will be located.', 'locations')}</FormLabel>
            {
                formData?.locations?.map((v, k) => {
                    return (
                        <div className="span2 span1-md edit-field-row" key={k}>
                            <TextField placeholder={__('Location', 'location')} value={v} onChange={e => {
                                updateAndStoreLocations(k, e.target.value)
                            }} variant="outlined" required />{ (v !== '0' && k !== 0)? <Button key={`b${v}`} variant="text" onClick={() => {
                                removeLocation(k)
                            }}><i className="fas fa-minus-circle"></i></Button> : null}
                        </div> 
                    )
                })
            }

            <div className="span2 span1-md col-count-4 col-c3-lg col-c2-md col-c1-sm mb-4">
                <Button variant="contained" onClick={() => {
                    const c = formData?.locations?.length
                    updateAndStoreLocations(c, '')
                }}>Add new location&nbsp;<i className="fas fa-plus"></i></Button>
            </div>

            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={__('Date of First Calf Born', 'born_start')}
                value={ dayjs(formData?.calving_dates?.start || '')}
                onChange={v => {
                    setFormData(arr => ({...arr, calving_dates: {...arr?.calving_dates, start: v}}))
                }}
                slots={{
                    textField: (params: any) => <TextField {...params} />
                }}
            />
            </LocalizationProvider>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={__('Date of Last Calf Born', 'born_end')}
                value={ dayjs(formData?.calving_dates?.end || '')}
                onChange={v => {
                    setFormData(arr => ({...arr, calving_dates: {...arr?.calving_dates, end: v}}))
                }}
                slots={{
                    textField: (params: any) => <TextField {...params} />
                }}
            />
            </LocalizationProvider>
            
            <div className="col-count-5 col-c3-md col-c1-sm">
                <TextField 
                    className="span2 span1-md"
                    label={__('Approx. # Calves', 'approx_calves')}
                    value={ formData?.approx_calves || 1 }
                    onChange={e => {
                        let f = !isNaN(+e.target.value)? parseInt(e.target.value) : 1
                        if(f <= 0) {
                            f = 1
                        }
                        setFormData(toFormData('approx_calves', f))
                    }}
                    variant="outlined"
                    required
                />
            </div>
            
            {/* <TextField
                className="span2 span1-md"
                label={__('Describe calves identification (i.e. blue ranch tag at birth with cow\'s #, brand in spring, etc.)', 'desc_calve_id')}
                value={ formData?.id_description || '' }
                onChange={e => {
                    setFormData(toFormData('id_description', e.target.value))
                }}
                variant="outlined"
                required
            /> */}

            <FormGroup className={`span2 span1-md ${sectionError === 'tagstype'? 'section-errors' : ''}`}>
                { sectionError === 'tagstype'? <AlertDanger text={error} /> : null }
                <FormControlLabel control={<Checkbox checked={ ranchTag || !empty(formData?.calving_tags?.ranch) || false } />} label="Ranch Tag/Visual Tag/Panel Tag" onChange={(e: any) => {
                        setRanchTag(e.target.checked)
                        if(!e.target.checked) {
                            updateAndStoreTags('ranch', '')
                        }
                    }}
                />
                { (ranchTag || !empty(formData?.calving_tags?.ranch))? <TextField className="span2 span1-md" label={__('Describe Ranch Tag (Numbering Scheme, Color of Tag, etc.)', 'describe_ranch_tag')} value={formData?.calving_tags?.ranch} onChange={e => { updateAndStoreTags('ranch', e.target.value) }} variant="outlined" required /> : null }


                <FormControlLabel control={<Checkbox checked={eidTag || !empty(formData?.calving_tags?.eid) || false} />} label="EID Tags" onChange={(e: any) => {
                    setEidTag(e.target.checked)
                    if(!e.target.checked) {
                        updateAndStoreTags('eid', '')
                    }
                }} />
                { eidTag || !empty(formData?.calving_tags?.eid)? <TextField className="span2 span1-md" label={__('Describe EID Tag (Tag Manufacturer)', 'describe_eid_tag')} value={formData?.calving_tags?.eid} onChange={e => { updateAndStoreTags('eid', e.target.value) }} variant="outlined" required /> : null}


                <FormControlLabel control={<Checkbox checked={metallicTag || !empty(formData?.calving_tags?.metalplastic_clip) || false} />} label="Metal/Plastic clip tags" onChange={(e: any) => {
                  setMetallicTag(e.target.checked)
                  if(!e.target.checked) {
                    updateAndStoreTags('metalplastic_clip', '')
                  }
                }} />
                { metallicTag || !empty(formData?.calving_tags?.metalplastic_clip)? <TextField className="span2 span1-md" label={__('Describe Metal/Plastic Clip Tag (Numbering Scheme, Color of Tag, etc.)', 'describe_mp_tag')} value={formData?.calving_tags?.metalplastic_clip} onChange={e => { updateAndStoreTags('metalplastic_clip', e.target.value) }} variant="outlined" required /> : null }


                <FormControlLabel control={<Checkbox checked={brandTag || !empty(formData?.calving_tags?.brands) || false} />} label="Brands" onChange={(e: any) => {
                    setBrandTag(e.target.checked)
                    if(!e.target.checked) {
                        updateAndStoreTags('brands', '')
                    }
                }} />
                { brandTag || !empty(formData?.calving_tags?.brands)? <TextField className="span2 span1-md" label={__('Describe Brand (Ex. quarter circle on left hip)', 'describe_brands')} value={formData?.calving_tags?.brands} onChange={e => { updateAndStoreTags('brands', e.target.value) }} variant="outlined" required /> :  null }


                <FormControlLabel control={<Checkbox checked={notchTag ||  !empty(formData?.calving_tags?.ear_notches) || false } />} label="Ear Notches" onChange={(e: any) => {
                    setNotchTag(e.target.checked)
                    if(!e.target.checked) {
                        updateAndStoreTags('ear_notches', '')
                    }
                }} />
                { notchTag || !empty(formData?.calving_tags?.ear_notches)? <TextField className="span2 span1-md" label={__('Describe Ear Notch and Location', 'describe_notches')} value={ formData?.calving_tags?.ear_notches } onChange={e => { updateAndStoreTags('ear_notches', e.target.value) }} variant="outlined" required /> : null }


                <FormControlLabel control={<Checkbox checked={otherTag || !empty(formData?.calving_tags?.other_tag) || false} />} label="Other" onChange={(e: any) => {
                    setOtherTag(e.target.checked)
                    if(!e.target.checked) {
                        updateAndStoreTags('other_tag', '')
                    }
                }} />
                { otherTag || !empty(formData?.calving_tags?.other_tag)? <TextField label={__('Describe Your Tag (Numbering Scheme, Color of Tag, etc.)', 'describe_other')} value={formData?.calving_tags?.other_tag} onChange={e => { updateAndStoreTags('other_tag', e.target.value) }} variant="outlined" required /> : '' }

            </FormGroup>
            
            <FormGroup className={`span2 span1-md ${sectionError === 'tagsapplied'? 'section-errors' : ''}`}>
                { sectionError === 'tagsapplied'? <AlertDanger text={error} /> : null }
                <label>When will EID tags or other program compliant tags be applied to calves?</label>
                <FormControlLabel control={<Checkbox checked={calving || formData?.tags_applied?.calving === 1 || false} />} label="at Calving" onChange={(e: any) => {
                    setCalving(e.target.checked)
                    updateAndStoreTagsApplied('calving', e.target.checked )
                }} />
                <FormControlLabel control={<Checkbox checked={branding || formData?.tags_applied?.branding === 1 || false} />} label="at Branding" onChange={(e: any) => {
                    setBranding(e.target.checked)
                    updateAndStoreTagsApplied('branding', e.target.checked )
                }} />
                <FormControlLabel control={<Checkbox checked={weaning || formData?.tags_applied?.weaning === 1 || false} />} label="at Weaning" onChange={(e: any) => {
                    setWeaning(e.target.checked)
                    updateAndStoreTagsApplied('weaning', e.target.checked )
                }} />
                <FormControlLabel control={<Checkbox checked={other || (formData?.tags_applied?.other !== '' && typeof formData?.tags_applied?.other !== 'undefined') || false} />} label="Other" onChange={(e: any) => {
                    setOther(e.target.checked)
                    if(!e.target.checked) {
                        updateAndStoreTagsApplied('other', '' )
                    }
                }} />
                { (other || !empty(formData?.tags_applied?.other))?
                    <TextField
                        label={__('Describe Other', 'describe_other')}
                        value={formData?.tags_applied?.other || ''}
                        onChange={ e => {
                            const f = ({...formData})
                            f.tags_applied.other = e.target.value
                            setFormData(f)
                        }}
                        variant="outlined"
                        required
                    /> : null }
            </FormGroup>

            <div className="span2 span1-md align-middle mt-2">
                    {
                        (formData.ID === null)? 
                <div className="col-count-2 gapped">
                    <Button className="py-3 px-5" variant={!createNewState? 'contained' : 'outlined'} onClick={() => createNew(!createNewState)}>{createNewState? <ButtonBack /> : 'Create Group'}</Button>
                    <Button className="py-3 px-5" type="submit" variant="contained"><i className="fas fa-plus"></i><CowHeadIcon />&nbsp;Create</Button>
                </div> :
                <div className="col-count-3 col-c2-lg col-c1-md gapped">
                    <Button className="py-3 px-5" variant="outlined" onClick={goBack}><ButtonBack /></Button>
                    { (gid !== '0')?  
                    <Button className="py-3 px-5" variant="outlined" onClick={() => {
                        setModalActive(true)
                        // Allow delete
                        setModalAttr({ width: 'w-md' })
                        setModalTitle(__('Are you sure?', 'areyousure'))
                        setModalBody(
                            <ModalContentGeneric
                                title={ __('Are you sure?', 'areyousure') }
                                description={ __('Are you sure you want to delete this group? Click the delete button to remove this group and all the herd data associated with it. This action is not undoable.', 'delete_group') }
                            />);
                        setModalButtons(
                            <div className="col-count-2 gapped">
                                <Button className="py-3 px-5" variant="contained" onClick={() => {
                                    if(formData.ID) {
                                        deleteGroup(formData.ID)
                                    }
                                }}><ButtonDelete /></Button>
                                <Button className="py-3 px-5" variant="outlined" onClick={() => setModalActive(false)}><ButtonBack text={__('Cancel', 'cancel')} /></Button>
                            </div>)
                        if(setModalEvent)
                            setModalEvent(() => () => setModalActive(false)
                        )
                    }}><ButtonDelete /></Button> : null }
                    <Button className="py-3 px-5" type="submit" variant="contained"><i className="fas fa-sd-card"></i>&nbsp;{ gid !== '0' && [null, 3].includes(formData.status || null)? 'Update' : 'Save'  }</Button>
                </div>
                    }
            </div>

        </form>
    )
}