import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { getVal, onTextChangeEvent } from "../submodule.base"

interface IModuleComponentsRadioComponent
{
    name: string
    form: any
    setForm: any
    options?: any[]
    label?: string | JSX.Element
    replace?: string
    disabled?: boolean
    defaultValue?: string
}

export const ModuleComponentsRadioComponent = ({
    label,
    name,
    form,
    setForm,
    replace,
    options,
    disabled,
    defaultValue
}: IModuleComponentsRadioComponent) =>
{
    if(!options)
        options = [
            {
                "label": "NO",
                "value": "0"
            },
            {
              "label": "YES",
              "value": "1"
            }
        ]
    return (
    <FormControl component="fieldset">
        {label? <FormLabel component="legend">{label}</FormLabel> : null }
        <RadioGroup
            name={ name }
            value={ getVal(form, name, '') || defaultValue || '0' }
            onChange={ (e: any) => onTextChangeEvent(e, setForm, replace)}
        >
            { options?.map((option, idx) => (
                <FormControlLabel
                    key={ idx }
                    value={ option.value }
                    control={ <Radio /> }
                    label={ option.label }
                    disabled={ disabled }
                />
            )) }
        </RadioGroup>
    </FormControl>
    )
}