import { ReactNode, createContext, useEffect, useState } from "react"
import { IContextChildren } from "../interfaces/context-children.inteface"

export interface IAlertContext
{
    msg: string | JSX.Element | ReactNode
    type: boolean
    setMsg: any
    setType: any
    setCloseable?: any
    closeable?: boolean
    alt?: string
    setAlt?: any
    iconType?: string
    setIconType?: any
    setAutoClose?: any
    autoClose?: number | boolean
}

export const AlertContext = createContext<IAlertContext>({ msg: '', setMsg: () => {}, type: false, setType: () => {}})

export const AlertProvider = ({children}: IContextChildren) => {
    const [ msg, setMsg ] = useState<string | JSX.Element | ReactNode>('')
    const [ type, setType ] = useState<boolean>(false)
    const [ closeable, setCloseable ] = useState<boolean>(false)
    const [ iconType, setIconType ] = useState<string>('')
    const [ autoClose, setAutoClose ] = useState<number|undefined|boolean>(undefined)

    useEffect(() => {
        if(msg === '') {
            setType(false)
            setIconType('')
        }
    }, [ msg ])
    
    return (
    <AlertContext.Provider
        value={{
            msg, setMsg,
            iconType, setIconType,
            type, setType,
            closeable, setCloseable,
            autoClose, setAutoClose
        }}
    >
        { children }
    </AlertContext.Provider>
    )
}