import ImgLogo from '/src/media/images/logo.png'
import __ from '../../../services/translator'
import './styles.scss'

type IImageCompanyLogo = {
    className?: null | string
}

export default function ImageCompanyLogo({ className }: IImageCompanyLogo)
{
    return (
        <img src={ImgLogo} alt={__('Cattle Proof Verified Logo', 'cpv_logo_alt')} className={ className || 'logo'} />
    )
}