import { randomStr } from "../../helpers/util.helper"
import { IContextChildren } from "../../interfaces/context-children.inteface"

interface ITemplatesCattleProofCenterBlock extends IContextChildren
{
    id?: string
    startClass?: string
    addClass?: string
    className?: string
    gapped?: boolean
}

const TemplatesCattleProofCenterBlock = ({ children, className, id, startClass, gapped, addClass }: ITemplatesCattleProofCenterBlock) => {
    return (
        <div className={ `${className || 'col-count-3 offset col-c1-lg'} ${ addClass || ''} ${(gapped || gapped === undefined)? 'gapped' : ''}` } id={ id || `untitled-id-${randomStr(4)}` }>
            <div className={ startClass || 'start2 start1-lg' }>
                { children }
            </div>
        </div>
    )
}

export default TemplatesCattleProofCenterBlock