import { memo, ReactNode, useContext } from "react"
import { FasFaFileImport } from "../../fontawesome"
import { NavLink } from "react-router-dom"
import { isAdmin } from "../../../../helpers/account"
import { ApplicationContext } from "../../../../providers/application.provider"
import { SSOHook } from "../../../../hooks/sso.hook"
import { empty } from "../../../../helpers/util.helper"
import { AccountContext } from "../../../../providers/account.provider"
import { environment } from "../../../../environmentals/config"
import { isString } from "../../../../helpers/stringworks.helper"
import LabelIcon from '@mui/icons-material/Label';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import __ from "../../../../services/translator"
import './styles.scss'

export const NavSidebar = memo(function NavSidebar(props?: any)
{
    const { account } = useContext(AccountContext)
    const { setTriggerSso } = SSOHook()
    // const { setGroupsState, setGid } = useContext(HerdGroupsContext)
    const { setToggleForm } = useContext(ApplicationContext)
    // const [ token, setToken ] = useState<string>('')
    const admin = isAdmin()

    const sso = () => setTriggerSso(true)

    const toggleOffForm = () => {
        if(props.clickEvent)
            props.clickEvent()
        setToggleForm(false)
    }

    const isActive = (path: string) => window.location.pathname.match(new RegExp(`${path}`, 'gi'))? 'active' : ''
    return (
        <div className={`left-nav shadowed sidebar ${!admin? 'admin-grad' : 'bkg-corp-grad'} ${account?.account_type === 'buyer'? 'buyer-view' : ''}`}>
            <ul className="list-menu">
                { admin? <li className="bkg-corp-dark"><h6 className="text-white p-3"><i className="fas fa-binoculars"></i>&nbsp;<strong>ADMIN MODE</strong></h6></li> : null }
                { account?.account_type === 'buyer'? <li className="bkg-corp-dark txt-center text-center"><h6 className="text-white p-3"><SettingsApplicationsIcon />&nbsp;<strong>LIMITED FEATURES</strong></h6></li> : null }
                
                <NavLinkSidebar
                    to="/"
                    icon={ <FasFaFileImport /> }
                    text={ __('My Groups', 'my_groups') }
                    clickEvent={ toggleOffForm }
                    className={`${isActive('group')} ${empty(window.location.pathname)? 'active' : ''}`}
                />
                <NavLinkSidebar
                    to="/tag-manager/"
                    icon={ <LabelIcon style={{transform: 'rotate(-65deg)'}} /> }
                    text={ __('Tag Manager', 'tag_manager') }
                    clickEvent={ toggleOffForm }
                />
                <NavLinkSidebar
                    to="/certified-herds/"
                    icon="fas fa-award"
                    text={ __('Certified Groups', 'certified_groups') }
                    clickEvent={ toggleOffForm }
                />
                <NavLinkSidebar
                    to="/disposition/"
                    icon="fas fa-map-marker-alt"
                    text={ __('Disposition', 'disposition') }
                    clickEvent={ toggleOffForm }
                />
                <NavLinkSidebar
                    to="/settings/"
                    icon={ <SettingsApplicationsIcon /> }
                    text={ __('Settings', 'settings') }
                    clickEvent={ toggleOffForm }
                />
                <NavLinkSidebar
                    to="/explorer/"
                    icon={ <ScreenSearchDesktopIcon /> }
                    text={ __('Explorer', 'explorer') }
                    clickEvent={ toggleOffForm }
                />
                <NavLinkSidebar
                    to={ !admin? '#' : environment.account }
                    icon="fas fa-user"
                    text={ !admin? __('Account', 'account') : 'Admin' }
                    clickEvent={ !admin? sso : null }
                    useLink={ true }
                />
            </ul>
        </div>
    )
})

export const NavLinkSidebar = ({ to, icon, text, clickEvent, className, useLink = false }: { to: string, icon: any, text: string | JSX.Element | ReactNode, clickEvent?: any, useLink?: boolean, className?: string }) => (
    <li>
        { !useLink && <NavLink to={ to } className={ className || '' } onClick={ clickEvent }>{ isString(icon)? <i className={ icon }></i> : icon }&nbsp;{ text }</NavLink> }
        { useLink && <a href={ to } className={ className || '' } onClick={ clickEvent }>{ isString(icon)? <i className={ icon }></i> : icon }&nbsp;{ text }</a> }
    </li>
)