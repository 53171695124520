import { Button } from "@mui/material";
import { ReactNode, useState } from "react";
import { empty } from "../../../helpers/util.helper";

const UIBlockTabsComponent = ({ defaultTab, tabs, variant }: { variant: 'contained' | undefined, tabs: { shortcode: string, label: string | JSX.Element | ReactNode, content: any, ignore?: boolean }[], defaultTab: string }) => {
    const [currToggle, setCurrToggle] = useState<string>(defaultTab);
    
    return (
        <div className="tab-container">
            <ul className={`tabs tab-menu ${variant === 'contained'? 'tabs-contained' : ''}`}>
                { tabs.filter((v) => v?.ignore !== true && !empty(v?.content)).map((tab, i) => (
                    <li key={`tab-btn-${i}`} className={`tab-menu-item ${currToggle === tab.shortcode? 'active' : '' }`}>
                        <Button data-type="range" onClick={ () => setCurrToggle(tab.shortcode) }>{ tab.label }</Button>
                    </li>
                ))}
            </ul>
            <ul className="tab-content" style={{minHeight: '100px'}}>
                { tabs.filter((v) => v?.ignore !== true && !empty(v?.content)).map((tab, i) => (
                    <li key={`tab-content-${i}`}
                        className={`tab-content-item ${ currToggle === tab.shortcode? 'active' : '' }`}
                        style={{minHeight: '100px'}}
                    >
                        { tab.content }
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default UIBlockTabsComponent;