import { DataGrid, GridColDef, GridRowModesModel, GridRowSelectionModel } from "@mui/x-data-grid"
import PermissionsDataGridRowsComponent from "../../permissions/datagrid-rows"
import { filterMuiColumns } from "../../../helpers/arrayworks.helper"
import { Button } from "@mui/material"
import { useState } from "react"

interface IDataGridRowsBlockComponent<T = any, Z = any>
{
    children?: Z
    columns: GridColDef[]
    rows: T[]
    label: string | JSX.Element
    rowModesModel: GridRowModesModel
    onRowsModelChange?: (e: any) => any
    processRowUpdate: (e: any) => any
    setCheckedRows?: (e: any) => void
    filter?: string[]
    checkbox?: boolean
    onRowsDeleteEvent?: (e: GridRowSelectionModel) => void
}

export const DataGridRowsBlockComponent = ({
    children,
    setCheckedRows,
    checkbox,
    label,
    columns,
    rowModesModel,
    onRowsModelChange,
    processRowUpdate,
    filter,
    onRowsDeleteEvent,
    rows = []
}: IDataGridRowsBlockComponent ) => {
    const [ selectedRows, setSelectedRows ] = useState<string[]>([]);

    let finalColumns = columns;
    if(filter) {
        finalColumns = filterMuiColumns(columns, rows, filter);
    }
    return (
        <PermissionsDataGridRowsComponent
            rows={ rows }
            label={ label }
        >
            { onRowsDeleteEvent && checkbox && 
            (<div className="data-grid-rows-block">
                <Button onClick={ () => onRowsDeleteEvent(selectedRows) }>Delete</Button>
            </div>)}
            <div style={{ height: rows.length > 0? 'auto' : '200px', minHeight: '200px' }}>
                <DataGrid
                    getRowId={(e: any) => e.ID}
                    onRowSelectionModelChange={(v: GridRowSelectionModel) => {
                        onRowsDeleteEvent && setSelectedRows(v.map((z) => z.toString()));
                        setCheckedRows && setCheckedRows(v);
                    }}
                    //onProcessRowUpdateError={(e) => e}
                    rowModesModel={ rowModesModel }
                    onRowModesModelChange={ onRowsModelChange }
                    // onCellEditStart={(e) => {
                    //     setDataTableTarget(e.field)
                    // }}
                    processRowUpdate={ processRowUpdate }
                    rows={ rows }
                    columns={ finalColumns }
                    checkboxSelection={ checkbox }
                />
            </div>
            { children || '' }
        </PermissionsDataGridRowsComponent>
    )
}