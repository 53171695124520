import HttpClient from "../../../helpers/client"
import { IResponse } from "../../../interfaces/response.interface"

interface IShoppingCartGetServiceItemCodes
{
    sku: string
    qty: number
}

interface IShoppingCartGetService
{
    itemcodes: IShoppingCartGetServiceItemCodes[]
}

interface IShoppingCartGetServiceResponseItems
{
    ID: string
    country: string
    currency: string
    description: string | JSX.Element | React.ReactNode
    hosted: number
    image: string
    item_code: string
    price_1: number
    subtotal: number
    qty: number
    sort_order: number
    status: number
    title: string
    type: number  | 'fee' | 'goods' | 'services' 
}

export interface IShoppingCartGetServiceResponse
{
    item_codes: IShoppingCartGetServiceResponseItems[]
    shipping: number
    tax: number
    subtotal: number
    total: number
    fees?: number
    order_token?: string
    cardLast4?: number
    cardholderName?: string
    order_type?: string
    orderRequest?: any
}

export interface IShoppingCartPaymentRequest extends IShoppingCartGetServiceResponse
{
    payment_token?: string
}

export const ShoppingCartGetService = (body: IShoppingCartGetService) => (new HttpClient()).post<IResponse<IShoppingCartGetServiceResponse>>(`checkout`, body)

export const ShoppingCartPaymentService = (body: IShoppingCartPaymentRequest) => (new HttpClient()).post<IResponse<any>>(`payment`, body)

export const StoreGetService = (type: string) => (new HttpClient()).get<IResponse<any[]>>(`store/${type}`)