import { createContext, useEffect } from "react"
import { IContextChildren } from "../interfaces/context-children.inteface"
import { useState } from "react"
import { IAccount } from "../services/account"
import { cacheDestroyTargets } from "../services/cache"
import { decodeJwt, getToken, identity } from "../helpers/token"
import { http } from "../helpers/client"
import { GeneralAccountSettingsGetService } from "../services/settings"
import { IResponse } from "../interfaces/response.interface"
import { empty } from "../helpers/util.helper"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { setAccount, setAccountState } from "../redux/account.slicer"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"


interface ISystemSettingsWizardStartUp
{
    exists: boolean
}

interface ISystemSettingsSystem
{
    hide_on_decentralize?: number | string | null
    ui_compact?: number | string | null
    ticker_active?: number | string | null

}

interface ISystemSettingsSubscription
{
    expired: boolean
    exists: boolean
}

interface ISystemSettings
{
    system: ISystemSettingsSystem
    communique: any[]
    wizard_startup: ISystemSettingsWizardStartUp
    subscription: ISystemSettingsSubscription
}

export interface IAccountContext
{
    account: any
    loggedIn: boolean
    isAdmin: boolean
    accountState: IComponentState<any>
    setAccountState: (state: IComponentState<any>) => void
    setAccount: (account: IAccount) => void
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
    systemSettings: ISystemSettings
    setSystemState: any
    systemState: IComponentState<any>
}

export const AccountContext = createContext<IAccountContext>({
    account: {},
    accountState: {
        loading: false, 
        ready: false
    },
    loggedIn: false,
    isAdmin: false,
    systemState: ComponentStateDto,
    setAccountState: () => {},
    setLoggedIn: () => {},
    setAccount: () => {},
    systemSettings: {
        system: {},
        communique: [],
        wizard_startup: {
            exists: false
        },
        subscription: {
            exists: false,
            expired: true
        }
    },
    setSystemState: () => {}
})

export const AccountProvider = ({ children }: IContextChildren) => {
    //const [ account, setAccount ] = useState<IAccount>({ID: null, valid: false})
    //const [ accountState, setAccountState ] = useState<IComponentState<any>>({ loading: false, ready: false })
    const [ systemState, setSystemState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ loggedIn, setLoggedIn ] = useState<boolean>(false)
    const [ systemSettings, setSystemSettings ] = useState<any>({})

    const token = identity()
    const [ isAdmin ] = useState<boolean>((!!token.admin_id && token.admin_id < 3) || (!!token.role && token.role  < 3))

    const dispatch = useDispatch();
    const $_account = useSelector((state: RootState) => state.account.account);
    const $_state = useSelector((state: RootState) => state.account.state);

    const $_setAccount = (account: IAccount) => dispatch(setAccount(account));
    const $_setAccountState = (state: IComponentState<any>) => dispatch(setAccountState(state));

    const accountState = $_state;
    const account = $_account;
    
    useEffect(() => {
        // See if this login is ready
        if(!accountState.ready) {
            // Fetch it so
            if(!accountState.loading) {
                $_setAccountState(ComponentStateLoadingDto);
                // Checks if user token is valid
                const jwt = decodeJwt(getToken())
                // If JWT expired or invalid, stop
                if(jwt.expired) {
                    // Disable login
                    setLoggedIn(false)
                    // Done
                    $_setAccountState(ComponentStateReadyDto)
                    // Remove all the targets
                    cacheDestroyTargets()
                } else {
                    http().get<IResponse<IAccount>>(`account/`).then(r => {
                        // Set raw account for immediate use
                        $_setAccount(r.data)
                        // Make it ready now
                        $_setAccountState(ComponentStateReadyDto)
                        setLoggedIn(true)
                    })
                    // HttpClient.init().get<IAccount>(`account/get`).then(r => {
                    //     // Set raw account for immediate use
                    //     setAccount(r)
                    //     // Make it ready now
                    //     setAccountState({loading: false, ready: true})
                    //     setLoggedIn(true)
                    // })
                }
            }
        } else {
            if(typeof account.isAdmin === "undefined" || !!account.isAdmin && account.isAdmin !== isAdmin) {
                $_setAccount(({...account, isAdmin: isAdmin}))
            }
        }
    }, [ loggedIn, $_account, $_state?.ready ]);

    const loadSettings = () => GeneralAccountSettingsGetService(account.ID || '').then((r: any) => {
        setSystemSettings(r.data)
        setSystemState(ComponentStateReadyDto)
    })

    useEffect(() => {
        if(!empty(account.ID) && accountState.ready && !systemState.ready) {
            loadSettings()
        }
    }, [account?.ID, accountState.ready, systemState.ready])

    return (
        <AccountContext.Provider value={{
            account,
            accountState,
            loggedIn,
            isAdmin,
            systemSettings,
            setAccount: $_setAccount,
            setAccountState: $_setAccountState,
            setLoggedIn,
            setSystemState,
            systemState,
        }}>
            { children }
        </AccountContext.Provider>
    )
}