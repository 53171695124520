import { ICalvingGroupForm } from "../services/herd-groups"
import { BaseDto } from "./base.dto"

export class CalvingGroupFormCalvingTagsDto extends BaseDto 
{
    brands: string | null = ''
    ear_notches: string | null = ''
    eid: string | null = ''
    metalplastic_clip: string | null = ''
    other_tag: string | null = ''
    ranch: string | null = ''
    /**
     *	@description	
     *	@param	
     */
     constructor(obj: any)
     {
         super()
         this.populate(obj, this)
     }
}

export class CalvingGroupFormTagsAppliedDto extends BaseDto 
{
    branding: number = 0
    calving: number = 0
    weaning: number = 0
    other: string = ''
    /**
     *	@description	
     *	@param	
     */
     constructor(obj: any)
     {
        super()
        this.populate(obj, this)
     }
}

export class CalvingGroupFormDto extends BaseDto implements ICalvingGroupForm {
    public ID: string | null = null
    public unique_id: string | null = null
    public group_name: string = ''
    public locations: string[] = ['']
    public tags_applied: CalvingGroupFormTagsAppliedDto = new CalvingGroupFormTagsAppliedDto({})
    public calving_dates: any = {
        start: new Date(),
        end: new Date()
    }
    public calving_tags: CalvingGroupFormCalvingTagsDto = new CalvingGroupFormCalvingTagsDto({})
    public id_description: string = ''
    public approx_calves: number = 1
    public cattle_left_origin: string = ''
    public map_id: string | null = null
    public status: number | null = null
    public herd_count: number | null = null
    public paid: number | null = null
    public payment_id: string | null = null
    public decentralized: number | null = null
    public certified_at: string | null = null

    /**
     *	@description	
     *	@param	
     */
    constructor(obj: any)
    {
        super()
        this.populate(obj, this)
    }
}

export class GroupListDto extends BaseDto
{
    public valid: boolean = false
    count: number = 0
    uid: string = '0'
    groups: any[] = []

    constructor(obj: any)
    {
        super()
        this.populate(obj, this)
    }
}

export interface IGroupsListDto
{
    valid: boolean
    count: number
    uid?: string
    groups?: any[]
}