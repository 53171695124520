import React, { useContext, useEffect, useState } from 'react';
import { ButtonsTableMiniActions, CreateNewGroupButtonComponent } from '../../components/ui/buttons';
import { NavLink, Params, useNavigate, useParams } from 'react-router-dom';
import { count, toDate } from '../../helpers/util.helper';
import { loop } from '../../helpers/arrayworks.helper';
import { DataGridRowsBlockComponent } from '../../components/ui/blocks/data-grid.component';
import { GridColDef, GridRowModesModel } from '@mui/x-data-grid';
import { UserToolsTagManagerCreateComponent } from '../../components/user-tools/tag-manager';
import { TagManagerDeleteTagService, TagManagerGetService, TagManagerPCTUpdateService } from '../../components/user-tools/tag-manager/tags.services';
import { Button, TextField, Typography } from '@mui/material';
import { TagManagerPageNoRowsComponent } from '../../components/user-tools/tag-manager/tags-no-rows';
import { AlertContext } from '../../providers/alert.provider';
import { ModalContext } from '../../providers/modal.provider';
import { CpvBlockHeader } from '../../components/ui/blocks';
import { highlighter } from '../../helpers/stringworks.helper';
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from '../../interfaces/component-state.interface';
import Template from '../../templates/cattleproof';
import LabelIcon from '@mui/icons-material/Label';
import ButtonEdit from '../../components/ui/buttons/button-edit';
import TagsList from '../../components/user-tools/tag-manager/tags-list';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ButtonView from '../../components/ui/buttons/button-view';
import ButtonBack from '../../components/ui/buttons/button-back';
import ButtonSearch from '../../components/ui/buttons/button-search';
import BlockLoaderTransparency from '../../components/ui/loader';

const TagManagerPage: React.FC = () => {
    const [ state, setState ] = useState<IComponentState>(ComponentStateDto);
    const [ tagGroups, setTagGroups ] = useState<any[]>([]);
    const [ rowModesModel ] = useState<GridRowModesModel>({});
    const [ curr, setCurr ] = useState<string>('');
    const [ lockingId, setLockingId ] = useState<string>('');
    const [ reload, setReload ] = useState<boolean>(false);
    const { setMsg, setType } = useContext<any>(AlertContext);
    const [ confirmLock, setConfirmLock ] = useState<boolean>(false);
    const { setModalActive, setModalBody, setModalTitle, setModalAttr} = useContext(ModalContext)
    const p: Readonly<Params<string>> = useParams();
    const nav = useNavigate();

    const initiateLocking = (id: string) => {
        setConfirmLock(true);
        setLockingId(id);
    };

    const tagsColumns: GridColDef[] = [
        { field: 'created_at', headerName: 'Date', minWidth: 120, },
        { field: 'description', headerName: 'Title', flex: 1, minWidth: 200 },
        { field: 'docs', headerName: 'Docs', minWidth: 60 },
        { field: 'tag_count', headerName: 'Qty', minWidth: 60 },
        { field: 'verified_at', headerName: 'Status', minWidth: 60, renderCell: (e: any) => {
            if(!e.value && e.row.submitted_at && !e.row?.denied_at) {
                return (<div className={ `badge bkg-orange` }>SUBMITTED</div>);
            } else if (e.row?.denied_at) {
                return (<div className={ `badge bkg-${ e.row.submitted_at ? 'orange' : 'red' }` }>DENIED</div>);
            }
            return (<div className={ `badge bkg-${ e.value ? 'green' : 'red' }` }>{ e.value ? '' : 'UN' }VERIFIED</div>);
        } },
        { field: 'actions', headerName: '', cellClassName: 'justify-content-end', renderCell: (e: { row: {ID: string, verified_at?: string | null, submitted_at?: string | null, tag_count: number, docs: number , denied_at?: string | null }}) => {
            const allowed: boolean = (e.row?.verified_at || e.row?.submitted_at || e.row.denied_at)? false : true;
            return (
            <div>
                <Button
                    className='no-appearance'
                    variant='text'
                    onClick={() => nav(`${e.row.ID}`)}
                >
                    { allowed? <ButtonEdit text=' ' /> : <ButtonView text=' ' /> }
                </Button>
                <button
                    className={ `no-appearance ${!allowed && 'cursor-default'}` }
                    onClick={() => null}
                    disabled={ (e.row?.verified_at || e.row?.submitted_at || e.row.tag_count === 0 || e.row.docs === 0)? true : false }
                >
                    { !(e.row?.verified_at || e.row?.submitted_at) && <LockOpenIcon className={ `fill-dark-grey` } fontSize="small" onClick={ () => 
                        initiateLocking(e.row.ID) } /> }
                    { (e.row?.verified_at || e.row?.submitted_at) && <LockIcon className={ `fill-dark-grey` } fontSize="small" /> }
                </button>
            </div> )} },
    ];
    const onClickEvent = () => nav('create');
    const pathItems: {[ key: string ]: any } = {...p};

    useEffect(() => {
        if(count(pathItems) > 0) {
            const v = loop(pathItems, (v: string) => v.split('/'), '').pop().filter((v: string) => v !== '');
            if(count(v) > 0) {
                setCurr(v.pop());
            } else {
                if(curr !== '') {
                    setCurr('');
                }
            }
        }
    }, [ p ])

    const tagMngrTitle = <><LabelIcon style={{transform: 'rotate(-65deg) translateX(0.15em)'}} />&nbsp;Tag Manager</>;

    const loadTagList = (func?: () => void, loadAll?: boolean) => {
        if(!state.loading) {
            setState(ComponentStateLoadingDto);
            TagManagerGetService(loadAll? 'all' : undefined).then(r => {
                if(r.success) {
                    setTagGroups(r.data);
                }
                setState(ComponentStateReadyDto);
                if(func)
                    func();
            });
        }
    }

    const submitLock = async (id: string) => {
        const r = await TagManagerPCTUpdateService({id, action: 'lock'});
        if(r.success) {
            setMsg('You have successfully locked the tag group.');
            setType(true);
            setLockingId('');
            loadTagList();
            setConfirmLock(false);
            setModalActive(false);
            setReload(true);
        } else {
            setMsg(r.error || 'An error occurred while locking the tag group.');
            setType(false);
        }
    }

    useEffect(() => {
        const isCreate: boolean = loop(pathItems, (v: string) => v.split('/'), '').pop().filter((v: string) => v !== '').includes('create');
        if(!isCreate) {
            if(!state.ready) {
                loadTagList();
            }
        }
    }, [ curr ])

    useEffect(() => {
        if(confirmLock) {
            setModalTitle('Lock Tag Group');
            setModalBody(
            <div className='col-count-1 gapped'>
                <div className="align-middle">
                    <Typography variant='h6'>Are you sure you want to lock this tag group?</Typography>
                </div>
                <div className="align-middle">
                    <div className="col-count-2 gapped col-c1-md">
                        <Button variant='outlined' onClick={ async () => {
                            await submitLock(lockingId);
                        } }>Yes, Lock</Button>
                        <Button variant='contained' onClick={ () => setModalActive(false) }>Cancel</Button>
                    </div>
                </div>
            </div>);
            setModalActive(true);
            setModalAttr({ closeButton: true, closeEvent: () => {
                setLockingId('');
                setConfirmLock(false);
            } });
            setConfirmLock(false);
        }
    }, [ confirmLock ])
    
    useEffect(() => {
      if(reload) {
        setReload(false);
      }
    }, [ reload ]);
    
    return (
        <Template
            title={ curr === ''? tagMngrTitle : 'Create Tags' }
            description={ ['','create'].includes(curr)? `Manage all your tags. Before you can import your animals, you are required to import tags here and provide evidence they are owned by you.` : '' }
            createButton={
                <CreateNewGroupButtonComponent
                    resetGroup={ () => null }
                    title={`Create Tags`}
                    onClickEvent={ onClickEvent }
                /> }
            backButtonLabel={ curr === 'create'? tagMngrTitle : undefined }
            backButtonEvent={ curr === 'create'? () => nav('') : undefined }
            loading={ lockingId !== '' }
        >
            { curr === '' &&
            <>
                <Button variant='contained' className='my-4' onClick={ () => nav('all') }><ButtonSearch /></Button>
                <DataGridRowsBlockComponent
                    processRowUpdate={ (e: any) => console.log(e) }
                    onRowsModelChange={ (e: any) => console.log(e) }
                    rowModesModel={ rowModesModel }
                    columns={ tagsColumns }
                    rows={ tagGroups }
                    label={ <TagManagerPageNoRowsComponent onClickEvent={ onClickEvent } /> }
                >
                </DataGridRowsBlockComponent>
            </>
            }

            { curr === 'create' && <UserToolsTagManagerCreateComponent curr={ curr } onSuccessfulEvent={ () => loadTagList() } /> }

            { !['','create','all'].includes(curr) && !reload && <TagsList lockGroup={ initiateLocking } id={ curr } loadTagList={ loadTagList } /> }

            { curr === 'all' && 
            <AdminPageManageAllTagsComponent reload={loadTagList} />
            }

        </Template>
    );
};

export default TagManagerPage;


export const AdminPageManageAllTagsComponent = ({ reload }: { reload: () => void}) => {
    const [ state, setState ] = useState<IComponentState>(ComponentStateDto);
    const [ filters, setFilters ] = useState<any>({ search: '' });
    // const [ dispositionAction, setDispositionAction ] = useState<{ dead: string[], tag: {[k: string]: string }}>({dead: [], tag: {}});
    // const [ dispositionGroup, setDispositionGroup ] = useState<string[]>([]);
    const { setMsg, setType } = useContext(AlertContext);
    ///const { setModalActive, setModalBody, setModalAttr } = useContext(ModalContext);
    const nav = useNavigate();

    const tagsColumns: GridColDef[] = [
        { field: 'pct', headerName: 'PCT', flex: 1, minWidth: 200, renderCell: (e: any) => {
            //const inDisp: boolean = dispositionGroup.includes(e.row.ID);
            return (
            <>
                {/* { inDisp && <CheckBoxIcon color='error' onClick={() => setDispositionGroup(dispositionGroup.filter(v => v !== e.row.ID))} /> }
                { !inDisp && <CheckBoxOutlineBlankIcon color='primary' onClick={() => setDispositionGroup([...dispositionGroup, e.row.ID])} /> } */}
                { highlighter(e.value, filters.search) }
            </>)
        } },
        { field: 'group_name', headerName: 'Group', flex: 1, renderCell: (e: any) => <NavLink to={ e.row.tid }>{ e.value }</NavLink> },
        { field: 'group_id', headerName: 'Group Id' },
        { field: 'replacement_id', headerName: 'Replacement Id', minWidth: 200 },
        { field: 'verified_at', headerName: 'Verified', minWidth: 60, renderCell: (e: any) => {
            return (<div className={ `badge bkg-${ e.value ? 'green' : 'red' }` }>{ e.value ? '' : 'UN' }VERIFIED</div>);
        } },
        { field: 'created_at', headerName: 'Date', minWidth: 120, renderCell: (e: any) => toDate(e.value, false) },
        { field: 'actions', headerName: '', cellClassName: 'justify-content-end', renderCell: (e: any) =>  
        <ButtonsTableMiniActions
            onDeleteEvent={ () => {
                if(!e.row.verified_at) {
                    TagManagerDeleteTagService({id: e.row.ID, action: 'tag', tid: e.row.tid}).then(r => {
                        setType(r.success? true : false);
                        setMsg(r.success? 'Tag deleted successfully.' : r.error || 'An error occurred while deleting the tag.');
                        reload();
                        loadAllTags(filters.search);
                    });
                }
            }}
            disableDelete={ e.row.verified_at? true : false }
            // onEditEvent={ !e.row.verified_at? undefined : () => {
            //     setModalActive(true);
            //     setModalBody(
            //         <TagManagerDispositionModalComponent saveDisposition={setDispositionAction} />
            //     );
            // }}
        >
        </ButtonsTableMiniActions> },
    ];
    const loadAllTags = (search?: string) => {
        setState(ComponentStateLoadingDto);
        TagManagerGetService('all', {search}).then(r => {
            if(r.success) {
                setState({...ComponentStateReadyDto, data: r.data});
            } else {
                setState(ComponentStateReadyDto);
                throw new Error(r.error || 'An error occurred while loading all tags.');
            }
        });
    }
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            loadAllTags(filters.search);
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [filters.search]);

    return (
        <>
        <Button variant='contained' className='my-4' onClick={ () => nav('') }><ButtonBack /></Button>
        <CpvBlockHeader
            title='All Tags'
            description='List of all tags in the system.'
        >
            <div className="d-flex justify-content-start gap">
                <TextField
                    label='Search'
                    value={ filters.search }
                    onChange={ (e) => setFilters({ ...filters, search: e.target.value }) }
                />
                {/* { dispositionGroup.length > 0 && (
                    
                )} */}
            </div>
            <BlockLoaderTransparency loading={ state.loading }>
                <DataGridRowsBlockComponent
                    processRowUpdate={ (e: any) => e }
                    onRowsModelChange={ (e: any) => e }
                    rowModesModel={ {} }
                    columns={ tagsColumns }
                    rows={ state.data }
                    label={ '' }
                    filter={ ['actions', 'non_pct', ] }
                >
                </DataGridRowsBlockComponent>
            </BlockLoaderTransparency>
        </CpvBlockHeader>
        </>
    )
}


// export const TagManagerDispositionModalComponent = ( { saveDisposition }: { saveDisposition: (data: { dead: string[], tag: {[k: string]: string }}) => void } ) => {
    
//     const [ dispositionSelect, setDispositionSelect ] = useState<string>('');
//     const [ dispositionId, setDispositionId ] = useState<string>('');

//     return (
//         <ModalContentGeneric
//             title="Tag Modifications"
//             description="What changes would you like to make to this tag?"
//         >
//             <Select
//                 className='mt-4'
//                 variant='outlined'
//                 onChange={ (e: { target: { value: string }}) => setDispositionSelect(e.target.value) }
//                 value={ dispositionSelect || 'Selected' }
//             >
//                 <MenuItem value="Selected" disabled >Select</MenuItem>
//                 <MenuItem value="replace">Replace Tag</MenuItem>
//                 <MenuItem value="delete">Remove Tag</MenuItem>
//             </Select>
//         </ModalContentGeneric>
//     )
// }