import { useRegionsHook } from "../../hooks/regions.hook"
import { loop } from "../../helpers/arrayworks.helper"
import { onChangeEventHelper } from "../../helpers/form"
import { ISelectOptions } from "../../interfaces/form-builder.interface"
import { BootStrapSpinner } from "../ui/views/spinner"
import { MenuItem, Select, SelectChangeEvent } from "@mui/material"

interface IFormStateComponent
{
    name?: string
    value: string
    label?: string
    setForm: any
}

export const FormStateComponent = ({ name, value, label, setForm }: IFormStateComponent) => {
    const { regions } = useRegionsHook()
    const opts: ISelectOptions[] = regions.ready? regions.data : [ {
        "label": "Select",
        "value": ""
    }]
    
    return (
        regions.ready? 
        <Select
            name={ name || 'state' }
            label={ label || "State" }
            required
            value={ value }
            onChange={(e: SelectChangeEvent<string>) => onChangeEventHelper(e, setForm)}
        >
            {
                loop(opts, (v: ISelectOptions, k: number) => (
                    <MenuItem key={k} value={ v.value as string }>{ v.label as string }</MenuItem>
                ))
            }
        </Select> : <BootStrapSpinner />
    )
}