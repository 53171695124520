import HttpClient from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"

export const CalvingGroupsGetService = (prefs: any) => {
    return (new HttpClient()).get<IResponse<any[]>>('customer/calving-groups', prefs)
}

export const CalvingGroupUpdateService = (body: any) => {
    return (new HttpClient()).patch<IResponse<any>>('customer/calving-groups', body)
}

export const CalvingGroupCreateService = (body: any) => {
    return (new HttpClient()).post<IResponse<any>>('customer/calving-groups', body)
}

export const CalvingGroupDeleteService = (body: any) => {
    return (new HttpClient()).delete<IResponse<any>>('customer/calving-groups', body)
}