import { useState } from "react"
import { WizardSignUpTestIntroduction } from "./components/introduction"
import { WizardSignUpTestStarted } from "./components/started"
import ViewMainWindow from "../../ui/views/main-window"
import './styles.scss'

export default function WizardSignUpTest()
{
    const [ startViewing, setStartViewing ] = useState(false)

    return (
        <ViewMainWindow
            expandable={true}
            logoSize="small"
        >
            <div className="testing-window mt-4">
                { startViewing?
                    <WizardSignUpTestStarted
                        startViewing={startViewing}
                        setStartViewing={setStartViewing}
                    />
                    :
                    <WizardSignUpTestIntroduction
                        startViewing={startViewing}
                        setStartViewing={setStartViewing}
                    /> }
            </div>
        </ViewMainWindow>
    )
}