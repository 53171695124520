import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotes } from "../services/notification";
import IComponentState, { ComponentStateDto } from "../interfaces/component-state.interface";

interface INotifications
{
    count: number,
    notes: INotes[]
}

export interface INotificationRedux
{
    summary: INotifications,
    state: IComponentState<any>
}

const initialState: INotificationRedux = {
    summary: {
        count: 0,
        notes: []
    },
    state: ComponentStateDto
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<INotifications>) => {
            state.summary = action.payload
        },
        setState: (state, action: PayloadAction<IComponentState<any>>) => {
            state.state = action.payload
        },
        clearNotification: () => initialState
    }
})

export const { setNotification, clearNotification, setState } = notificationSlice.actions;
export default notificationSlice.reducer;