import HttpClient from "../helpers/client";

export function AngusGetService(hid: string)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).get<any>(`angus/${hid}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function AngusSaveService(body: any)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).post<any>(`angus`, body).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function AngusUpdateService(body: any)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).patch<any>(`angus`, body).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function AngusDeleteService(hid: string)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).delete<any>(`angus/${hid}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}