import { ModuleComponent } from '../../components/module'
import Template from '../../templates/cattleproof'
import Login from '../../components/login'
import './styles.scss'
import TemplatesCattleProofCenterBlock from '../../templates/cattleproof/center-block'

export const GroupEditPage = ({ create }: { create?: boolean }) => {
  
  return (
    <Login>
        <Template
            title={<><i className="far fa-list-alt"></i>&nbsp;Group Editor</>}
            backButton="/"
            backButtonLabel="My Groups"
            description=''
            createButton={ create? <></> : undefined }
        >
          <TemplatesCattleProofCenterBlock>
              <ModuleComponent />
          </TemplatesCattleProofCenterBlock>
        </Template>
    </Login>
  )
}