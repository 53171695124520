import { useContext, useEffect, useState } from "react"
import { IModuleParent, isChecked, onCheckChangeEvent } from "../submodule.base"
import { ModuleComponentsRadioComponent } from "./radio.component"
import { count, equals } from "../../../helpers/util.helper"
import { ModuleComponentsSelectFieldComponent } from "./select-field.component"
import { ModuleComponentsTextFieldComponent } from "./text-field.component"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { ApplicationContext } from "../../../providers/application.provider"
import { StateType } from "../../../interfaces/form-builder.interface"

export const ModuleComponentsGeneticsComponent = ({ parentName }: IModuleParent) => {
    const [ ready, setReady ] = useState<boolean>()
    const [ form, setForm ] = useState<StateType<any>>({})
    const keyName = parentName? `${parentName}.` : ''
    const { currentApp, editable } = useContext(ApplicationContext)
    const answers = currentApp.flattened || {}

    const tags = [
        {
          "label": "EID TAG",
          "name": "tag.add_tag.eid",
          "value": "1"
        },
        {
          "label": "WRITE ON TAG",
          "name": "tag.add_tag.write_on",
          "value": "1"
        },
        {
          "label": "EAR NOTCH",
          "name": "tag.add_tag.ear_notch",
          "value": "1"
        },
        {
          "label": "TAG NOTCH",
          "name": "tag.add_tag.tag_notch",
          "value": "1"
        },
        {
          "label": "Other",
          "name": "tag.add_tag.other_co",
          "value": "1",
          "toggle": "genetics_tag_add_tag_other.text",
          "child": {
            "type": "text",
            "required": true,
            "toggle_target": "genetics_tag_add_tag_other.text",
            "label": "Other Description",
            "description": "",
            "name": "tag.add_tag.other",
            "placeholder": "Describe",
            "group": 3,
            "visible": false
          }
        }
      ]

    useEffect(() => {
      if(!ready) {
        if(answers) {
          const f: StateType<string | number | boolean> = {}
          for (const k in tags) {
            const anKey: string = `${keyName}${tags[k].name}`
            if(answers[anKey]) {
              f[anKey] = answers[anKey] === '1'
              if(tags[k].child && tags[k].child?.name) {
                const v: string = (tags[k].child && tags[k].child?.name) || ''
                f[`${keyName}${v}`] = answers[`${keyName}${v}`] || ''
              }
            }
          }

          if(answers[`${keyName}test_dna.currently`]) {
            f[`${keyName}test_dna.currently`] = answers[`${keyName}test_dna.currently`]
          }
          if(count(f) > 0) {
            setForm(f)
          }
        }
        setReady(true)
      }
    }, [answers])

    return (
        <div className="section py-3 col-count- gapped">
            <ModuleComponentsRadioComponent
                label="Do you currently DNA your animals?"
                name={`${keyName}test_dna.currently`}
                form={form}
                setForm={setForm}
                disabled={ !editable }
            />

            { equals(form[`${keyName}test_dna.currently`], '1')? 
                <div>
                    <ModuleComponentsSelectFieldComponent
                        name={`${keyName}test_dna.name`}
                        label={"DNA Test Name"}
                        required
                        defaultValue={ answers && answers[`${keyName}test_dna.name`] || "NeoGen" }
                        options={[
                            {
                              "label": "NeoGen",
                              "value": "NeoGen"
                            },
                            {
                              "label": "Zoetis",
                              "value": "Zoetis"
                            },
                            {
                              "label": "Other",
                              "value": "Other"
                            }
                          ]}
                        setSelectHook={setForm}
                    />
                    { equals(form[`${keyName}test_dna.name`], 'Other')?
                    <div className="my-3">
                        <ModuleComponentsTextFieldComponent
                            label="Other Description"
                            name={`${keyName}test_dna.name_other`}
                            defaultValue={ answers && answers[`${keyName}test_dna.name_other`] || '' }
                            className="standard"
                            placeholder="Company name"
                            disabled={ !editable }
                            required
                        />
                    </div>
                        : null
                    }
                    <Typography variant="h5" className="mt-4">Identifying Cattle</Typography>
                    <p>Describe how you identify calves returned as eligible for GVAB.</p>
                    { tags.map((v,k) => {
                        return (
                        <div key={`genetics-tags-${k}`}>
                            <FormControlLabel
                                onChange={(e) => onCheckChangeEvent(e, setForm) }
                                checked={ isChecked(form, `${keyName}${v.name}`) }
                                name={`${keyName}${v.name}`}
                                value='1'
                                control={<Checkbox />}
                                label={v.label}
                                disabled={ !editable }
                            />
                            { v.child && isChecked(form, `${keyName}${v.name}`)?
                                <ModuleComponentsTextFieldComponent
                                    label="Describe other method"
                                    name={`${keyName}${v.child.name}`}
                                    className="standard"
                                    placeholder="Other way of tagging to represent genetics status"
                                    required
                                    disabled={!editable}
                                    defaultValue={answers && answers[`${keyName}${v.child.name}`] || ''}
                                /> : null
                            }
                        </div>)
                    })}
                </div> : null
            }
        </div>
    )
}