import { GetStatesService } from "./locale.service";

export const AutoService = (service: string): Promise<any> | null => {
    switch (service) {
        case ('GetStatesService'):
            return GetStatesService('US')
        default:
            return null
            break
    }
}