import { useContext, useEffect, useState } from "react"
import { useLocationsHook } from "../../hooks/locations.hook"
import { UploadSingleMultiAnimalService } from "../../services/upload-csv"
import { AlertBase, AlertYinYang } from "../ui/views/alerts"
import { Button, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, TextareaAutosize, Typography } from "@mui/material"
import { IBreeds } from "../../services/breeds"
import { BootStrapSpinner } from "../ui/views/spinner"
import { usePreflightHook } from "../../hooks/herd.hook"
import { count, empty, scrollToElement } from "../../helpers/util.helper"
import { isArray } from "../../helpers/arrayworks.helper"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../interfaces/component-state.interface"
import ButtonBack from "../ui/buttons/button-back"
import { ModalContext } from "../../providers/modal.provider"
import AlertBox from "./alert"
import { ApplicationContext } from "../../providers/application.provider"

interface IUploadSingleMultiComponent
{
    gid: string
    toggleDone: boolean
    setToggleDone?: any
}

const defaultTypes = {
    pct: 'eid',
    nonPct: 'visual_tag'
}

const defaultForm = {
    breed: 'select',
    location: 'select',
    method: 'update', // Default selection when opening the upload
    form_mode: defaultTypes.pct
}

export const UploadSingleMultiComponent = ({ gid, setToggleDone }: IUploadSingleMultiComponent) => {
    const [ id ] = useState<string>(gid)
    const [ form, setForm ] = useState<any>(defaultForm)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ msg, setMsg ] = useState<any>({msg: '', type: false })
    const [ multiple, setMultiple ] = useState<number>(0)
    const [ fillInType, setFillInType ] = useState<boolean>(true)
    const [ primaryKey, setPrimaryKey ] = useState<string>(defaultTypes.pct)

    const { setModalActive } = useContext(ModalContext)
    const { breeds, breedState } = useContext(ApplicationContext);
    const { locationsState, locations, setGid } = useLocationsHook()
    const { setPfGid, preFlight } = usePreflightHook()

    const isCompliant = (val: string) => {
        const id = val.replace(/[^\d]/, '')
        if(id.length !== 15) {
            return false
        }
        return true
    }

    const onChangeValue = (e: any) => {
        const { value } = e.target
        const f: any = {...form}

        if(value.match(',')) {
            const v: string[] = value.split(',')
            setMultiple(v.filter((v: string) => v !== '').length)
            if(primaryKey === defaultTypes.pct) {
                v.map((id: string, k: number) => {
                    if(k !== v.length - 1) {
                        if(!isCompliant(id)) {
                            toMessage('This set of tags should have 15 digits. There are some that do not comply.', false)
                        }
                    }
                })
            }
        } else if(!empty(value)) {
            if(multiple === 0) {
                setMultiple(1)
                if(primaryKey === defaultTypes.pct) {
                    if(!isCompliant(value)) {
                        toMessage('This set of tags should have 15 digits. There are some that do not comply.', false)
                    } else {
                        if(msg.msg !== '') {
                            setMsg({type: false, msg: ''})
                        }
                    }
                }
            } else {
                if(primaryKey === defaultTypes.pct && multiple === 1) {
                    if(msg.msg !== '' && isCompliant(value)) {
                        setMsg({type: false, msg: ''})
                    }
                }
            }
        } else if(empty(value) && multiple > 0) {
            if(msg.msg != '') {
                setMsg({type: false, msg: ''})
            }
            setMultiple(0)
        }
        setForm({...f, tags: e.target.value })
    }

    useEffect(() => {
        setGid(id)
        setPfGid(id)
    }, [ id ])

    useEffect(() => {
        if(preFlight.ready && count(preFlight.data.counts.overview.certs.applied) === 0) {
            setPrimaryKey(defaultTypes.nonPct)
        }
    })

    const onChangeEvent = (e: any) => {
        const { name, value } = e.target
        if(value !== '' && name === 'tags') {
            const f = { eid: value }
            const valid = validEids(f)
            if(multiple !== valid.tags.length)
                setMultiple(valid.tags.length)
        } else {
            if(multiple)
                setMultiple(0)
        }
        setForm((arr: any) => ({ ...arr, [name]: value }))
    }

    const buildRangeEids = (f: any): number[] => {
        const numberList: number[] = [];
        const start: number = f.tag_start? parseInt(f.tag_start) : 0
        const end: number = f.tag_end? parseInt(f.tag_end) : 0

        if(start === 0) {
            return []
        }
        if (start > end) {
            notify("Start number must be less than or equal to end number", false)
            return []
        }
        for (let i = start; i <= end; i++) {
            if(primaryKey === defaultTypes.pct) {
                if(i.toString().length !== 15) {
                    notify('Invalid tag id. Number of characters does not equal 15 required for a PCT.', false)
                    return []
                }
            }
            numberList.push(i)
        }
        return numberList
    }

    const validEids = (f: any) => {
        const suspect: any = []
        const tags: any = []
        if(f.tags) {
            f.tags.split(',').map((r: string) => {
                const orig = r
                r = r.replace(/[^\d]/gi, '')
                if(r.length !== 15 || !r.match(/^[\d]{15}$/gi)) {
                    suspect.push(orig)
                } else {
                    if(tags.length === 0 || !tags.includes(r))
                        tags.push(r.replace(/[^\d]/gi, ''))
                }
            })
        }
        return {
            tags,
            suspect
        }
    }

    const toMessage = (msg: string, type: boolean) => {
        setMsg({msg, type})
    }

    const notify = (msg: string, success: boolean) => {
        toMessage(msg, success)
        scrollToElement('#manual-entry')
        setState(ComponentStateReadyDto)
    }

    const onSubmitEvent = (e: any) => {
        e.preventDefault()
        toMessage('', false)
        setState(ComponentStateLoadingDto)
        const f = {...form}
        let valid: { tags: number[], suspect: number[] } = { tags: [], suspect: [] }

        let tags: any[] = []

        if(f.tag_start && f.tag_end && fillInType) {
            if(primaryKey === defaultTypes.pct)
                valid.tags = buildRangeEids(f)
            else
                tags = buildRangeEids(f)
        }
        if(primaryKey === defaultTypes.pct) {
            if(valid.tags.length === 0) {
                valid = validEids(f)
            }
            f.tags = valid.tags
        } else {
            if(!fillInType) {
                if(f.tags.match(',')) {
                    tags = f.tags.split(',')
                }
            }
            if(tags.length > 0) {
                f.tags = tags
            }
        }

        if(!f.location || f.location === 'select') {
            notify('Please select a location.', false)
            return false
        }
        
        if(!f.breed || f.breed === 'select') {
            toMessage('Please select a breed.', false)
            scrollToElement('#manual-entry')
            setState(ComponentStateReadyDto)
            return false
        }
        
        if(f.tag_start) {
            if(!isValidNumberSpan(parseInt(f.tag_start), parseInt(f.tag_end))) {
                notify('Invalid start and end ID range.', false)
                return false
            }
        }

        if(f?.tags) {
            if(!isArray(f.tags)) {
                f['tags'] = [f.tags]
            }
        }
        if(f?.tags && f.tags.length === 0) {
            return
        } else {
            if(valid.suspect.length > 0) {
                setMsg({ msg: `One or more PCT values may be invalid: "${valid.suspect.join('", "')}"`, type: false })
                setState(ComponentStateReadyDto)
            } else {
                f.gid = id
                UploadSingleMultiAnimalService(f).then(r => {
                    if(r.success) {
                        setForm(defaultForm)
                        notify('You have successfully uploaded your animals', true)
                        setToggleDone(true)
                    } else {
                        toMessage(r.error || '', false);
                    }
                    setState(ComponentStateReadyDto)
                })
            }
        }
    }

    const changeAndClear = (clear: string[], type: boolean) => {
        const f:  any = {...form}
        clear.map(v => {
            if(f[v])
                delete f[v]
        })
        setForm(f)
        setFillInType(type)
    }

    const isValidNumberSpan = (start: number, end: number) => start < end

    const chooseTagType = (type: string) => {
        setForm({...defaultForm, form_mode: type})
        setPrimaryKey(type)
    }

    const isForcedNonPTC = (): boolean => preFlight.ready && count(preFlight.data.counts.overview.certs.applied) === 0

    useEffect(() => {
        if(preFlight.ready && isForcedNonPTC() && form.form_mode !== defaultTypes.nonPct) {
            setForm({...defaultForm, form_mode: defaultTypes.nonPct })
            setPrimaryKey(defaultTypes.nonPct)
        }
    }, [ preFlight.ready, isForcedNonPTC() ])

    return (
        preFlight.ready? 
        <div>
            <Typography variant="h5" className="mb-4" id="manual-entry">Manual Entry</Typography>
            <InputLabel><strong>Animal Tags</strong></InputLabel>
            <p className="mb-4">Add one or more animals that are of the same breed and  physical location. Enter the PCT tags of the animals in either consecutive order (Number Range) or non-consecutive order (Comma-Separated List).</p>
            { msg.msg !== ''? <AlertYinYang success={ msg.type? msg.msg : '' } error={ !msg.type? msg.msg : '' } /> : null }
            <form id="single-herd-upload" className={ state.loading? 'disabled' : '' } style={{ width: '100%' }} onSubmit={onSubmitEvent}>
                <InputLabel className="required mb-2">How do you want to identify your animals in the system?</InputLabel>
                <div className="d-flex justify-content-start gapped mb-4">
                    <Button variant={primaryKey === defaultTypes.pct? 'contained' : "outlined"} style={{boxShadow: 'none' }} onClick={() => chooseTagType(defaultTypes.pct)} disabled={count(preFlight.data.counts.overview.certs.applied) === 0}>PCT/PCT Id</Button>
                    <Button variant={primaryKey === defaultTypes.nonPct? 'contained' : "outlined"} style={{boxShadow: 'none' }} onClick={() => chooseTagType(defaultTypes.nonPct)} disabled={count(preFlight.data.counts.overview.certs.applied) > 0}>Other (Non-Compliant) Id</Button>
                </div>
                { primaryKey !== defaultTypes.pct? <AlertBase icon={<i className="fas fa-exclamation-triangle"></i>} type="warning" text="You may disqualify certifications by not using PCT or Program Compliant Tags (PCT)." /> : null }
                <hr className="standard my-4" />

                <div className="col-count-2 gapped col-c1-md">
                    <div className="span2 span1-md col-count-3 col-c2-lg col-c1-md gapped">
                        <Button variant={ !fillInType? "contained" : "outlined" } size="small" onClick={() => changeAndClear(['tag'], false)}><i className="fas fa-list"></i>&nbsp;Comma-separated List</Button>
                        <Button variant={ fillInType? "contained" : "outlined" } size="small" onClick={() => changeAndClear(['tag_start', 'tag_end'], true)}><i className="fas fa-exchange-alt"></i>&nbsp;Number Range</Button>
                    </div>
                    <div className="span2 span1-md">
                        { !fillInType? <SelfCertifiedPrimaryKeyComponent {...{onChangeValue, form, primaryKey} } /> : <TagRangeGeneratorComponent {...{ form, setForm, primaryKey }} /> }
                    </div>

                    <div className="span2 span1-md">
                        <hr className="standard" />
                    </div>

                    <div>
                        <InputLabel className="required mb-2">Select breed for your animal{multiple !== 1? 's' : ''}</InputLabel>
                            { breedState.ready? 
                            <Select value={form.breed} name="breed" onChange={onChangeEvent} required={true} fullWidth >
                                <MenuItem key={`breeds-`} value={'select'}>Select</MenuItem>
                                { breeds.allBreeds.map((breed: IBreeds, k: number) => {
                                    return (
                                        <MenuItem key={`breeds-${k}`} value={breed.value}>{ breed.label }</MenuItem>
                                    )
                                })
                            }
                        </Select> : <BootStrapSpinner /> }
                    </div>
                    
                    <div>
                        <InputLabel className="required mb-2">Select location for th{multiple !== 1? 'ese' : 'is'} tagged animal{multiple !== 1? 's' : ''}</InputLabel>
                        <Select value={form.location || locations[0] || ''} name="location" onChange={onChangeEvent} required={true} fullWidth >
                            <MenuItem key={`location-`} value={'select'}>Select</MenuItem>
                            {
                                locationsState.ready? locations.map((location: string, k: number) => {
                                    return (
                                        <MenuItem key={`location-${k}`} value={location}>{ location }</MenuItem>
                                    )
                                }) : <BootStrapSpinner />
                            }
                        </Select>
                    </div>
                </div>
                <div className="span2 span1-md align-middle mt-4">
                    <AlertBox
                        errorClass={ 'secondary' }
                    >
                        { (form.method || 'overwrite') === 'overwrite'? <><h5>Clear &amp; Replace</h5>{`This will clear out any existing animals from the group then import ${multiple !== 1? 'these' : 'this'} PCT${multiple !== 1? 's' : ''}.`}</> : '' }
                        { form.method === 'add'? <><h5>Add New Only</h5>{'This will not change any exist animals and only add new. If there are any other animals that match already in the group, those will be ignored.'}</> : '' }
                        { form.method === 'update'? <><h5>Add New, Update Existing</h5>{'This will add new animals and change any existing animals if any tags matches what\'s already in the system.'}</> : '' }
                    </AlertBox>
                    <RadioGroup
                        aria-labelledby="options-for-upload"
                        defaultValue="overwrite"
                        name="method"
                        value={form.method || defaultForm.method}
                        onChange={onChangeEvent}
                    >
                        <FormControlLabel value="overwrite" control={<Radio />} label="Clear and replace" />
                        <FormControlLabel value="add" control={<Radio />} label="Add new only" />
                        <FormControlLabel value="update" control={<Radio />} label="Add New, Update Existing" />
                    </RadioGroup>
                    {/* <p className="my-4">You are currently set to upload { multiple } animal{multiple !== 1? 's' : ''}.</p> */}
                    <div className="align-middle">
                        <div className="col-count-2 gapped col-c1-md">
                            <Button variant="outlined" className="mt-4 corp" disabled={state.loading} onClick={() => setModalActive(false)}><ButtonBack text="Cancel" /></Button>
                            <Button variant="contained" className="mt-4 corp" type="submit" disabled={state.loading}>Save</Button>
                        </div>
                    </div>
                </div>
            </form>
        </div> : <BootStrapSpinner />
    )
}

export interface ISelfCertifiedPrimaryKeyComponent
{
    form: any
    primaryKey?: any
    onChangeValue?: any
    setForm?: any
}

export const SelfCertifiedPrimaryKeyComponent = ({ form, primaryKey, onChangeValue }: ISelfCertifiedPrimaryKeyComponent) => {
    
    return (
        <div>
            <p><i className="far fa-question-circle"></i>&nbsp;Type {primaryKey === defaultTypes.pct? 'one or more non-consecutive PCT tag numbers' : 'one or more of your tags' } separated by a comma that are of the same breed and physical location. Then select the breed and physical location for this group. Finally click the Save button to add this set of animals to your herd group.</p>
            <TextareaAutosize
                className="standard"
                name='tags'
                value={ form.tags || '' }
                onChange={onChangeValue}
                required
            />
        </div>
    )
}

export const TagRangeGeneratorComponent = ({ setForm, form, primaryKey } : ISelfCertifiedPrimaryKeyComponent) => {

    const onChangeEvent = (e: any) => {
        const { name, value } = e.target
        setForm((arr: any) => ({...arr, [name]: value.substring(0,15) }))
    }

    const toPCT = (v: string) => primaryKey === defaultTypes.pct? v.replace(/[^\d]/gi, '').substring(0, 15) : v

    return (
        <div>
            { primaryKey !== defaultTypes.pct? <AlertBase type="primary" text="This will only generate sequential numeric tags so if there are letters in your tags, please upload a CSV file or paste using comma separations." /> : null }
            <p><i className="far fa-question-circle"></i>&nbsp;Enter a consecutive range of PCT EIDs that are grouped by breed and physical location. Place the starting PCT in the Start ID field and the last PCT in the End ID field and that will generate all the numbers for your consecutive PCT range. Then select the breed and physical location for this group. Finally click the Save button to add this set of animals to your herd group.</p>
            <div className="col-count- gapped eid-range col-c1-md">
                <div><TextField required fullWidth variant="outlined" value={ toPCT(form?.tag_start || '') } name="tag_start" placeholder="Start ID#" onChange={ onChangeEvent } /></div>
                <div className="align-middle">to</div>
                <div><TextField required fullWidth variant="outlined" value={ toPCT(form?.tag_end || '') } name="tag_end" placeholder="End ID#" onChange={ onChangeEvent } /></div>
            </div>
        </div>
    )
}