import { useContext, useEffect, useState } from "react"
import { AccountContext } from "../../../providers/account.provider"
import { BootStrapSpinner, CattleLogoSpinner } from "../../ui/views/spinner"
import { IContextChildren } from "../../../interfaces/context-children.inteface"
import { UnEnrolledCustomer } from "../../login"
import { Loader } from "../../ui/loader"
import PaymentAnnualFee from "./payment-annual-fee"
import WizardSignUpTest from "../../wizard/sign-up-test"
import IComponentState, { ComponentStateDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"

export const SubscriptionPromptComponent = ({ children }: IContextChildren) => {
    // Check to see if the customer has a valid subscription
    const [ subscriptionValid, setSubscriptionValid ] = useState(false)
    // Need to pay fee status
    const [ payFee, setPayFee ] = useState(false)
    // Need to run through start up wizard
    const [ startUp, setStartUp ] = useState(false)

    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)

    // Account Provider
    const { account, accountState, isAdmin, systemSettings, /* setSystemState,  */systemState } = useContext(AccountContext)


    useEffect(() => {
        if(!state.ready && accountState.ready && systemState.ready && ((subscriptionValid && systemSettings.wizard_startup.exists) || !subscriptionValid)) {
            setState(ComponentStateReadyDto)
        }
    }, [ state.ready,
        accountState.ready,
        systemState.ready,
        subscriptionValid,
        systemSettings?.wizard_startup?.exists
    ])

    // Show loader whilst waiting for the user state
    if(!accountState.ready) {
        return (
            <div className='align-middle full-height'>
                <BootStrapSpinner />
            </div>
        )
    }
    // Show if admin
    if(isAdmin) {
        return children
    }

    if(account.account_type === 'buyer')
        return children

    // Check if there is a subscription check already run
    if(account.cpv_enrolled === 1 && systemState.ready) {
        // If we are not starting up and we don't have the fee paid yet
        if(!startUp && !payFee) {
            // If there is a valid subscription
            if((systemSettings?.subscription?.exists && !systemSettings?.subscription?.expired) && !subscriptionValid) {
                setSubscriptionValid(true)
            } else {
                // If no valid subscription
                if(!systemSettings?.subscription?.exists && payFee === false)
                    setPayFee(true)
            }
            // If the wizard doesn't exist yet
            if(systemSettings?.wizard_startup?.exists) {
                setStartUp(true)
            }
        } else {
            // If there is no valid subscription
            if (!subscriptionValid) {
                return (
                    <div className='body full-height payment-container'>
                        { !payFee? <CattleLogoSpinner size="xlg" /> : <PaymentAnnualFee /> }
                    </div>
                )
            }
        }
        // If there is a valid subscription but the test not taken
        if(subscriptionValid && !systemSettings.wizard_startup.exists) {
            return (
                <WizardSignUpTest />
            )
        }
    }
    if(!state.ready) {
        return (<Loader />)
    }
    // Main router
    return ( (account.cpv_enrolled === 1)? children : <UnEnrolledCustomer>{ children }</UnEnrolledCustomer> )
}