import { createContext, useContext, useEffect, useState } from "react"
import { GroupListDto, IGroupsListDto } from "../dto/calving-group-form.dto"
import { IContextChildren } from "../interfaces/context-children.inteface"
import { AccountContext } from "./account.provider"
import { GroupsLeftOpen } from "../helpers/groups"
import { CattleFormSingleViewDto } from "../dto/animal-record"
import { isAdmin } from "../helpers/account"
import { CattleGroupDto } from "../dto/cattle-group"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import Groups, { Herd } from "../services/herd-groups"

interface IHerdContext
{
    groups: IGroupsListDto,
    setGroups: React.Dispatch<React.SetStateAction<IGroupsListDto>>
    groupsState: IHerdGroupsState
    setGroupsState: React.Dispatch<React.SetStateAction<IHerdGroupsState>>
    herdState: IComponentState<any>
    setHerdState: React.Dispatch<React.SetStateAction<IComponentState<any>>>
    createNew: boolean
    setCreateNew: React.Dispatch<React.SetStateAction<boolean>>
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>
    gid: string
    setGid: React.Dispatch<React.SetStateAction<string>>
    herd: CattleFormSingleViewDto[]
    setHerd: React.Dispatch<React.SetStateAction<CattleFormSingleViewDto[]>>
    gidLocked: boolean
    setGidLocked: React.Dispatch<React.SetStateAction<boolean>>
    herdLocked: boolean
    setHerdLocked: React.Dispatch<React.SetStateAction<boolean>>
    setRefreshHerd: React.Dispatch<React.SetStateAction<boolean>>
}

interface IHerdGroupsState extends IComponentState<any>
{
    completed: boolean
}

export const HerdGroupsContext = createContext<IHerdContext>({
    groups: {valid: false, count: 0},
    setGroups: () => {},
    groupsState: {ready: false, loading: false, completed: false},
    setGroupsState: () => {},
    createNew: false,
    setCreateNew: () => {},
    setRefresh: () => {},
    gid: '',
    setGid: () => {},
    herd: [],
    setHerd: () => {},
    gidLocked: false,
    setGidLocked: () => {},
    herdLocked: false,
    setHerdLocked: () => {},
    herdState: {ready: false, loading: false },
    setHerdState: () => {},
    setRefreshHerd: () => {}
})

export const HerdGroupsProvider = ({children}: IContextChildren) =>
{
    const [ herd, setHerd ] = useState<CattleFormSingleViewDto[]>([])
    const [ gid, setGid ] = useState<string>('')
    const [ groups, setGroups ] = useState<IGroupsListDto>({valid: false, count: 0})
    const [ refresh, setRefresh ] = useState(false)
    const [ refreshHerd, setRefreshHerd ] = useState<boolean>(false)
    const [ createNew, setCreateNew ] = useState(false)
    const [ groupsState, setGroupsState ] = useState<IHerdGroupsState>({ready: false, loading: false, completed: false})
    const [ herdState, setHerdState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ gidLocked, setGidLocked ] = useState(false)
    const [ herdLocked, setHerdLocked ] = useState(false)
    const { account } = useContext(AccountContext)

    const toState = (ready: boolean = false, loading: boolean = false, completed: boolean = false) => setGroupsState({ready, loading, completed})

    const loadHerd = () => {
        if(!herdState.ready && gid !== '' ) {
            if(!herdState.loading) {
                // Set waiting
                setHerdState(ComponentStateLoadingDto)
                setRefreshHerd(false)
                // If the group id is valid
                if(!['0', 0, null,'',undefined].includes(gid)) {
                    // Load the herd from the id
                    Herd(gid?.toString() || '').then(r => {
                        // set the rows
                        if(r.success) {
                            // Set the rows
                            setHerd(r.herd.map((v) => new CattleFormSingleViewDto(v)))
                            setHerdState(ComponentStateReadyDto)
                        }
                    })
                } else {
                    // Reset the herd to no rows
                    setHerd([])
                    setGidLocked(false)
                    setHerdState(ComponentStateReadyDto)
                }
            }
        }
    }

    // Check if this selected group is allowed
    const gridIsEditableCheck = (): boolean => {
        let truethy = false
        if(isAdmin())
            return true
        groups.groups?.map((v) => {
            const row = new CattleGroupDto(v)

            if((row.status === 0 || row.status === 3) &&  (gid === row.ID)) { 
                truethy = true
            }
            return null
        })
        return truethy
    }


    useEffect(() => {
        if(!groupsState.ready && account.ID) {
            if(!groupsState.loading) {
                toState(false, true, false)
                Groups(account).then(r => {   
                    const response = new GroupListDto(r)
                    toState(true, false, GroupsLeftOpen(response.groups || []))
                    setGroups(response)
                })
            }
        }
    }, [groupsState.ready, account.ID])
    
    useEffect(() => {
        if(refresh) {
            toState(false, false, false)
        }

        if(refreshHerd) {
            setHerdState(ComponentStateDto)
            if(!herdState.ready) {
                setRefreshHerd(false)
                loadHerd()
            }
        }
    }, [ refresh, refreshHerd ])

    useEffect(() => {
        if(gid !== '') {
            loadHerd()
        }
        setGidLocked(!gridIsEditableCheck())
    }, [ gid, herdState.ready ])

    return (
        <HerdGroupsContext.Provider value={{
            groups,
            setGroups,
            groupsState,
            setGroupsState,
            createNew,
            setCreateNew,
            setRefresh,
            gid,
            setGid,
            herd,
            setHerd,
            herdState,
            setHerdState,
            gidLocked,
            setGidLocked,
            setRefreshHerd,
            herdLocked,
            setHerdLocked
        }}>
            { children }
        </HerdGroupsContext.Provider>
    )
}