import { useState } from "react"
import { Button, Checkbox, FormControlLabel } from "@mui/material"
import { WizardTestQuestions } from "../components/questions"
import ButtonBack from "../../../ui/buttons/button-back"
import manualThumb from '../../../../media/images/cpv_manual_thumb.jpg'
import manualPdf from '../../../../media/images/manuals/producer_manual.pdf'
import * as WI from "../../../../interfaces/wizard.interface"

/**
 * @description Watch the video when toggled
 */
export const WizardSignUpTestStarted = (props: WI.IWizardStartUpTestVideo) => {
    const [ startTest, setStartTest ] = useState(false)
    const [ haveReadManual, setHaveReadManual ] = useState(false)

    return (
        <div className="test-active">
            {!startTest ?
                <div>
                    <div className="col-count-2 col-c1-md gapped mb-4" style={{gridTemplate: '1fr/1fr auto'}}>
                        <div className="align-middle">
                            <p>Click the thumbnail below to view the CattleProof Verified Producer Training Manual. When you have read the manual, continue on to take the test which is required by the USDA to ensure you are aware of rules and regulations surrounding certifications.</p>
                        </div>
                        <div className="align-middle">
                            <div>
                                <a href={manualPdf} target="_blank" rel="noreferrer" className="nfx hover-zoom pointer contact-shadow-element" style={{overflow: 'visible'}}><img style={{maxWidth: 200, height: 'auto'}} src={ manualThumb } alt="CattleProof Manual" className="manual-thumb" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 align-middle">
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={haveReadManual}
                                        onChange={() => setHaveReadManual(!haveReadManual)}
                                    />
                                }
                                label="I have read the manual"
                            />
                        </div>
                        <div className="col-count-2 gapped col-c1-sm">
                            <Button variant="outlined" onClick={() => { !startTest ? props.setStartViewing(false) : setStartTest(false) }}><ButtonBack /></Button>
                            <Button variant="contained" disabled={!haveReadManual} onClick={() => setStartTest(true)}>Start Test</Button>
                        </div>
                    </div>
                </div>
                : <WizardTestQuestions setStartTest={setStartTest} />
            }
        </div>
    )
}