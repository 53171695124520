import { Button } from "@mui/material";
import { useContext } from "react";
import { ModalContext } from "../../../../providers/modal.provider";
import { ICattleGroupDto } from "../../../../dto/cattle-group";
import { GroupStatusPending } from "../../../../enums/group.status.enum";
import AdminValidation from "../../../user-tools/admin-validation";
import __ from "../../../../services/translator";

interface IIconAdmin
{
    className?: string
    text?: string | JSX.Element
}

interface IButtonAdmin extends IIconAdmin
{
    onClickEvent: any
    variant?: any
}
export const IconAdmin = ({ className, text }: IIconAdmin) => (<span className="strong">
<i className={`${className? className : ''} fas fa-cogs`}></i> { (typeof text === 'string')? text : __('Admin Actions', 'admin_actions') }
</span>)

export default function ButtonAdmin({ className, text, onClickEvent, variant }: IButtonAdmin)
{
    return (
        <Button className='no-appearance' onClick={onClickEvent} variant={variant}>
            <IconAdmin {...{className, text }} />
        </Button>
    )
}

interface IAdminCertifyOpenModalButton
{
    group?: any
}

export const AdminCertifyOpenModalButton = ({ group }: IAdminCertifyOpenModalButton) => {
    const { setModalActive, setModalAttr, setModalTitle, setModalBody, setModalEvent } = useContext(ModalContext)
    const gid = group?.ID || 0
    // Get group from gid
    const getGroup = (): ICattleGroupDto => {
        // let group: ICattleGroupDto = {}
        // groups.groups?.map((v: any) => {
        //     if(gid === v.ID)
        //         group = v
        //     return null
        // })
        return group
    }

    const validationModal = () => {
        setModalActive(true)
        setModalAttr({width: 'w-md'})
        setModalTitle(__('Admin Validation', 'admin_validation'))
        setModalBody(
        <AdminValidation
            onCancel={() => {
                setModalActive(false)
            }}
            gid={ gid }
            group={ getGroup() }
        />)
        if(setModalEvent)
            setModalEvent(() => () => {
                setModalActive(false)
            })
    }

    return (
        <GroupStatusPending status={ getGroup()?.status || 0 }>
            <ButtonAdmin onClickEvent={validationModal} className="bold" variant='outlined' />
        </GroupStatusPending>
    )
}