import { ReactNode } from "react"
import { IContextChildren } from "../../interfaces/context-children.inteface"

interface IPermissionsDataGridRowsComponent extends IContextChildren
{
    rows: any[]
    label?: JSX.Element | ReactNode | string
}

const PermissionsDataGridRowsComponent = ({ children, rows, label }: IPermissionsDataGridRowsComponent) => {
    return (
        rows.length > 0? children : label//<CreateButtonBlock label={ label } />
    )
}

export default PermissionsDataGridRowsComponent