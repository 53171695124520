import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { signOut } from '../../../logout'
import { FarFaBell } from '../../fontawesome'
import { IModalRequest } from '../../../../interfaces/modal-request.interface'
import { isAdmin } from '../../../../helpers/account'
import { Tooltip } from '@mui/material'
import { switchMode } from '../../buttons/button-nightshift'
import { cacheGet } from '../../../../services/cache'
import { AccountContext } from '../../../../providers/account.provider'
import NavMainCustomer from './customer'
import ImageCompanyLogo from '../../image-company-logo'
import __ from '../../../../services/translator'
import './customer/styles.scss'
import './styles.scss'

export const NavMain = (props?: IModalRequest) =>
{
    const [ menuStatus, setMenuStatus ] = useState('')

    const matchStyle = (name: string) => menuStatus === name? {display: 'flex'} : {}
    const clickMenu = (name: string) => setMenuStatus(menuStatus === name? '' : name)
    const [ notificationCount, setNotificationCount ] = useState(0)
    const [ admin ] = useState(isAdmin())
    const { account } = useContext(AccountContext)

    const propsValid = typeof props !== "undefined"
    // Toggle dark mode
    useEffect(() => {
        switchMode((cacheGet('interface.nightshift') === '1')? '1' : '0')
    })
    
    return (
        <div className={((admin)? 'admin-view' : (account?.account_type === 'buyer'? 'buyer-view' : '')) + ' main-nav desktop'}>
            <div className='left-block'>
                <NavLink to={admin? '#' : '/'}><ImageCompanyLogo /></NavLink>
            </div>
            <div className='align-middle'></div>
            <div className='right-block'>
                <div>
                    <ul className='main-submenu'>
                        <li className='submenu-wrapper'>
                            <div className='dropdown-wrapper' onClick={ () => clickMenu('account') }><Tooltip title={ account.email } arrow><i className="fas fa-info-circle mr-4 fa-sm"></i></Tooltip>Hello { account.first_name }&nbsp;{ <FarFaBell toggled={notificationCount > 0} styling={ notificationCount > 0? 'active' : 'inactive' } /> }
                            { notificationCount > 0? <span className='notification-cnt'>{notificationCount}</span> : '' }
                                <div className='submenu' style={ matchStyle('account') }>
                                    <NavMainCustomer
                                        setNotificationCount={setNotificationCount}
                                        eventController={ (propsValid && typeof props.eventController !== "undefined")? props.eventController : null }
                                        modalActiveController={ propsValid && typeof props.modalActiveController !== "undefined"? props.modalActiveController : null }
                                        admin={admin}
                                    />
                                    { props?.loggedIn?
                                        <div>
                                            <NavLink to="#sign-out" onClick={()=> signOut }><i className="fas fa-sign-out-alt"></i>&nbsp;{ __('Sign Out', 'sign_out') }</NavLink>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}