import { GridColDef } from "@mui/x-data-grid"
import { empty, isObject } from "./util.helper"

type ParsedObject = { [key: string]: any }

function parseStringToObject(input: string, value: any): ParsedObject {
  const result: ParsedObject = {}
  const keys = input.split('.')

  let currentObj = result
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i]
    if (!currentObj[key]) {
      currentObj[key] = {}
    }
    currentObj = currentObj[key]
  }

  currentObj[keys[keys.length - 1]] = value

  return result
}

function mergeObjects(target: ParsedObject, source: ParsedObject): ParsedObject {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === 'object') {
        if (!target[key]) {
          target[key] = source[key]
        } else {
          mergeObjects(target[key], source[key])
        }
      } else {
        target[key] = source[key]
      }
    }
  }
  return target
}

export const arrayWorksRecurseStringsToObjectBuilder = (existingObject: ParsedObject | undefined, entries: [string, any][]): ParsedObject => {
  const result = existingObject ? { ...existingObject } : {}

  entries.forEach(([input, value]) => {
    const parsedObject = parseStringToObject(input, value)
    mergeObjects(result, parsedObject)
  })

  return result
}

function recursiveMerge(target: Record<string, any>, source: Record<string, any>): void {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (!Object.prototype.hasOwnProperty.call(target, key)) {
          target[key] = Array.isArray(source[key]) ? [] : {}
        }
        if (Array.isArray(target[key])) {
          // If the target is already an array, don't merge into it
          target[key] = source[key];
        } else {
          recursiveMerge(target[key], source[key]);
        }
      } else if (!isNaN(Number(key))) {
        // Convert numeric keys to an array
        if (!Object.prototype.hasOwnProperty.call(target, key)) {
          target[key] = []
        }
        target[key].push(source[key])
      } else {
        target[key] = source[key]
      }
    }
  }
}

export function getFirstKey(obj: object) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return key;
    }
  }
  return undefined; // Object is empty
}

export function isNumeric(value: any) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

function convertNumericKeysToArray(obj: Record<string, any>): any {
  if(typeof obj === "object") {
    if(isNumeric(getFirstKey(obj))) {
      const newObj: any[] = Object.keys(obj).map(v => {
        return convertNumericKeysToArray(obj[v])
      })
      obj = newObj
    } else {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = convertNumericKeysToArray(obj[key])
        }
      }
    }
  }
  return obj
}

export const flattenObj = (originalObject: Record<string, any>) => {
  const mergedObject: Record<string, any> = {}
  for (const key in originalObject) {
    if (Object.prototype.hasOwnProperty.call(originalObject, key)) {
      recursiveMerge(mergedObject, originalObject[key])
    }
  }
  return convertNumericKeysToArray(mergedObject)
}


export const convertTildeKeyObjectsToArray = (inputObj: any): any => {
  if(typeof inputObj === "object") {
    Object.keys(inputObj).map((v) => {
      if(typeof inputObj[v] === "object") {
        const dumpArr: any[] = []
        Object.keys(inputObj[v]).map((val) => {
          if(val.match('~')) {
            dumpArr.push(convertTildeKeyObjectsToArray(inputObj[v][val]))
          }
        })
        if(dumpArr.length > 0) {
          inputObj[v] = dumpArr
        } else {
          inputObj[v] = convertTildeKeyObjectsToArray(inputObj[v])
        }
      }
    })
  }
  return inputObj
}

export const isCallable = (obj: any): boolean => typeof obj === "function"

export const isArray = (obj: any): boolean => Array.isArray(obj)

export function loop<T>(obj: any, callable: any, def?: any) {
    if(empty(obj))
        return typeof def !== "undefined"? def : obj
    if(isArray(obj)) {
        return obj.map((v: T, k: number) => callable(v, k))
    } else if(typeof obj === "object") {
        return Object.keys(obj).map((v: string, k: number) => {
            const val: T = obj[v]
            return callable(val, v, k)
        })
    }
}

export function toArray<T>(obj: any): T[]
{
    if(isArray(obj))
        return obj
    const f: T[] = []
    if(typeof obj === "object") {
        loop(obj, (v: T) => f.push(v))
    } else {
        return [obj]
    }
    return f
}

export const arrayKeys = (arr: any, func?: any): any[] => {
  let array: any[] = []
  if(isArray(arr)) {
    array = arr
  } else if(isObject(arr)) {
    Object.keys(arr).map(v => array.push(func? func(v) : v))
  } else {
    array.push(func? func(arr) : arr)
  }
  return array
}

export const arrayValues = (arr: any, func?: any): any[] => {
  let array: any[] = []
  if(isArray(arr)) {
    array = arr
  } else if(isObject(arr)) {
    Object.keys(arr).map(v => array.push(func? func(arr[v]) : arr[v]))
  } else {
    array.push(func? func(arr) : arr)
  }
  return array
}

export const inArray = (val: any, arr: any): boolean => {
  let inArr = false
  if(isArray(arr))
    return arr.includes(val)

  if(isObject(arr)) {
    loop(arr, (v: any) => {
      if(val === v)
        inArr = true
    })
  }

  return inArr
}

export function filterMuiColumns<T>(columns: GridColDef[], rows: T[], ignore?: string[])
{
  const nonEmptyFields = new Set();

  rows.forEach((item: any) => {
      Object.keys(item).forEach(key => {
          if (item[key] !== null && item[key] !== undefined && item[key] !== '') {
              nonEmptyFields.add(key);
          }
      });
  });

  return columns.filter(column => nonEmptyFields.has(column.field) || (ignore && ignore.includes(column.field)));
}