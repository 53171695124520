import HttpClient from "../helpers/client"

export type IValidationRequest = {
    gid: string
}

export function Validation(params: IValidationRequest)
{
    return new Promise<any>((success, fail) => {
        try {
            (new HttpClient()).get<any>(`admin/validation`, params).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function ValidationSaveService(params: IValidationRequest)
{
    return new Promise<any>((success, fail) => {
        try {
            (new HttpClient()).post<any>(`admin/validation`, params).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}