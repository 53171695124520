import { useEffect, useState } from "react";
import { UserToolsTagManagerCreateService, UserToolsTagManagerGetService, UserToolsTagManagerUpdateService } from "./tags.services";
import { Button, TextField, Typography } from "@mui/material";
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface";
import { IAnyType } from "../../../interfaces/form-builder.interface";
import { UploadAnyFileComponent } from "../../upload";
import { count } from "../../../helpers/util.helper";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

interface IUserToolsTagManagerUploaderBlock
{
    handleChange?: (e: any) => void;
    setResetForm?: (e: boolean) => void;
    useTabs?: boolean;
    clearForm?: () => void;
    resetForm?: boolean,
    csvEnabled?: boolean;
}

interface IUserToolsTagManagerUploader extends IUserToolsTagManagerUploaderBlock
{
    gid?: string;
    useForm?: boolean;
}

const UserToolsTagManagerUploader = ({ gid, useForm = false }: IUserToolsTagManagerUploader) => {
    const [ form, setForm ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setForm({...form, data: {...form.data, [name]: value}})
    }

    const onSubmitEvent = (e: any) => {
        e.preventDefault()
        const f: any = {...form?.data}
        if(gid)
            f.gid = gid
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            const action = f?.ID? UserToolsTagManagerUpdateService(f) : UserToolsTagManagerCreateService(f)
            action.then(r => {
                if(r?.success) {
                    setState({...ComponentStateReadyDto, data: r.data})
                } else {
                    setState(ComponentStateReadyDto)
                }
            })
        }
    }

    useEffect(() => {
        if(!form?.ready && useForm) {
            if(!form?.loading) {
                setForm(ComponentStateLoadingDto)
                UserToolsTagManagerGetService().then(r => {
                    if(r?.success) {
                        setForm({...ComponentStateReadyDto, data: r.data})
                    } else {
                        setForm(ComponentStateReadyDto)
                    }
                })
            }
        }
    }, [ form?.ready, useForm ]);

    return (
        useForm?
        <form onSubmit={onSubmitEvent}>
            <UserToolsTagManagerUploaderBlock handleChange={handleChange} />
            <div className="align-middle mt-4">
                <Button variant="contained" color="primary" type="submit">Submit</Button>
            </div>
        </form> : <UserToolsTagManagerUploaderBlock handleChange={handleChange} />
    );
}

export default UserToolsTagManagerUploader;


export const UserToolsTagManagerUploaderBlock = ({ useTabs, clearForm, resetForm, setResetForm, csvEnabled = true }: IUserToolsTagManagerUploaderBlock) => {
    const [ toggle, setToggle ] = useState<boolean>(false)
    const [ currToggle, setCurrToggle ] = useState<'range' | 'comma' | 'file'>('range')
    const [ form, setForm ] = useState<IAnyType<any>>({})
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }
    const onClickEvent = (e: any) => {
        const { type } = e.target.dataset
        if(clearForm)
            clearForm()
        setCurrToggle(type || 'range')
    }

    const commaList = () => <TextField
        name="tag_list"
        rows={3}
        multiline
        fullWidth
        onChange={handleChange}
        placeholder="Example: 810000000000001,810000000000002,...etc"
        required
        value={ form?.tag_list || '' }
    />

    useEffect(() => {
        if(resetForm) {
            setForm({})
            if(setResetForm)
                setResetForm(false)
        }
    }, [resetForm])
    

    return (
        !useTabs? <>
        <div className="align-middle">
            <div className="col-count-2 gapped mb-4">
                <Button variant={ !toggle? "contained" : "outlined" } type="button" onClick={() => setToggle(false)}>Add Using Range</Button>
                <Button variant={ toggle? "contained" : "outlined" } color="primary" type="button" onClick={() => setToggle(true)}>Add Using Commas</Button>
            </div>
        </div>
        { toggle && (
            <div>
                { commaList() }
            </div>
        )}
        { !toggle && (<div className="align-middle"><div className="col-count-2 gapped">
            <TextField label="Start" name="tag_start" onChange={(e: any) => handleChange? (useTabs? handleChange({target: {name: 'tag_start', value: e.target.value.replace(/[^\d]/gi, '')}}) : handleChange(e)) : null } placeholder="Example: 810000000000001" required />
            <TextField label="End" name="tag_end" onChange={(e: any) => handleChange? (useTabs? handleChange({target: {name: 'tag_emd', value: e.target.value.replace(/[^\d]/gi, '')}}) : handleChange(e)) : null } placeholder="Example: 810000000000020" required />
            </div></div>) }
        </> :
        <>
            <div className="tab-container">
                <ul className="tabs tab-menu">
                    <li className={`tab-menu-item ${currToggle === 'range'? 'active' : '' }`}><Button data-type="range" onClick={onClickEvent}>Tag Range</Button></li>
                    <li className={`tab-menu-item ${currToggle === 'comma'? 'active' : '' }`}><Button data-type="comma" onClick={onClickEvent}>Comma Delimited</Button></li>
                    { csvEnabled && <li className={`tab-menu-item ${currToggle === 'file'? 'active' : '' }`}><Button data-type="file" onClick={onClickEvent}>Upload CSV</Button></li> }
                </ul>
                <ul className="tab-content">
                    { currToggle === 'range' && (
                    <li className={`tab-content-item ${ currToggle === 'range'? 'active' : '' }`}>
                        <Typography variant="body1" className="mb-4"><ReportGmailerrorredIcon color="error" />&nbsp;Add tags using a start and end value. The system will generate the rest of the tags in between the values.</Typography>
                        <RangeListComponent resetForm={ resetForm || false } />
                    </li>)}
                    { currToggle === 'comma' && (
                    <li className={`tab-content-item ${ currToggle === 'comma'? 'active' : '' }`}>
                        <Typography variant="body1" className="mb-4"><ReportGmailerrorredIcon color="error" />&nbsp;Add tags using commas to separate each one.</Typography>
                        { commaList() }
                    </li>)}
                    { currToggle === 'file' && csvEnabled && (
                        <li className={`tab-content-item ${ currToggle === 'file'? 'active' : '' }`}>
                            <Typography variant="body1" className="mb-4"><ReportGmailerrorredIcon color="error" />&nbsp;Upload a CSV document with the <span className="txt-red" style={{fontWeight: 'inherit'}}>PCT Numbers in the column one</span>.</Typography>
                            <UploadAnyFileComponent
                                label={ "Upload CSV" }
                                id="csv-tag-file"
                                fileTypes={ ['.csv'] }
                                ready
                                required
                            />
                    </li>)}
                </ul>
            </div>
        </>
    )
}

const RangeListComponent = ({ resetForm }: { resetForm: boolean }) => {
    const [ fields, setFields ] = useState<IAnyType>({})

    useEffect(() => {
        if(resetForm && count(fields) > 0) {
            setFields({})
        }
    }, [ resetForm ])

    const onChangeEvent = (e: any) => {
        const { name, value } = e.target
        setFields({ ...fields, [name]: value.replace(/[^\d]/gi, '').substring(0, 15) })
    }

    if(fields?.tag_start && fields?.tag_end) {
        const start = parseInt(fields.tag_start)
        const end = parseInt(fields.tag_end)
        if(start > end && fields.tag_start.length === 15 && fields.tag_end.length === 15) {
            const temp = fields.tag_start
            setFields({ ...fields, tag_start: fields.tag_end, tag_end: temp })
        }
    }

    return (
        <div className="col-count-2 gapped col-c1-md">
            <TextField
                label="Start"
                name="tag_start"
                onChange={ onChangeEvent }
                value={ fields?.tag_start || '' }
                placeholder="Example: 810000000000001"
                required
            />
            <TextField
                label="End"
                name="tag_end"
                onChange={ onChangeEvent }
                value={ fields?.tag_end || '' }
                placeholder="Example: 810000000000020"
                required
                className="start1"
            />
        </div>
    )
}