import { Input } from "@mui/material"

interface IModuleComponentsActionsComponent
{
    appid: string | undefined
    uid: string | undefined
}

export const ModuleComponentsActionsComponent = (
    { appid, uid }: IModuleComponentsActionsComponent
) => {
    return (
        appid && appid !== ''? 
        <span>
            <Input type="hidden" name="appid" value={appid} />
            <Input type="hidden" name="uid" value={uid} />
        </span>
         : null
    )
}