import HttpClient from "../helpers/client";

export interface IPdfCertificateResponse
{
    ID: string
    group_name: string
    certified_at: string
    animals: number
}

interface IResponse
{
    data: IPdfCertificateResponse[]
    success: boolean
    error?: string
}

export function PdfCertificateService(gid: string): Promise<IResponse>
{
    return (new HttpClient()).get<IResponse>(`generate/pdf/${gid}`)
}