import React, { useEffect } from 'react'

const CattleTradingViewWidget: React.FC = () => {

  useEffect(() => {
      const id: string = 'tradingview-widget-script';
      const exists = document.getElementById(id);
      if(!exists) {

        const script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;
        script.innerHTML = `
        {
          "symbols": [
          {
          "description": "Soybean Futures",
          "proName": "CBOT:ZS1!"
          },
          {
          "description": "Wheat Futures",
          "proName": "CBOT:ZW1!"
          },
          {
          "description": "Lean Hog Futures",
          "proName": "CME:HE1!"
          },
          {
          "description": "Live Cattle Futures",
          "proName": "CME:LE1!"
          },
          {
          "description": "Feeder Cattle Futures",
          "proName": "CME:GF1!"
          },
          {
          "description": "Oats Futures",
          "proName": "CBOT:ZO1!"
          },
          {
          "description": "KC HRW Wheat Futures",
          "proName": "CBOT:KE1!"
          },
          {
          "description": "Corn Futures",
          "proName": "CBOT:ZC1!"
          }
          ],
          "showSymbolLogo": true,
          "isTransparent": false,
          "displayMode": "adaptive",
          "colorTheme": "light",
          "locale": "en"
          }
        `;
        document.getElementById('tradingview-widget-container__widget')?.appendChild(script);
        // return () => {
        //   document.getElementById('tradingview-widget-container__widget')?.removeChild(script);
        // };
      }
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview-widget-container__widget" className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright"></div>
    </div>
  )
}

export default CattleTradingViewWidget