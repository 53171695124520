import { FormControl, FormLabel, MenuItem, Select } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { IData } from "../../../interfaces/data.interface"
import { ApplicationContext } from "../../../providers/application.provider"
import { AutoService } from "../../../services/auto-service.service"
import { FormBuilderSimpleComponent, IFormSimpleFields } from "./form-builder-simple"
import { ComponentStateDto } from "../../../interfaces/component-state.interface"
// import { FormBuilderSimpleComponent, IFormSimpleFields } from "../../form-builder/simple"

interface IModuleComponentsSelectFieldComponent
{
    name: string
    required?: boolean
    masterDisabled?: boolean
    label: string
    defaultValue?: string
    placeholder?: string
    api?: string
    options?: any[]
    setSelectHook?: any
    overRideEditable?: boolean
    child?: any[]
}

export const ModuleComponentsSelectFieldComponent = ({
    name,
    label,
    required,
    defaultValue,
    placeholder,
    api,
    options,
    setSelectHook,
    overRideEditable,
    child,
    masterDisabled
}: IModuleComponentsSelectFieldComponent) => {
    const [ form, setForm ] = useState<any>({})
    const [ response, setResponse ] = useState<IData>(ComponentStateDto)
    const [ optionsList ] = useState<any[]>(options || [])
    const { editable } = useContext(ApplicationContext)
    const [ firstRun, setFirstRun ] = useState<boolean>(true)
    useEffect(() => {
        if(firstRun) {
            setForm({[name]: defaultValue || ''})
            setFirstRun(false)
        }
    }, [ firstRun ])

    useEffect(() => {
        if(setSelectHook) {
            setSelectHook((arr: any) => ({...arr, [name]: form[name]}))
        }
    }, [ form ])

    let isEditable = editable
    if(overRideEditable)
        isEditable = true

    if(typeof masterDisabled !== "undefined") {
        isEditable = !masterDisabled
    }

    if(api) {
        if(!response.ready) {
            if(!response.loading) {
                setResponse(arr => ({...arr, loading: true }))
                AutoService(api || '')?.then(r => {
                    if(r.success) {
                        setResponse({loading: false, ready: true, data: r.data})
                    } else {
                        setResponse(arr => ({...arr, loading: false, ready: true }))
                    }
                })
            }
        }
    }

    return (
        <FormControl>
            <FormLabel component="legend" className={`${required? 'required' : ''}`}>{ label }</FormLabel>
            <Select
                readOnly={ !isEditable }
                disabled={ !isEditable }
                name={name}
                value={form[name] || ''}
                required={ required }
                placeholder={placeholder || '' }
                onChange={(e: any) => {
                    setForm((arr: any) => ({...arr, [name]: e.target.value }))
                }}
            >
                { response.ready && response.data?  
                response.data?.map((option: any, idx: number) => (
                    <MenuItem selected={option.value === form[name]} key={idx} value={option.value}>
                        { option.label }
                    </MenuItem>
                )) : 
                optionsList?.map((option, idx) => (
                    <MenuItem selected={option.value === form[name]} key={idx} value={option.value}>
                        { option.label }
                    </MenuItem>
                )) }
            </Select>
            {
                child && child.length > 0? [...child].map((r: IFormSimpleFields, key: number) => {
                    if(form[name] === r?.enableOnValue) {
                        return (
                        <div key={`child-select-${key}`} className="mt-3">
                            <FormBuilderSimpleComponent
                                formWrapper={ false }
                                fields={ [r] }
                                setError={() => {}}
                                data={[]}
                                onSubmitEvent={() => {}}
                            ><></></FormBuilderSimpleComponent>
                        </div>)
                    } 
                }) : null
            }
        </FormControl>
    )
}