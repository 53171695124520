import { useState } from 'react'
import { Buffer } from 'buffer'
import { cache, cacheGet } from '../services/cache'


type JWTI = {
    expired: boolean,
    role?: number,
    id?: number,
    admin_id?: number
    expires?: any,
}
type JWTIResponse = {
    iat: number,
    expired: boolean,
    expires: number
    role?: number, 
    admin_id?: number,
    id?: number
}

/**
 * @description Fetches the jwt token from memory
 * @param keyName Name of the value to be stored in sessionStorage
 * @returns The token
 */
export const getToken = (keyName: string = 'token') =>
{
    let tokenString = cacheGet(keyName)
    if(!tokenString)
        tokenString = ''
    return tokenString
}
/**
 * @description Sets the JWT string
 * @param jwt String returned from server /auth
 */
export const setToken = (jwt: string, keyName: string = 'token') =>
{
    cache(keyName, jwt);
}

export default function useToken(keyName: string = 'token')
{
    const getJwt = () => getToken(keyName);
    const [token, setLocalToken] = useState(getJwt());
    const setJwt = (jwt: string) => {
        setLocalToken(jwt)
        setToken(jwt)
    };

    return {
        setToken: setJwt,
        token
    }
}

export const decodeJwt = (str: string): JWTI =>
{
    const def = { expired: true }
    const json = Buffer.from(str, 'base64').toString()
    if(json === '')
        return def
    const jsonObj = json.match(/\{[^{}]+\}/gi)
    if(jsonObj?.length === 2) {
        const parsed: JWTIResponse = JSON.parse(jsonObj[1])
        const now = (+new Date())
        const future = parsed.expires * 1000
        // var issuedAtt = new Date(parsed.iat * 1000);
        return {
            expired: now >= future,
            id: parsed.id,
            role: parsed.role,  
            admin_id: parsed.admin_id,  
            expires: new Date(future)
        }
    }
    return def
}

export const identity = () => {
    return decodeJwt(getToken())
}