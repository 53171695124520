import { createSlice } from '@reduxjs/toolkit';
import { IAccount } from '../services/account';
import IComponentState, { ComponentStateDto } from '../interfaces/component-state.interface';

export interface IAccountRedux
{
    state: IComponentState<any>
    account: IAccount
}

const initialState: IAccountRedux = {
    account: { ID: null, valid: false },
    state: ComponentStateDto
};

const accountSlice = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    setAccount: (state, action) => {
        state.account = action.payload;
    },
    clearAccount: () => initialState,
    setAccountState: (state, action) => {
        state.state = action.payload;
    }
  }
});

export const { setAccount, clearAccount, setAccountState } = accountSlice.actions;
export default accountSlice.reducer;