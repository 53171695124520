import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { CattleRecords } from "../../components/user-tools/cattle-records";
import { CowHeadIcon, FasFaHatCowboySide } from "../../components/ui/fontawesome";
import { BootStrapSpinner } from "../../components/ui/views/spinner";
import { cacheDestory } from "../../services/cache";
import { HerdGroupsContext } from "../../providers/herd-groups.provider";
import ButtonBack from "../../components/ui/buttons/button-back";
import CalvingGroupFormCreate from "../../components/user-tools/calving-group-form-create";
import __ from "../../services/translator";
import './styles.scss'
import { appGroupsComplete } from "../../helpers/groups";

export default function HerdGroups()
{
    const { createNew, setCreateNew, groupsState, setRefresh, groups } = useContext(HerdGroupsContext)
    
    const createGroup = () => {
        if(!createNew) {
            cacheDestory('calving_group.form')
            cacheDestory('calving_group.toggled')
        }
        setCreateNew(!createNew)
    }

    useEffect(() => {
        if(!groupsState.ready && !groupsState.loading && !createNew) {
            setRefresh(true)
        }
    })

    return (
        <div className="main-block p-3 shadowed bkg-white">
            <div className="click-head-row">
                <h4 className="main-header"><FasFaHatCowboySide />&nbsp;{ __('Herds', 'herds') }</h4>{ createNew? <Button variant="text" onClick={createGroup}><ButtonBack /></Button> : <Button variant="outlined" onClick={createGroup} disabled={ !appGroupsComplete(groups?.groups || []) }><CowHeadIcon color="blue" />&nbsp;Create Group</Button> }
            </div>
            <p className="my-4">{ createNew? __('Create a new Calving Group.', 'create_new_calving_group') : __('Select your Calving Group from the dropdown menu to display the herd in that group.', 'cattle_group_p') }</p>

            { (groupsState.loading)? <BootStrapSpinner /> : null }
            { (groupsState.ready && !createNew)? <CattleRecords /> : null }
            { createNew? <CalvingGroupFormCreate toEditGid={"0"} createNew={setCreateNew} createNewState={createNew} /> : null }
        </div>
    )
}