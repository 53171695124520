import Translations from "../translations/text.json"
import { cache, cacheGet, cacheDestory } from "../services/cache"

export function setLang(lang: string): void
{
    cache('lang', lang, true)
}

export function getLang(): string
{
    const l = cacheGet('lang')
    return (l)? l : 'en'
}

export function clearLang(): void
{
    cacheDestory('lang')
}

export default function __(def: string, transkey: string): string
{
    const tk = transkey as keyof typeof Translations
    const l = getLang() as keyof typeof Translations[typeof tk]
    const all = 'all' as keyof typeof Translations[typeof tk]
    try {
        const t = Translations[tk]
        if(typeof t === "undefined")
            return def
        if(typeof t[all] !== "undefined")
            return t[all]
        if(typeof t[l] === "undefined")
            return def
        return t[l]
    } catch (Exception) {
        return def
    }
}