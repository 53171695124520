export const toCurrency = (
    amount: number,
    currency: 'USD',
    co: string = 'US',
    lang: string = 'en'
): string => {
    try {
      const currencyFormatter = new Intl.NumberFormat(`${ lang.toLocaleLowerCase() }-${ co.toUpperCase() }`, {
        style: 'currency',
        currency,
      });
      return currencyFormatter.format(amount);
    } catch (error) {
      return 'Invalid Locale';
    }
}

export const usd = (float: number): string => toCurrency(float, 'USD', 'US', 'en')