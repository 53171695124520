import { useContext } from "react"
import { Switch } from "@mui/material"
import { SettingsSetAttr } from "../../services/settings"
import { AccountContext } from "../../providers/account.provider"
import { Loader } from "../../components/ui/loader"
import { ComponentStateDto } from "../../interfaces/component-state.interface"
import ButtonNightShift from "../../components/ui/buttons/button-nightshift"
import Template from "../../templates/cattleproof"
import Login from "../../components/login"
import './styles.scss'

export default function Settings()
{
    const { systemSettings, setSystemState, systemState } = useContext(AccountContext)
    // Save individual setting, then reload all
    const saveAttr = (key: string, value: any) => {
        const body = {...systemSettings.system, [key]: value}
        SettingsSetAttr(body).then(() => {
            setSystemState(ComponentStateDto)
        })
    }
    
    return (
        <Login>
            <Template
                title={<><i className="fas fa-cog"></i>&nbsp;Settings</>}
                description={'Change site settings.'}
            >
                {systemState.ready? <table className="standard">
                    <tbody>
                        <tr className="setting-block">
                            <td>
                                <p className="mb-0">Toggle dark mode.</p>
                            </td>
                            <td>
                                <ButtonNightShift />
                            </td>
                        </tr>
                        <tr className="setting-block">
                            <td><p className="mb-0">Hide calving groups from menus after the calving group has been decentralized (only show new groups).</p></td>
                            <td><Switch disabled={ !systemState.ready } checked={systemSettings?.system?.hide_on_decentralize === 1} value={ systemSettings?.system?.hide_on_decentralize === 1? 1 : 0 } onChange={ (e: any) => {
                                saveAttr('hide_on_decentralize', e.target.checked)
                            }} /></td>
                        </tr>
                    </tbody>
                </table> : <Loader />}
            </Template>
        </Login>
    )
}