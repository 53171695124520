import { FormLabel, TextareaAutosize } from "@mui/material"
import { useState } from "react"
import { onTextChangeEvent } from "../submodule.base"

interface IModuleComponentsTextareaComponent
{
    name: string
    label: string
    defaultValue?: any
    required?: boolean
    disabled?: boolean
    replace?: string
    className?: string
    placeholder?: string
}

export const ModuleComponentsTextareaComponent = ({
    name, defaultValue, required, label, disabled, replace, className, placeholder
}: IModuleComponentsTextareaComponent) => {
    const [ form, setForm ] = useState<any>({ [name]: defaultValue || ''})

    return (
        <div className="col-count- gapped">
            <FormLabel component="legend" className={`${required? 'required' : ''}`}>{label}</FormLabel>
            <TextareaAutosize
                disabled={ disabled || false }
                name={ name }
                value={ form[name] || '' }
                onChange={ (e: any) => onTextChangeEvent(e, setForm, replace) }
                required={ required || false }
                className={ className || "" }
                placeholder={ placeholder || "" }
            />
        </div>
    )
}