import { columnToTitle, multiFormatter } from "../../helpers/util.helper"
import { IAnyType } from "../../interfaces/form-builder.interface"


export interface IStandardTable
{
    data: any,
    skip?: string[]
    replaceKeys?: IAnyType<any>,
    replaceValues?: IAnyType<any>
}

const StandardTable = ({data, skip, replaceKeys, replaceValues}: IStandardTable) => {
    const replaceKeysFunc = (value: string) => replaceKeys && replaceKeys[value]? replaceKeys[value] : value
    const replaceValsFunc = function(keyName: string) {
        const ret: { replace: boolean, val: any }  = { replace: false, val: null }
        if(!replaceValues) {
            ret.replace = false
            ret.val = data[keyName]
            return ret
        }
        else if(!replaceValues[keyName]) {
            ret.replace = false
            ret.val = data[keyName]
            return ret
        }
        if(['string','number'].includes(typeof replaceValues[keyName])) {
            ret.replace = true
            ret.val = replaceValues[keyName]
            return ret
        }
        if(!replaceValues[keyName]?.type) {
            ret.replace = false
            ret.val = data[keyName]
            return ret
        }
        switch(replaceValues[keyName]?.type) {
            case('func'):
                ret.val = replaceValues[keyName]?.value(data[keyName])
                ret.replace = true
                break
            default:
                ret.replace = true
                ret.val = replaceValues[keyName]?.value
        }
        return ret
    }

    return (
        data?
        <table className="auto-table alternate-rows mt-4">
            <tbody>
                {
                    Object.keys(data).map((v, k) => {
                        const val: any = replaceValsFunc(v)
                        if(!skip) {
                            return (
                        <tr key={k}>
                            <td>{ replaceKeysFunc(columnToTitle(v)) }</td>
                            <td>{ (val.replace)? val.val : (typeof val.val === "object")? <StandardTable data={val.val} /> : multiFormatter(v, val.val) }</td>
                        </tr>
                        )} else {
                            return (
                                !(skip? skip : []).includes(v)?
                        <tr key={k}>
                            <td>{ replaceKeysFunc(columnToTitle(v)) }</td>
                            <td>{ (val.replace)? val.val : (typeof val.val === "object")? <StandardTable data={val.val} skip={skip} /> : multiFormatter(v, val.val) }</td>
                        </tr> : null
                            )

                        }
                    })
                }
            </tbody>
        </table> : null 
    )
}

export default StandardTable