import { useState } from "react"
import { Typography } from "@mui/material"
import { IContextChildren } from "../../../interfaces/context-children.inteface"
import { ModuleComponentsTextFieldComponent } from "./text-field.component"
import { ModuleComponentsSelectFieldComponent } from "./select-field.component"
// import { ModuleComponentsTextFieldComponent } from "../module/components/text-field.component"
// import { ModuleComponentsSelectFieldComponent } from "../module/components/select-field.component"
// import { IContextChildren } from "../../interfaces/context-children.interface"
//import IComponentState, { ComponentStateDto } from "../../interfaces/component-state.interface"

interface IFormSimpleFieldsOptions
{
    label: string
    value: string | number | boolean
}

export interface IFormSimpleFields
{
    type: string
    name: string
    fname: string
    defaultValue?: string | number | boolean | null
    label: string
    value: string | number | boolean | null
    disabled?: boolean
    required?: boolean
    replace?: string
    match?: string
    options?: IFormSimpleFieldsOptions[]
    enableOnValue?: CallableFunction
    child?: IFormSimpleFields[]
    className?: string
    masterDisabled?: boolean
}

interface IFormBuilderSimpleComponent extends IContextChildren
{
    fields: IFormSimpleFields[]
    data: any
    setError: any
    onSubmitEvent: any
    parentName?: string
    className?: string
    formWrapper: boolean
}

export const FormBuilderSimpleComponent = ({
    fields,
    data,
    setError,
    parentName,
    onSubmitEvent,
    className,
    formWrapper,
    children
}: IFormBuilderSimpleComponent) => {
    //const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    // const [ form, setForm ] = useState<any>(data || {})
    const [ instr ] = useState<any>(fields)

    const handleFormSubmit = (e: any) => {
        const formData = new FormData(e.target)
        const formBuilderData: any = {}
        formData.forEach((v, k) => {
            if(k.match(/_date$/gi)) {
                if(typeof v === "string" && !v.match(/[\d]{4}-[\d]{2}-[\d]{2}/gi)) {
                    setError('You have a bad date in this section.')
                }
            }
            formBuilderData[k] = v
        })

        return formBuilderData
    }

    // const setFormData = (name: string, value: any) => {
    //     setForm((arr: any) => ({...arr, [name]: value}))
    // }

    const keyName = parentName? `${parentName}.` : ''

    const formFields = instr.map((v: IFormSimpleFields, k: number) => {
        const { name, type, defaultValue, required, label, replace, match, disabled, options, child, className, masterDisabled } = v
        let obj: any = <></>

        switch (type) {
            case ('text'):
                obj =  (
                <div key={`simple-form-${k}`} className={`simple-form-${k} ${className || ''}`}>
                    <ModuleComponentsTextFieldComponent
                        name={`${keyName}${name}`}
                        defaultValue={data[`${keyName}${name}`] || defaultValue || ''}
                        masterDisabled={ masterDisabled }
                        required={ required || false }
                        title={label}
                        replace={ replace }
                        match={ match }
                        disabled={ typeof disabled !== "boolean"? false : disabled }
                        overRideEditable={ true }
                    />
                </div>)
                break
            case ('password'):
                obj =  (
                <div key={`simple-form-${k} ${className || ''}`} className={`simple-form-${k} ${className || ''}`}>
                    <ModuleComponentsTextFieldComponent
                        name={ `${keyName}${name}` }
                        defaultValue={ data[`${keyName}${name}`] || defaultValue || '' }
                        masterDisabled={ masterDisabled }
                        required={ required }
                        title={label}
                        replace={ replace }
                        match={ match }
                        type='password'
                        overRideEditable={ true }
                    />
                </div>)
                break
            case ('label'):
                obj = (
                <div key={`simple-form-${k} ${className || ''}`} className={`simple-form-${k} ${className || ''}`}>
                    <Typography variant='h4'>{ label }</Typography>
                </div>)
                break
            case ('select'):
                obj = (
                <div key={`simple-form-${k} ${className || ''}`} className={`simple-form-${k} ${className || ''}`}>
                    <ModuleComponentsSelectFieldComponent
                        name={`${keyName}${name}`}
                        defaultValue={data[`${keyName}${name}`] || defaultValue || ''}
                        masterDisabled={ masterDisabled }
                        required={ required || false }
                        label={label}
                        options={options || []}
                        overRideEditable={ true }
                        child={child}
                    />
                </div>)
                break
            default:
                if(['email'].includes(type)) {
                    obj =  (
                    <div key={`simple-form-${k}`} className={`simple-form-${k} ${className || ''}`}>
                        <ModuleComponentsTextFieldComponent
                            name={`${keyName}${name}`}
                            type={type}
                            defaultValue={data[`${keyName}${name}`] || defaultValue || ''}
                            masterDisabled={ masterDisabled }
                            required={ required || false }
                            title={label}
                            replace={ replace }
                            match={ match }
                            disabled={ typeof disabled !== "boolean"? false : disabled }
                            overRideEditable={ true }
                        />
                    </div>)
                }
                break;
        }

        return obj
    })

    const render = () => (
        <>
        <div className={ `form-fields ${ className || `col-count-` } gapped` }>
            { formFields }
        </div>
        <div className="form-childrent col-count- gapped">
            { children }
        </div>
        </>
    )
    
    return (
        formWrapper? 
        <form onSubmit={(e: any) => {
            e.preventDefault()
            const f: any = handleFormSubmit(e)
            onSubmitEvent(f, e)
        }} id={`form-${parentName || 'dynamic-simple'}`}>
            { render() }
        </form> : render()
    )  
}