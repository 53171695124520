import { createContext, useState } from "react"
import { IContextChildren } from "../interfaces/context-children.inteface"



interface IModuleSubmoduleBaseContext
{
    subData?: any
    setSubData?: any
    parentName: string
    onRemoveEvents?: any
    setOnRemoveEvents?: any
    increment: number
    setIncrement?: any
}

interface IModuleSubmoduleBaseProviderChildren extends IContextChildren
{
    parentNameElem: string
}

export const ModuleSubmoduleBaseContext = createContext<IModuleSubmoduleBaseContext>({ parentName: '', increment: 0})

export const ModuleSubmoduleBaseProvider = ({ children, parentNameElem }: IModuleSubmoduleBaseProviderChildren) => {
    const [ subData, setSubData ] = useState<any>({})
    const [ parentName ] = useState<string>(`${parentNameElem}`)
    const [ onRemoveEvents, setOnRemoveEvents ] = useState<any>(undefined)
    const [ increment, setIncrement ] = useState(0)

    return (
        <ModuleSubmoduleBaseContext.Provider value={{
            subData,
            setSubData,
            parentName,
            onRemoveEvents,
            setOnRemoveEvents,
            increment, setIncrement
        }}>
            <div className={`submodule-provider-${parentName?.replace('.', '-').replace('_', '-').replace('[]', '') || 'standard'}`}>
                { children }
            </div>
        </ModuleSubmoduleBaseContext.Provider>
    )
}