export class BaseDto
{
    protected populate(obj: object, comp: any)
    {
        const typeOf = typeof obj
        if(typeOf !== "undefined" && typeOf !== null) {
            for(const k in obj) {
                if(typeof obj[k as keyof typeof obj] !== "undefined" && typeof comp[k as keyof typeof obj] !== "undefined")
                    comp[k as keyof typeof obj] = obj[k as keyof typeof obj]
            }
        }
    }
}