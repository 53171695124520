import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { useEffect, useState } from "react"
import { Validation, ValidationSaveService } from "../../../services/validation"
import { AlertSuccess } from "../../ui/views/alerts"
import ButtonBack from "../../ui/buttons/button-back"
import __ from "../../../services/translator"
import { loop } from "../../../helpers/arrayworks.helper"

export type IValidationResponse = {
    success: boolean,
    error?: string,
    group: any
}

export default function AdminValidation(props?: any)
{
    const [ validationList, setValidationList ] = useState<any>({})
    const [ validationListLoaded, setValidationListLoaded ] = useState(false)
    const [ validChecked, setValidChecked ] = useState<string[]>([])
    const [ validated, setValidated ] = useState(false)
    const [ finishedValidate, setFinishedValidate ] = useState(false)
    const [ listCount, setListCount ] = useState(0)
    const [ listChecked, setListChecked ] = useState(0)

    useEffect(() => {
        const modalFooter = document.getElementById('modal-footer')
        if(modalFooter) {
            modalFooter.classList.remove('modal-footer')
        }
    })

    if(!validationListLoaded) {
        Validation({gid: props.gid}).then(r => {
            setValidationList(r.group)
            setValidationListLoaded(true)
            setListCount(Object.keys(r.group).length)
        })
    }

    const validateHerd = () => {
        ValidationSaveService({ gid: props.gid }).then(r => {
            if(r.success) {
                setFinishedValidate(true)
            }
        })
    }

    const toggleArray = (arr: any[], v: any) => arr.includes(v)? [...arr].filter(val => val !== v) : [...arr, v]

    return (
        <div className="validation-modal">

            { !finishedValidate && !props.group.certified_at? 
            <span>
                <p className="mb-3">These are the validation elements that have been indicated by the client when they created their calving group.</p>
                <FormGroup>
                { loop(validationList.certs, (v: any, k: string) => {
                    return (
                        <div key={k} className="col-count-12 col-c10-md">
                            <div className="focus-element my-1">
                                <FormControlLabel control={<Checkbox checked={validChecked.includes(v.shortcode)} onChange={() => {
                                    const checkedQty = toggleArray(validChecked, v.shortcode)
                                    setValidChecked(checkedQty)
                                    setListChecked(checkedQty.length)
                                    setValidated(listCount === checkedQty.length)
                                }} />} label={validationList[v.shortcode]} />
                            </div>
                            <div className="span11 span9-md">
                                <h6>{ v.image? <img src={ v.image } alt={ v.name } style={{maxWidth: 40, marginRight: '0.5em'}} /> : null }<strong>{ v.name }</strong></h6>
                                <p>{ v.description }</p>
                            </div>
                        </div>
                    )
                }, null)}
                </FormGroup>
                { validated? <AlertSuccess text="Certify indicates that you have checked all pertinent paperwork to prove the herd information is accurate, to the best of your knowledge." /> : null }

                <div className="align-middle">
                    <div className="d-flex flex-row gapped">
                        <Button className="py-3" disabled={!validated} variant="contained" onClick={() => {
                            validateHerd()
                        }}><i className="fas fa-award"></i>&nbsp;Certify ({listChecked}/{listCount})</Button>

                        <Button className="py-3 px-5" variant="outlined" onClick={() => props.onCancel() }><ButtonBack text={__('Cancel', 'cancel')} /></Button>
                    </div>
                </div>
            </span> :
            
            <div className="align-middle">
                <AlertSuccess text="You have successfully certified this herd." />
                <Button className="py-3 px-5" variant="outlined" onClick={() => props.onCancel() }><ButtonBack text={__('Done', 'done')} /></Button>
            </div> }
            
        </div>
    )
}