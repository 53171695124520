import { BaseDto } from "../dto/base.dto"
import HttpClient, { http } from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"

type INotifications = {
    count: boolean
}

export interface INotes
{
    ID: string
    uid: string
    type: string
    appid: number | null
    gid: string | null
    parent_id: number | null
    note: string
    hidden_at: string | null
    read_at: string | null
    notified_at: string | null
    created_at: string | null
    deleted_at: string | null
}

export class INote extends BaseDto implements INotes
{
    ID: string = '0'
    uid: string = '0'
    type: string = ''
    appid: number | null = null
    gid: string | null = null
    parent_id: number | null = null
    note: string = ''
    hidden_at: string | null = ''
    read_at: string | null = ''
    notified_at: string | null = ''
    created_at: string | null = ''
    deleted_at: string | null = ''

    constructor(obj: any)
    {
        super()
        this.populate(obj, this)
    }
}

export interface INotify
{
    count: number
    notes: INotes[]
}

export default function Notification(props?: INotifications): Promise<INotify>
{
    const useCount = (typeof props !== "undefined" && props.count)? '?count=true' : ''

    return new Promise<INotify>((success, fail) => {
        try {
            (new HttpClient()).get<INotify>(`notification/internal${useCount}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export const Communique = (mid?: string) => {
    return http().get<IResponse<any[]>>(`internal/communique/${mid? `mng/${mid}/` : ''}`)
}

export const CommuniqueUpdate = (mid?: string) => {
    return http().patch<IResponse<any[]>>(`internal/communique/${mid}/`)
}

export const CommuniqueDelete = (mid?: string) => {
    return http().delete<IResponse<any[]>>(`internal/communique/${mid}/`)
}

/**
 * @param   nid     Notification Id
 * @param   status  Notifification type
 */
type INotificationUpdate = {
    nid: string,
    status: string
}

export function NotificationUpdate(params: INotificationUpdate)
{
    return new Promise<INotify>((success, fail) => {
        try {
            (new HttpClient()).post<INotify>(`notification/internal`, { nid: params.nid, status: params.status }).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}