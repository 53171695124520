import { useEffect } from "react"
import { useHerdLockHook, usePreflightHook } from "../../../hooks/herd.hook"
import { LockWindowModal } from "../../../hooks/herd-hook/lock-modal-window"

interface ILockHerdFromGrid
{
    id: string
    onSuccessLock: any
    onCloseLock: any
    group: any
    setGid: any
}
/**
 * @description Locks a herd from the grid view
 */
export const LockHerdFromGridComponent = ({ id, onSuccessLock, onCloseLock, group, setGid }: ILockHerdFromGrid) => {
    const { setLock, setOnSuccessEvent } = useHerdLockHook(id)
    const { setPfGid, preFlight } = usePreflightHook()

    useEffect(() => {
        if(id)
            setPfGid(id)
    }, [ id ])

    return (
        preFlight.ready && 
        <LockWindowModal 
            preFlight={ preFlight }
            group={ group }
            setGid={ setGid }
            events={ {
                openEvent: () => {
                    //setToggleLock(true)
                },
                closeEvent: onCloseLock,
                submitEvent: () => {
                    setLock(true)
                    setOnSuccessEvent(onSuccessLock)
                }
            }}
        />
    )
}