import { FC, useContext } from 'react'
import { environment } from '../../../environmentals/config'
import CattleTradingViewWidget from '../../widgets/cattle-trade-ticker'
import './styles.scss'
import { AccountContext } from '../../../providers/account.provider'


const LayoutFooter: FC = () => {
    const { systemSettings, systemState } = useContext(AccountContext)
    return (
        <footer className="border-top-heavy col-count-3 offset">
            <div className="start2 align-middle">
                <p className="legal mb-2">HERD MANAGER v{ environment.appVersion }</p>
            </div>
            <div className="start2 pad-top-2 pad-bottom-2 align-middle"><p className="legal">Copyright &copy; { environment.companyName } { (new Date).getFullYear() }. All Rights Reserved.</p></div>
            { systemSettings?.system?.ticker_active !== 0 && systemState.ready?
            <div className="span3 fixed-bottom">
                <CattleTradingViewWidget />
            </div> : null }
        </footer>
    ) 
}

export default LayoutFooter