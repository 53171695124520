import HttpClient, { http } from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"

export function GetOptionsService(column: string)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).get<any>(`option/${column}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function FormDropdownSettings<T>() {
    return http().get<IResponse<T>>('form/settings/dropdowns/')
}