import { useEffect, useState } from "react"
import { GetCountriesService, GetStatesService } from "../services/locale.service"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"

export const useRegionsHook = () => {
    const [ regions, setRegions ] = useState<IComponentState<any>>(ComponentStateDto)

    useEffect(() => {
        if(!regions.ready) {
            if(!regions.loading) {
                setRegions(ComponentStateLoadingDto)
                GetStatesService('US').then(r => {
                    setRegions({...ComponentStateReadyDto, data: r.data})
                })
            }
        }
    }, [ regions.ready ]);

    return {
        regions, setRegions
    }
}

export const useCountryHook = () => {
    const [ country, setCountry ] = useState<IComponentState<any>>(ComponentStateDto)

    useEffect(() => {
        if(!country.ready) {
            if(!country.loading) {
                setCountry(ComponentStateLoadingDto)
                GetCountriesService().then(r => {
                    setCountry({...ComponentStateReadyDto, data: r.data})
                })
            }
        }
    }, [ country.ready ]);

    return {
        country, setCountry
    }
}