import { createSlice } from "@reduxjs/toolkit"
import { IAnyType } from "../interfaces/form-builder.interface"
import { IBreeds } from "../services/breeds"
import IComponentState, { ComponentStateDto } from "../interfaces/component-state.interface"

export interface IBreedsAvailableRedux
{
    state: IComponentState<{
        breeds: IAnyType<string>[]
        myBreeds: string[]
        allBreeds: IBreeds[]
    }>
}

const initialState: IBreedsAvailableRedux = {
    state: ComponentStateDto
};

export const breedsSlice = createSlice({
  name: 'breeds',
  initialState: initialState,
  reducers: {
    setBreeds: (state, action) => {
        state.state = action.payload;
    },
  }
});

export const { setBreeds } = breedsSlice.actions;
export default breedsSlice.reducer;