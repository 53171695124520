import { count } from "../../helpers/util.helper"
import { Tooltip } from "@mui/material"
import { IPreFlightWindow } from "./herdlock.interface"

export const ContinuePreFlightWindow = ({ group, preFlightData }: IPreFlightWindow) => {

    return (
        (count(group?.selected_certs) > 0 && preFlightData().qualified_total > 0)? 
        <p className="mt-4">If you are satisfied that this herd group is accurate, click the Commit Herd button below. You will not be able to make any further changes. CattleProof will be notified that this group is ready for certification. Once certified, each animal's data will be written to the CattleProof Verified Ledger as a public record.</p>
        :
        <p className="mt-4">If you continue, this group will bypass certifications and be <Tooltip className="rel" title="This will allow your animals to be pushed to storage without CattleProof certifications.">
            <strong>"Ranch Certified"<i className="fas fa-info-circle mr-4 fa-sm"></i></strong>
        </Tooltip> instead. No further action, except payment, will be required.</p>
    )
}