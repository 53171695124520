import { useState } from "react"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto } from "../../../interfaces/component-state.interface"
import { DataGrid, GridColDef, GridRowModel, GridRowModesModel } from "@mui/x-data-grid"
import { AcceptUploadEvidenceService, MediaDeleteService, MediaDownloadService } from "../../../services/media.service"
import { IResponse } from "../../../interfaces/response.interface"
import { toDate, toFileSize } from "../../../helpers/util.helper"
import { getStatusCode } from "../../../enums/upload-status.enum"
import { GroupStatusPending } from "../../../enums/group.status.enum"
import { isAdmin } from "../../../helpers/account"
import { Tooltip } from "@mui/material"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { download } from "../../../helpers/files"
import AlertBox from "../../upload/alert"

interface IUploadDocGroup
{
    ID?: string
    description?: string
    gid?: string
    uid?: string
    name?: string
    real_name?: string
    unique_id?: string
    size?: number
    uploaded_at?: string
    uploaded_by?: number
    usergroup?: number
}

interface IUploadSupportDocsTableComponent
{
    setGid: any
    gidState: IComponentState<any>
    uploads: IUploadDocGroup[]
    group: any
    setUploads: any
    setGidState: any
}

export const UploadSupportDocsTableComponent = ({ setGidState, setUploads, group, gidState, uploads }: IUploadSupportDocsTableComponent) => {
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({})
    const [ lockFile, setLockFile ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ toggle, setToggle ] = useState<any>({})
    const updateAttribute = (e: any) => e

    const downloadFile = (ID: string) => download(ID);
    
    const deleteFile = (ID: string) => {
        MediaDeleteService(ID).then(r => {
            if(r.success && r.data) {
                setUploads(r.data)
                if(state.ready)
                    setState(ComponentStateDto)
            }
        })
    }

    const setFileAccepted = (fid: string, type: boolean = true) => {
        if(!lockFile.loading) {
            setLockFile(ComponentStateLoadingDto)
            AcceptUploadEvidenceService(fid, type? 'accepted' : 'denied').then((r: IResponse<any>) => {
                if(r.success) {
                    setState(ComponentStateDto)
                    setGidState(ComponentStateDto)
                }
                setLockFile(ComponentStateDto)
            })
        }
    }

    const columns: GridColDef[] = [
        { field: 'real_name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Type' },
        { field: 'size', headerName: 'Size', renderCell: e => toFileSize(e.row.size || 0) },
        { field: 'status', headerName: 'Status', minWidth: 180, renderCell: e => <div className={`tag tag-size-md tag-${getStatusCode(e.row).toLocaleLowerCase()} uppercase`}>{getStatusCode(e.row)}</div> },
        { field: 'uploaded_at', headerName: 'Upload Date', minWidth: 180, renderCell: e => toDate(e.row.uploaded_at) },
        { field: '', headerName: '', minWidth: 200, renderCell: e => {
            return (
                <GroupStatusPending status={group.status} enableOverrideOn={ isAdmin() } >
                    {isAdmin()? <Tooltip title="Accept the upload as evidence."><button className={`no-appearance ${e.row.accepted_at? 'txt-green' : ''}`} onClick={() => setFileAccepted(e.row.ID) }><i className="far fa-check-circle txt-green"></i></button></Tooltip> : null }
                    {isAdmin()? <Tooltip title="Deny the upload as evidence."><button className={`no-appearance ${e.row.denied_at? 'txt-red' : ''}`} onClick={() => setFileAccepted(e.row.ID, false) }><i className="far fa-times-circle txt-red"></i></button></Tooltip> : null }
                    { toggle[e.row.ID]? 
                <>
                    
                    <button className="no-appearance" onClick={() => setToggle((arr:any) => ({...arr, [e.row.ID]: false }))}><i className="fas fa-ban"></i></button>
                    <button className="no-appearance" onClick={() => deleteFile(e.row.ID)}><i className="far fa-trash-alt red"></i></button>
                </>
                    : 
                <>
                    {e.row.accepted_at? null : <button className="no-appearance" onClick={() => setToggle((arr:any) => ({...arr, [e.row.ID]: true }))}><i className="far fa-trash-alt"></i></button> }
                    <button className="no-appearance" onClick={() => downloadFile(e.row.ID)}><i className="fas fa-download"></i></button>
                </>
                    }
                    
                </GroupStatusPending>
            )
        } }
    ]

    return (
        !gidState.loading && uploads.length > 0? 
        <DataGrid
            getRowId={(e) => e.ID}
            rowModesModel={ rowModesModel }
            onRowModesModelChange={ setRowModesModel }
            processRowUpdate={(e: GridRowModel) => {
                updateAttribute(e)
                return e
            }}
            rows={ uploads.length > 0? uploads : [] }
            columns={ columns }
            checkboxSelection={false}
        /> : gidState.loading? <BootStrapSpinner /> : <AlertBox errorClass={ `secondary` } errorMessage={ `No support docs found.` } />
    )
}