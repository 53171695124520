import { useNavigate, useParams } from "react-router-dom"
import { CreateNewGroupButtonComponent } from "../../components/ui/buttons"
import { BootStrapSpinner } from "../../components/ui/views/spinner"
import { empty } from "../../helpers/util.helper"
import { IGroupsHook } from "../../hooks/groups.hook"
import { useState } from "react"
import { DispositionComponent } from "./components"
import { DispositionGetAvailableHerdGroupsService } from "../../services/disposition.service"
import { IResponse } from "../../interfaces/response.interface"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../interfaces/component-state.interface"
import PlaceIcon from '@mui/icons-material/Place'
import Template from "../../templates/cattleproof"

export interface IDispositionForm
{
    ID?: string
    unique_id?: string
    uid?: string
    did?: string
    title?: string
    description?: string
    price?: string | number
    weight?: number | string
    status?: number
    herd?: number
    price_includes_transport?: number | boolean
    created_at  ?: string | null
    completed_at?: string | null
    deleted_at?: string | null
    updated_at?: string | null
    destination_id?: string | null
}

export const DispositionPage = () => {
    const [ formData, setFormData ] = useState<IDispositionForm>({})
    const [ groupState, setGroupState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ groups, setGroups ] = useState<IGroupsHook>({count: 0, groups: [], uid: '', valid: false})
    // const { groups, groupState, setGroupState } = useGroupsHook()
    const { gid } = useParams()
    const nav = useNavigate()

    if(!groupState.ready) {
        if(!groupState.loading) {
            setGroupState(ComponentStateLoadingDto)
            DispositionGetAvailableHerdGroupsService().then((r: IResponse<IGroupsHook>) => {
                setGroups(r.data)
                setGroupState(ComponentStateReadyDto)
            })
        }
    }

    const onEditEvent = (data: any) => {
        setFormData(data)
        nav(`/disposition/${empty(data)? '' : data.ID}/`)
    }

    const onClickEventGoButton = () => {
        nav(empty(groups.groups)? '/create/group/' : '/disposition/create/')
        setGroupState(ComponentStateDto)
        setFormData({})
    }
    
    const onClickEventGoBackButton = () => {
        nav('/disposition/')
        setGroupState(ComponentStateDto)
        setFormData({})
    }
    
    const defTitle: JSX.Element = <><PlaceIcon />&nbsp;My Disposition Groups</>

    const isFocused: boolean = !empty(gid)
    
    const isUpdateFocused: boolean = isFocused && gid !== 'create'
    
    const isNewFocused: boolean = isFocused && gid === 'create'

    return (
        <Template
            title={ isNewFocused? formData?.title || `Create` : isUpdateFocused? (formData?.title || defTitle) : defTitle }
            description={ `Manage your disposition groups. These groups will be available in the Marketplace or available to transport when required.` }
            backButtonLabel={ ( isNewFocused || isUpdateFocused )? defTitle : `` }
            backButtonEvent={ !empty(gid) ? onClickEventGoBackButton : undefined }
            createButton={ <CreateNewGroupButtonComponent resetGroup={() => {}} title={`Create ${empty(groups.groups)? 'Animal' : 'Disposition'} Group`} onClickEvent={ onClickEventGoButton } /> }
        >
            { groupState.ready? 
                empty(!groups.groups)? 
                <DispositionComponent
                    groups={ groups }
                    backButtonEvent={ onClickEventGoBackButton }
                    createEvent={ onClickEventGoButton }
                    setUpdateEvent={ onEditEvent }
                    setFormData={ setFormData }
                    formData={ formData }
                /> : <div className="align-middle"><p>No groups available. Please create a group.</p></div>
                : 
                <BootStrapSpinner />
            }
        </Template>
    )
}