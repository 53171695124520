import { BaseDto } from "./base.dto"

export class CattleFormSingleViewDto extends BaseDto
{
    protected buttonActions: any = null
    public ID: string = '0'
    public id: string = '0'
    public uid: string = '0'
    public gid: string = '0'
    public ranch_name: string = ''
    public ranch_tag_id: string = ''
    public eid: string = ''
    public breed: string = ''
    public birth_date: string = ''
    public tag_color: string = ''
    public visual_tag: string = ''
    public location: string = ''
    public harvest_date: string = ''
    public death_date: string = ''
    public breed_certified_at: string = ''
    public eid_certified_at: string = ''
    public ear_notches_certified_at: string = ''
    public brands_certified_at: string = ''
    public metalplastic_clip_certified_at: string = ''
    public other_tag_certified_at: string = ''
    public ranch_certified_at: string = ''
    public status: number = 0
    public created_at: string = ''
    public deleted_at: string = ''
    public decentralized_at: string = ''
    public cid: string = ''
    public archived_at: string = ''
    public decentralizing: string = ''


    constructor(obj:any)
    {
        super()
        this.populate(obj, this)

        this.id = this.ID
    }
}

export default interface ICattleFormSingleViewDto {
    ID?: string;
    id?: string;
    uid?: string;
    gid?: string;
    ranch_name?: string;
    ranch_tag_id?: string;
    eid?: string;
    breed?: string;
    birth_date?: string;
    tag_color?: string;
    visual_tag?: string;
    location?: string;
    harvest_date?: string;
    death_date?: string;
    breed_certified_at?: string;
    eid_certified_at?: string;
    ear_notches_certified_at?: string;
    brands_certified_at?: string;
    metalplastic_clip_certified_at?: string;
    other_tag_certified_at?: string;
    ranch_certified_at?: string;
    status?: number;
    created_at?: string;
    deleted_at?: string;
    decentralized_at?: string;
    cid?: string;
    archived_at?: string;
    decentralizing?: string;
    has_replacement_tag?: string;
}
