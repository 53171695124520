import { useContext, useState } from "react"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../../interfaces/component-state.interface"
import { AlertContext } from "../../../../providers/alert.provider"
import { WizardServiceTesting, WizardServiceTestingSave } from "../../../../services/wizard"
import * as WI from "../../../../interfaces/wizard.interface"
import { inArray } from "../../../../helpers/arrayworks.helper"
import { empty } from "../../../../helpers/util.helper"
import { AlertDanger } from "../../../ui/views/alerts"
import { CircularProgress } from "../../../ui/views/spinner"
import ButtonBack from "../../../ui/buttons/button-back"
import { Button, MenuItem, Select } from "@mui/material"

interface IWizardTestQuestions
{
    setStartTest: any
    videoCode?: string
}

export const WizardTestQuestions = ({ setStartTest, videoCode }: IWizardTestQuestions) => {
    const [ questionsState, setQuestionsState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ answers, setAnswers ] = useState<any>({})
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ correctAnswer, setCorrectAnswer ] = useState<boolean>(false)
    const [ calculating, setCalculating ] = useState<boolean>(false)
    const { setMsg, setType, msg } = useContext(AlertContext)

    if(!questionsState.ready) {
        if(!questionsState.loading) {
            setQuestionsState(ComponentStateLoadingDto)
            WizardServiceTesting<WI.IWizardSignUpQuestions>('startup', { qty: 5 }).then(r => {
                setQuestionsState({...ComponentStateReadyDto, data: r.data })
            })
        }
    }

    const onChangeEvent = (e: any) => {
        const { name, value } = e.target
        setAnswers((arr: any) => ({...arr, [name]: value}))
    }
    
    const onSubmit = () => {
        setMsg('')
        if(!inArray(-1, answers) && !empty(answers)) {
            setCalculating(false)
            setState(ComponentStateDto)
            if(!state.loading) {
                setState(ComponentStateLoadingDto)
                WizardServiceTestingSave<WI.IWizardServiceTestingSave>('startup', answers).then(r => {
                    setState(ComponentStateReadyDto)
                    setCalculating(true)
                    setCorrectAnswer(r.success)
                })
            }
        } else {
            setMsg('All fields are required.')
            setType(false)
        }
    }

    const onFinish = () => {
        window.location.reload()
    }

    return (
        <div className="question-list rel">
            { state.ready && calculating && !correctAnswer? 
            <>
                { videoCode? <div className="align-middle mb-4" dangerouslySetInnerHTML={{__html: videoCode || ''}}></div> : null }
                <AlertDanger text="One or more of your answers is not correct. Please try again!" />
            </>
            : null }
            { msg !== ''? <AlertDanger text={msg} /> : null }
            { !questionsState.ready? <CircularProgress /> : 
                !(state.ready && calculating && correctAnswer)?
                questionsState.data.map((v: any, k: number) => {
                    return (
                        <div key={ `q-${k}` } className="mb-4">
                            <h5><strong>Q:</strong> {v.question}</h5>
                            <div className="col-count-1">
                                <Select
                                    name={ v.ui }
                                    value={ answers[v?.ui] || -1 }
                                    onChange={onChangeEvent}
                                >
                                    <MenuItem value={ -1 }>Select Answer</MenuItem>
                                    { v.options.map((opt: any, key: number) => {
                                        return ( <MenuItem key={ `a-${k}-${key}` } value={ opt.name }><span dangerouslySetInnerHTML={{ __html: opt.value }} /></MenuItem> )
                                    }) }
                                </Select>
                            </div>
                        </div>
                    )
                }) : null
            }
            
            <div className="align-middle test-wizard-buttons">
                { state.ready && calculating && correctAnswer? 
                <div className="text-center success-window">
                    <h4 className="mt-0">Great Job!</h4>
                    <p>You have successfully completed your training and can now access your account!</p>
                    <Button variant="contained" onClick={ onFinish }>Finished</Button>
                </div>
                :
                <div className="col-count-2 gapped col-c1-sm">
                    <Button variant="outlined" onClick={() => setStartTest(false)}><ButtonBack /></Button>
                    <Button variant="contained" onClick={ onSubmit }>Finished</Button>
                </div>
                }
            </div>
        </div>
    )
}