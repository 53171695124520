import { useContext, useState } from "react"
import { loop } from "../../../helpers/arrayworks.helper"
import { Loader } from "../../../components/ui/loader"
import { useParams } from "react-router-dom"
import { AlertBase } from "../../../components/ui/views/alerts"
import { IGroupsHook } from "../../../hooks/groups.hook"
import { AlertContext } from "../../../providers/alert.provider"
import { empty } from "../../../helpers/util.helper"
import { DispositionForm } from "./form"
import { CreateButtonBlock } from "../../../components/ui/blocks"
import { DispositionDashboard } from "./dashboard"
import { DispositionGetService } from "../../../services/disposition.service"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import PermissionsDataGridRowsComponent from "../../../components/permissions/datagrid-rows"

interface IDispositionComponent
{
    groups: IGroupsHook
    backButtonEvent: any
    createEvent: any
    setFormData: any
    formData: any
    setUpdateEvent: any
}

export const DispositionComponent = ({
    groups,
    backButtonEvent,
    createEvent,
    setFormData,
    formData,
    setUpdateEvent
}: IDispositionComponent) => {
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ dGroups, setDGroups ] = useState<any[]>([])
    const { setMsg, setType, setAutoClose } = useContext(AlertContext)
    const { gid } = useParams()
    const filteredGroups: any[] = []

    loop(groups.groups, (v: any) => {
        if(v.decentralized === 1) {
            filteredGroups.push(v)
        }
    })

    const reload = () => setState(ComponentStateDto)
    
    if(!state.ready) {
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            DispositionGetService().then(r => {
                if(r.success) {
                    setDGroups(r.data)
                } else {
                    setType(false)
                    setAutoClose(true)
                    setMsg(r.error || '')
                }
                setState(ComponentStateReadyDto)
            })
        }
    }
    
    return (
        state.ready? 
        <div>
            { !empty(gid)? 
                <DispositionForm
                    reload={ setUpdateEvent }
                    setFormData={ setFormData }
                    groups={ groups }
                    backButtonEvent={ backButtonEvent }
                    formData={ formData }
                /> : 
                <PermissionsDataGridRowsComponent
                    label={
                    <AlertBase
                        text="You do not have any disposition groups."
                        type="info"
                    /> }
                    rows={ dGroups || []}
                >
                    <DispositionDashboard
                        reloadEvent={ reload }
                        groups={ dGroups }
                        backButtonEvent={ backButtonEvent }
                        editGroupEvent={ setUpdateEvent }
                        createButton={ 
                            <CreateButtonBlock
                                label={ "You have no Disposition Groups available." }
                                createEvent={ createEvent }
                            />
                        }
                    />
                </PermissionsDataGridRowsComponent>
            }
        </div> : <Loader />
    )
}