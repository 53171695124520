import { InputFile } from '../interfaces/upload.request.interface'
import { Callable } from '../interfaces/callable.interface'
import __ from './translator'
import HttpClient from '../helpers/client'
import { IResponse } from '../interfaces/response.interface'

export default function UploadCsvService(data: InputFile, sending: Callable): Promise<any>
{
    const formData = new FormData()
    const file = data.file[0]
    if(typeof file === "undefined") {
        throw new Error(__('You must select a file first', 'error_upload_invalid_file'));
    } else if(file.type !== 'text/csv') {
        throw new Error(__('Invalid file type.', 'error_upload_invalid_file_type'))
    }
    // Set the file to the form
    formData.append("file", file)
    // Set the group id to the form
    formData.append("gid", data.group)
    // Notify of sending
    sending(true)
    const http: HttpClient = new HttpClient()
    // Send off
    return http.upload(`inventory/upload`, formData)
}

export type InputFileUpload = {
    file: any,
    data?: any
}

export function UploadCsvAnyService(
    service: string,
    uploadData: InputFileUpload,
    sending: Callable,
    type: string = 'text/csv'
): Promise<any>
{
    const formData = new FormData()
    const file = uploadData.file[0]

    if(typeof file === "undefined") {
        throw new Error(__('You must select a file first', 'error_upload_invalid_file'));
    } else if(file.type !== type) {
        throw new Error(__('Invalid file type.', 'error_upload_invalid_file_type'))
    }
    // Set the file to the form
    formData.append("file", file)
    // Set the group id to the form
    if(uploadData.data) {
        for (const k in uploadData.data) {
            formData.append(k, uploadData.data[k])
        }
    }
    // Notify of sending
    sending(true)
    // Send off
    return (new HttpClient()).upload(service, formData)
}

export const UploadSingleMultiAnimalService = (body: any) => {
    return (new HttpClient()).post<IResponse<any>>(`inventory`, body)
}