import { useEffect, useState } from "react"
import { DataGrid, GridColDef, GridRowModel, GridRowModesModel } from "@mui/x-data-grid"
import { Button, TextField } from "@mui/material"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { IResponse } from "../../../interfaces/response.interface"
import { useNavigate } from "react-router-dom"
import ButtonBack from "../../ui/buttons/button-back"

interface IAnimalTableEditorComponent<T>
{
    ready: boolean
    columns: GridColDef[]
    rows: T[]
    setRows: any
    onLoadEvent?: any
    setDeleteEvent?: any
    submitEvent: any
    submitSearch: any
    onUpdateEvent?: any
    backButtonUri?: string
    backButtonEvent?: CallableFunction
}

const AnimalTableEditorComponent = ({
    columns,
    rows,
    onLoadEvent,
    submitEvent,
    submitSearch,
    onUpdateEvent,
    backButtonUri,
    backButtonEvent,
    ready
}: IAnimalTableEditorComponent<any>) => {
    const [ dataColumns ] = useState<GridColDef[]>(columns)
    const [ dataRows, setDataRows ] = useState(rows)
    const [ search, setSearch ] = useState<string>('')
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({});
    const nav = useNavigate()
    //const { setMsg, setType } = useContext(AlertContext)

    useEffect(() => {
        if(onLoadEvent) {
            onLoadEvent().then((r: IResponse<any>) => setDataRows(r.data))
        }
    }, [])

    return (
        ready?
        <div>
            { backButtonUri || backButtonEvent? <Button variant="outlined" className="mb-2" onClick={ () => backButtonEvent? backButtonEvent() : nav(backButtonUri || '') }><ButtonBack /></Button> : null }
            <form onSubmit={(e) => {
                e.preventDefault()
                submitEvent(search)
            }}>
                <div className="d-flex gapped p-3 bkg-light-grey rounded border border-secondary mt-3 search-box-container">
                    <TextField value={ search } label="Search Herd Group" onChange={e => setSearch(e.target.value)} />
                    <Button variant="contained"  onClick={() => submitSearch(search).then((r: IResponse<any>) => {
                        setDataRows(r.data)
                    })}><i className="fas fa-search"></i>Search</Button>
                    <Button variant="contained" disabled={search === ''} onClick={() => {
                        onLoadEvent().then((r: IResponse<any>) => {
                            setSearch('')
                            setDataRows(r.data)
                        })
                    }}><i className="fas fa-eraser"></i>Clear</Button>
                    
                </div>
            </form>

            <div className="mt-3" style={ { height: 'auto' } }>
                <DataGrid
                    getRowId={e => e.ID }
                    onProcessRowUpdateError={(e) => {
                        console.log(e)
                    }}
                    rowModesModel={ rowModesModel }
                    onRowModesModelChange={ setRowModesModel }
                    // onCellEditStart={(e) => {
                    //     setDataTableTarget(e.field)
                    // }}
                    processRowUpdate={(e: GridRowModel) => {
                        if(onUpdateEvent)
                            onUpdateEvent(e)
                        return e
                    }}
                    rows={ dataRows }
                    columns={ dataColumns }
                    checkboxSelection={false}
                />
            </div>
        </div> : <BootStrapSpinner />
    )
}

export default AnimalTableEditorComponent