import { Button, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ModuleComponentsIncrementProviderComponentContext } from "../provider/increment.provider"
import { ApplicationContext } from "../../../providers/application.provider"
import { randomStr } from "../../../helpers/util.helper"
import { ModuleSubmoduleBaseContext } from "../../../providers/submodulebase.provider"
import { count } from "../../../helpers/util.helper"

export const ModuleSubmoduleLocations = () => {
    const { parentName } = useContext(ModuleSubmoduleBaseContext)
    const keyName = parentName || ''
    const { i } = useContext(ModuleComponentsIncrementProviderComponentContext)
    const { editable, currentApp, appLoaded } = useContext(ApplicationContext)
    const [ form, setForm ] = useState<any>({})
    
    const getName = (key: string) => {
        const a: string[] = []
        if(!['', undefined].includes(keyName)) {
            a.push(keyName)
        }
        if(!['', undefined].includes(i)) {
            a.push(i)
        }
        return (count(a) > 0)? `${a.join('.')}.${key}` : key;
    }

    const onChangeEvent = (e: any) => {
        const {name, value} = e.target
        const f = {...form, [name]: value}
        setForm(f)
    }

    const addSubModules = (count: number, fill: any[] = []) => {
        const f = {...form}
        for (let z = 0; z < count; z++) {
            const val = fill[z] || ''
            f[`${getName('locations')}.~${randomStr(10)}~`] = val
        }
        setForm(f)
    }

    const setRemoveKey = (val: string) => {
        const f: any = {}
        Object.keys(form).map((v: any) => {
            if(val !== v)
                f[v] = form[v]
            return v
        })
        setForm(f)
    }

    // useEffect(() => {
    //     if(defaultData && locations) {
    //         addSubModules(locations.length, locations)
    //         setLocations([])
    //     }
    // }, [ defaultData ])

    // useEffect(() => {
    //     if(Object.keys(form).length === 0 && locations.length === 0) {
    //         addSubModules(1)
    //     }
    // })

    useEffect(() => {
        if(appLoaded) {
            if(currentApp?.answers?.locations) {
                if(count(form) === 0) {
                    addSubModules(currentApp?.answers?.locations.length, currentApp?.answers?.locations)
                }
            } else {
                addSubModules(1)
            }
        }
    }, [ currentApp?.answers?.locations, appLoaded ])

    return (
        <div className={`col-count- gapped submod-single-element-${parentName.replace('_', '-').replace('.', '-')}-location`}>
            <h5 className="mt-3">Cattle Locations</h5>
            <p>Tell us a little bit about where you are keeping your herd(s).</p>
            { Object.keys(form).map((v: any, k: number) => {
                return (
                <div key={`location-${k}`} className="d-flex gapped">
                    <TextField
                        label="Location"
                        name={v}
                        value={form[v]}
                        placeholder="ie: North pasture"
                        onChange={onChangeEvent}
                        required
                        disabled={ !editable }
                        fullWidth={true}
                    />
                    <div className='align-self-end'>
                    { (k !== 0) ?
                    <Button
                        disabled={ !editable }
                        variant="contained"
                        className="rounded remove-mod "
                        size="small"
                        onClick={() => {
                            setRemoveKey(v)
                        }
                    } >
                        <i className="my-2 fas fa-trash-alt"></i>
                    </Button> : null
                    }
                    </div>
                </div>
            )})
        }
            <div className='align-middle'>
                <Button
                    variant="contained"
                    className="corp mt-3 rounded add-mod"
                    disabled={!editable}
                    onClick={() => {
                        addSubModules(1)
                    }
                }>
                    <i className="my-2 fas fa-plus-circle"></i>
                </Button>
            </div>
        </div>
    )
}