import { BaseDto } from "./base.dto"

export interface ICattleGroupDto
{
    ID?: string
    uid?: string
    group_name?: string
    certified_at?: string | null
    status?: number
    decentralized?: number
    paid?: boolean
    herd_count?: number
}

export class CattleGroupDto extends BaseDto
{
    public ID: string = '0'
    public uid: string = '0'
    public group_name: string = ''
    public certified_at: string | null = null
    public status: number = 0
    public decentralized: number = 0
    public paid: boolean = false
    public herd_count: number = 0
    constructor(obj:any)
    {
        super()
        this.populate(obj, this)
    }
}