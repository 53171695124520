import { IButtonAny } from "..";
import __ from "../../../../services/translator";

export default function ButtonView({ text }: IButtonAny)
{
    return (
        <span className="strong">
            <i className="fas fa-eye"></i>&nbsp;{ text || __('Edit', 'edit') }
        </span>
    )
}