import { useState } from "react"
import { LocationsGetService } from "../services/locations"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"



export const useLocationsHook = () => {
    const [ locationsState, setLocationsState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ locations, setLocations ] = useState<any>({breeds: [], myBreeds: []})
    const [ gid, setGid ] = useState<string>('')

    if(gid !== '') {
        if(!locationsState.ready) {
            if(!locationsState.loading) {
                setLocationsState(ComponentStateLoadingDto)
                LocationsGetService(gid).then(r => {
                    if(r.success) {
                        setLocations(r.data)
                    }
                    setLocationsState(ComponentStateReadyDto)
                })
            }
        }
    }

    return {
        locationsState, setLocationsState, locations, setGid
    }
}