import { Button } from "@mui/material"
import { IContextChildren } from "../../interfaces/context-children.inteface"
import { useContext } from "react"
import { ApplicationContext } from "../../providers/application.provider"

interface ITemplateCattleProofBlock extends IContextChildren
{
    title: string | JSX.Element
    description: string | JSX.Element
    onBackEvent?: any
}

export const TemplateCattleProofBlock = ({ title, description, onBackEvent, children }: ITemplateCattleProofBlock) => {

    const { setToggleForm, setClearForm } = useContext(ApplicationContext)

    return (
        <div>
            { title? (typeof title !== "string"? title :
            <h4 className='main-header span2 mb-1'>
                <Button
                    onClick={() => {
                        setClearForm(true)
                        setToggleForm(false)
                        if(onBackEvent)
                            onBackEvent()
                    }}
                    className='no-appearance'>
                        <i className="fas fa-chevron-left"></i>
                </Button>&nbsp;<strong>{title}</strong></h4>) : null }
            { description? (typeof description !== "string"? description : <p className='mt-0'>{description}</p>) : null }
            { children }    
        </div>
    )
}