
import { FarFaBell } from "../../../fontawesome"
import { useContext, useEffect } from "react"
import { ModalContext } from "../../../../../providers/modal.provider"
import { signOut } from "../../../../logout"
import { SSOHook } from "../../../../../hooks/sso.hook"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../redux/store"
import Notification, { INotify } from "../../../../../services/notification"
import NotificationsComponent from "../../../notifications"
import { setNotification, setState } from "../../../../../redux/internal.slicer"
import __ from "../../../../../services/translator"
import { ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../../../interfaces/component-state.interface"
// import { AccountContext } from "../../../providers/account.provider"

type INavMainCustomer = {
    setNotificationCount: any,
    eventController: any,
    modalActiveController: any,
    admin: boolean
}

export default function NavMainCustomer(props: INavMainCustomer)
{
    // const notes: INotify = {count:0, notes: []}
    // const [ notifications, setNotifications ] = useState(notes)
    // const [ notificationsState, setNotificationsState ] = useState<IComponentState<any>>({ready: false, loading: false})
    const { setModalActive, setModalBody, setModalTitle, setModalEvent } = useContext(ModalContext)
    const { setTriggerSso } = SSOHook()

    const dispatch = useDispatch();
    const $_notifications = useSelector((state: RootState) => state.notification.summary);
    const $_state = useSelector((state: RootState) => state.notification.state);

    const $_setNotification = (n: INotify) => dispatch(setNotification(n));
    const $_setState = (s: any) => dispatch(setState(s));

    //const { loggedIn } = useContext(AccountContext)
    const fireNotifications = () => {
        setModalTitle(__('Notifications', 'notifications'))
        setModalBody(<NotificationsComponent setNotificationCount={props.setNotificationCount} />)
        // Requires two because it's firing the first on set
        if(setModalEvent)
            setModalEvent(() => () => {
                setModalActive(false)
            })
        setModalActive(true)
    }

    const notificationsState = $_state;
    const notifications = $_notifications;

    useEffect(() => {
        if(!notificationsState.ready) {
            if(!notificationsState.loading) {
                $_setState(ComponentStateLoadingDto);
                Notification({count: true}).then(r => {
                    // Send back the count to parent
                    props.setNotificationCount(r.count);
                    // Set the notifications for this component
                    $_setNotification(r);
                    // Mark ready
                    $_setState(ComponentStateReadyDto);
                })
            }
        }
    }, [ notificationsState.ready ])
    
    //const url: string = import.meta.env.VITE_ACCOUNT_LINK as string
    
    return (
        <ul className="list-menu">
            <li>
                <a href="#notifications" onClick={ fireNotifications }><FarFaBell toggled={notifications.count > 0} styling={notifications.count > 0? 'active' : 'inactive' } />&nbsp;{__('Notifications', 'notifications')}</a>
            </li>
            {!props.admin?
            <>
                <li>
                    <div><a href="#" onClick={() => setTriggerSso(true)} ><i className="fas fa-user"></i>&nbsp;{__('Account', 'account')}</a></div>
                </li>
            </>
            : null }

            <li>
                <div><a href="#" onClick={() => signOut() }><i className="fas fa-sign-out-alt"></i>&nbsp;{__('Sign Out', 'signout')}</a></div>
            </li>
        </ul>
    )
}