import { useEffect, useState } from "react"
import { Tooltip } from "@mui/material"
import { IPreFlightWindow } from "./herdlock.interface"
import { environment } from "../../environmentals/config"
import { count } from "../../helpers/util.helper"
import AlertBox from "../../components/upload/alert"

export const PreFlightWindow = ({ unqualified, validated, preFlightData }: IPreFlightWindow) => {
    
    const [ missingOut ] = useState(preFlightData? preFlightData()?.missing_out_total || 0 : 0)
    const [ failedCertSelection ] = useState<boolean>(count(unqualified().certs) > 0)
    const [ invalidPct, setInvalidPct ] = useState(0)
//    const [ failedCount ] = useState<number>(unqualified().length)

    const lockable: boolean = preFlightData? preFlightData().lockable : false

    useEffect(() => {
        if(preFlightData) {
            if(preFlightData().counts.unable_to_certify && preFlightData().counts.unable_to_certify > 0) {
                if(invalidPct === 0 && preFlightData().qualified_total > 0) {
                    setInvalidPct(preFlightData().counts.unable_to_certify)
                }
            }
        }
    }, [ preFlightData && preFlightData().counts.unable_to_certify ] )

    return (
        <>
            { lockable && 
            <div className="preflight-summary mb-4">
                <h5><strong className={`txt-${validated() && invalidPct === 0 && lockable? 'green' : 'orange'}` }>
                    <i className={ validated() && invalidPct === 0 ? "fas fa-check-double txt-green" : 'fas fa-exclamation-circle txt-orange'}></i>&nbsp;{ environment.preFlightTitle } { validated() && invalidPct === 0 ? 'has no' : 'may have' } issues. { !lockable && 'Your group is not lockable because there are PCTs that are not verified.' }
                </strong></h5>
            </div> }
            { lockable && failedCertSelection && 
            <div className="unqualfied-list">
                <p><strong>You have selected certifications for this group that miss requirements.</strong></p>
                <div className="d-flex justify-content-start gapped">{
                    unqualified().certs.map((r: any, k: number) => (
                        <Tooltip title={ r.cert_description } key={ k }>
                            <div className="fa-fas-icon unqualified align-middle">
                                <i className="alert-icon fas fa-exclamation-triangle"></i>
                                <img src={ r.item_image } alt={ r.cert_title } style={{ maxWidth: 80 }} />
                                <p className="legal" style={{maxWidth: 100, textAlign: 'center'}}>{r.name}</p>
                            </div>
                        </Tooltip>
                    ))
                }</div>
            </div> }
            { invalidPct > 0 &&
                <AlertBox
                    errorClass={ `danger missing-animals` }
                    errorMessage={ `You are missing Program Compliant Tags for ${ invalidPct } animals.` }
                />
            }
            { lockable && missingOut > 0 &&
            <div className="missingout-list">
                {/* <p className="legal"><strong>Based on information provided, you may be missing out on {missingOut > 1? 'up to' : ''} { missingOut } certifications for this group{ failedCertSelection? ` (including the missed-requirements certification${failedCount !== 1? 's' : ''} noted above)` : null }.</strong>. It may be more costly to add those certifications after this process then to add them now.</p> */}

                <p>{ failedCertSelection? "You are not qualified for certifications you've selected." : (invalidPct === 0)? `Your cattle in this group have been scanned for potentially eligible certifications and none have been found. Click Next to continue.` : null }</p>
            </div> }
            { !lockable && <p className="alert alert-warning mt-3">This group can not be committed because there are unverified tags in this list.</p> }
        </>
    )
}