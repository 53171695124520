import { useState } from "react";
import { NavSidebar } from "../nav-sidebar";
import './styles.scss'

export default function NavMobile()
{
    const [ menuToggle, setMenuToggle ] = useState(false)

    return (
        <div className="modile-menu-container">
            <div>
                <a href="#" className={`${menuToggle? `rotate` : ``} transitioning`} onClick={() => setMenuToggle(!menuToggle)}><i className="fas fa-chevron-down fa-2x"></i></a>
            </div>
            { menuToggle? 
            <div className='dropdown-mobile-menu'>
                <NavSidebar clickEvent={() => setMenuToggle(false)} />
            </div> : null }
        </div>
    )
}