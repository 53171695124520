import ImageCompanyLogo from '../../image-company-logo'
import './styles.scss'

export const BootStrapSpinner = ({ size }: ICattleLogoSpinner) => {
   return <CattleLogoSpinner size={ size } />
}

interface ICattleLogoSpinner
{
    size?: string
}

export const CattleLogoSpinner = ({ size }: ICattleLogoSpinner) => {
    return (
        <div className="align-middle">
            <div className={`spin cpv-spin ${ size || 'sm' }`}>
                <ImageCompanyLogo />
            </div>
        </div>
    )
}

export const CircularProgress = ({ size }: ICattleLogoSpinner) => {
    return <CattleLogoSpinner size={size} />
}
