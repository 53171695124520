import { useEffect, useState } from "react"
import { MediaGetService } from "../services/media.service"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import { columnToTitle, empty } from "../helpers/util.helper"
import { http } from "../helpers/client"
import { IAnyType } from "../interfaces/form-builder.interface"
import { IResponse } from "../interfaces/response.interface"

export const useUploadsHook = () => {
    const [ uploads, setUploads ] = useState<any[]>([])
    const [ gid, setGid ] = useState<string>('')
    const [ gidState, setGidState ] = useState<IComponentState<any>>(ComponentStateDto)

    useEffect(() => {
        if(gid !== '') {
            if(!gidState.ready) {
                if(!gidState.loading) {
                    setGidState(ComponentStateLoadingDto)
                    MediaGetService(gid).then(r => {
                        if(r.success)
                            setUploads(r.data)

                        setGidState(ComponentStateReadyDto)
                    })
                }
            }
        } else {
            setUploads([])
        }
    }, [ gid, gidState ])

    return {
        uploads, setUploads, setGid, gidState, setGidState
    }
}


export const useFormProcessingHook = ({
    uri,
    onSuccessEvent,
    backend,
    validate,
}: {
    backend: boolean,
    validate?: IAnyType<(v: any) => boolean>,
    uri: string,
    onSuccessEvent?: (response: IResponse) => void,
    filters?: IAnyType<(v: any) => any>
}) => {
    const [ formSubmitState, setFormSubmitState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ validateErrors, setValidateErrors ] = useState<string[]>([])
    const [ submitForm, setSubmitForm ] = useState<FormData | undefined>(undefined)
    
    const setSubmitEvent = (e: any) => {
        e.preventDefault()
        setFormSubmitState(ComponentStateLoadingDto)
        const formData = new FormData(e.target)
        let errors: string[] = [...validateErrors]
        if(validate) {
            for(const key in validate) {
                if(formData.get(key)) {
                    const n: string = `${columnToTitle(key)} is invalid.`
                    const isValid: boolean = validate[key](formData.get(key))
                    if(isValid) {
                        if(errors.includes(n)) {
                            errors = errors.filter(v => v !== n)
                        }
                    } else {
                        if(!validateErrors.includes(n))
                            errors.push(n)
                    }
                }
            }
        }
        if(errors.length > 0) {
            setValidateErrors(errors)
        } else {
            setSubmitForm(formData)
            setValidateErrors([])
        }

    }
    useEffect(() => {
        if(validateErrors.length > 0) {
            setFormSubmitState({...ComponentStateReadyDto, data: { success: false, error: validateErrors.join(' ') } })
        } else if(submitForm !== undefined) {
            http(backend? 'b' : 'f').put<IResponse<any>>(uri, submitForm).then((r) => {
                if(onSuccessEvent)
                    onSuccessEvent(r)
                setFormSubmitState({...ComponentStateReadyDto, ...r})
            })
        }
    }, [ validateErrors, submitForm ])

    return {
        uri,
        setSubmitEvent,
        formSubmitState,
    }
}