import { useQuery } from 'react-query'
import { useContext, useEffect, useState } from "react"
import { CalvingGroupsGetService } from "../services/calving.groups.service"
import { IResponse } from "../interfaces/response.interface"
import { count } from "../helpers/util.helper"
import Groups, { GroupTypesService, IGroupTypes } from "../services/herd-groups"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import { AccountContext } from '../providers/account.provider'
import { IGroupsListDto } from '../dto/calving-group-form.dto'

export interface IGroupsHook
{
    count: number
    groups: any[]
    uid: string
    valid: boolean
}

export const useGroupsHook = () => {
    const [ groups, setGroups ] = useState<IGroupsHook>({count: 0, groups: [], uid: '', valid: false})
    const [ groupPrefs, setGroupPrefs ] = useState<any>({})
    const [ groupState, setGroupState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ error, setError ] = useState<string>('')
    const { loggedIn } = useContext(AccountContext)

    if(!groupState.ready && loggedIn) {
        if(!groupState.loading) {
            setGroupState(ComponentStateLoadingDto)
            CalvingGroupsGetService(count(groupPrefs) > 0? groupPrefs : null).then((r: IResponse<IGroupsHook | any>) => {
                if(r.success) {
                    setGroups(r.data)
                } else {
                    setError(r.error || 'An error occurred')
                }
                setGroupState(ComponentStateReadyDto)
            })
        }
    }

    return {
        groups, setGroups, setGroupPrefs, error, groupState, setGroupState
    }
}

const fetchGroupTypes = async () => {
    const data = await GroupTypesService()
    
    if(data.success) {
        return data.data
    }

    throw new Error(data.error)
}

export const useGroupTypesHook = () => {
    const [ groupTypesState, setGroupTypesState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ groupTypes, setGroupTypes ] = useState<IGroupTypes[]>([])

    const { data, isLoading, refetch } = useQuery('groupTypes', fetchGroupTypes, {enabled: false})

    const getItemValue = (id: number, gt: IGroupTypes[], key: string) => {
        let g: any
        gt.map(v => {
            if(v.type_id === id)
                g = v[key as keyof typeof v] || ''
        })
        return g
    }
    if(!groupTypesState.ready && !data && groupTypes.length === 0) {
        if(!groupTypesState.loading) {
            setGroupTypesState(ComponentStateLoadingDto)
            refetch()
        }
    }

    if(data && groupTypesState.loading && !groupTypesState.ready && !isLoading) {
        setGroupTypes(data)
        setGroupTypesState(ComponentStateReadyDto)
    }

    if(data && !groupTypesState.loading && !groupTypesState.ready && groupTypes.length === 0) {
        setGroupTypes(data)
        setGroupTypesState(ComponentStateReadyDto)
    }

    return {
        groupTypesState,
        groupTypes,
        getItemValue
    }
}

export const useHerdGroupsHook = () => {
    const [ refresh, setRefresh ] = useState<boolean>(false)
    const [ groups, setGroups ] = useState<IGroupsListDto>({valid: false, count: 0})
    const [ groupState, setGroupState ] = useState<IComponentState<any>>(ComponentStateDto)
    const { account } = useContext(AccountContext)

    useEffect(() => {
        if(refresh) {
            setRefresh(false)
            setGroupState(ComponentStateDto)
        }
    }, [ refresh ])

    useEffect(() => {
        if(!groupState.ready) {
            if(!groupState.loading) {
                setGroupState(ComponentStateLoadingDto)
                Groups(account).then((r: IGroupsListDto) => {
                    setGroupState(ComponentStateReadyDto)
                    // setGroupState({ ready: GroupsLeftOpen(r.groups || []), loading: false })
                    setGroups(r)
                })
            }
        }
    }, [ groupState.ready ])

    return {
        groups, setGroups, groupState, setGroupState, setRefresh
    }
}