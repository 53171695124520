import { Button } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { IAnyType } from "../../interfaces/form-builder.interface"
import { count, randomStr } from "../../helpers/util.helper"
import { ModuleSubmoduleBaseContext } from "../../providers/submodulebase.provider"
import { IContextChildren } from "../../interfaces/context-children.inteface"
import { ApplicationContext } from "../../providers/application.provider"
import { ModuleComponentsIncrementProviderComponent } from "./provider/increment.provider"

interface IModuleSubmoduleBaseComponent extends IContextChildren
{
    start: number,
    minCount?: number
}

export interface IModuleParent
{
    parentName?: string
}

export const ModuleSubmoduleBaseComponent = ({ start, minCount, children }: IModuleSubmoduleBaseComponent) => {
    const [ items, setItems ] = useState<IAnyType<JSX.Element>>({})
    const [ data, setData ] = useState<any[]>([])
    const [ min ] = useState<number>(minCount || 0)
    const [ removeKey, setRemoveKey ] = useState<any>({remove: false, key: ''})
    const [ firstRun, setFirstRun ] = useState<boolean>(true)
    const [ firstRunEffect, setFirstRunEffect ] = useState<boolean>(true)
    const { parentName, onRemoveEvents, setOnRemoveEvents } = useContext(ModuleSubmoduleBaseContext)
    const { currentApp, editable } = useContext(ApplicationContext)

    const answers = currentApp.flattened || {}

    const renderRow = (keyName: string, classNameElem: string, k: number) => {
        return (
        <div className={`submod-single-element-${classNameElem.replace('_', '-').replace('.', '-')}`}>
            { children }
            { (k >= min) ?
            <div className="d-flex justify-content-end">
                <div className='align-self-end'>
                <Button
                    variant="contained"
                    className="corp rounded remove-mod "
                    onClick={() => setRemoveKey({remove: true, key: keyName})}
                    disabled={!editable}
                >
                    <i className="my-2 fas fa-trash-alt"></i>
                </Button>
                </div>
            </div> : null
                }
        </div>
        )
    }

    const addSubModules = (count: number) => {
        const f: {[key: string]: any } = {...items}
        let k = Object.keys(items).length
        for(let i = 0; i < count; i++) {
            const classNameElem = parentName
            const keyName: string = `${ classNameElem }-${randomStr(10)}-${k}`
            f[keyName] = renderRow(keyName, classNameElem, k)
            k++
        }
        setItems(f)
    }

    const addSubModule = () => {
        const k = Object.keys(items).length
        const classNameElem = parentName
        const keyName = `${ classNameElem }-${randomStr(10)}-${k}`
        setItems(arr => { return {...arr, [keyName]: renderRow(keyName, classNameElem, k)}})
    }

    const recurseGet = (obj: any, arr: any []) => {
        const fKey: any = arr.splice(0, 1);
        let a = obj[fKey]
        if(count(arr) > 0) {
            a = recurseGet(a, arr)
        }
        return a
    }

    const breakOutArrayFromAnswers = (answers: any): any => {
        let found: any = undefined
        const store: string[] = []
        if(!answers)
            return null

        Object.keys(answers).map(r => {
            const match = r.match(new RegExp(`${parentName}.~`, 'gi'))
            if(match) {
                if(!store.includes(parentName)) {
                    store.push(parentName)
                    found = recurseGet(currentApp.answers, parentName.split('.'))
                }
            }
        })
        return found
    }

    if(removeKey.key !== '') {
        const it = {...items}
        delete it[removeKey.key]
        setItems(it)
        setRemoveKey((arr: any) => ({...arr, key: ''}))
        setData([])
    }
    // This fetches out of the answer pool and determines if there is any
    // submodule data to use
    useEffect(() => {
        if(firstRun) {
            if(count(answers) > 0 && firstRunEffect) {
                const dataPoints = breakOutArrayFromAnswers(answers)
                if(dataPoints && data.length === 0) {
                    const c = count(items)
                    const b = count(dataPoints)
                    const diff = +b - +c
                    setData(dataPoints)
                    if(c < b) {
                        addSubModules(diff)
                    }
                }
                setFirstRunEffect(false)
            } else {
                // if((count(items) < start) && firstRun) {
                //     addSubModule()
                // }
            }
            setFirstRun(false)
        }
    }, [ answers ])
    
    useEffect(() => {
        if((count(items) < start) && !firstRun) {
            addSubModule()
        }
    })
    
    // This will run any remove events that were added by the submnodule
    useEffect(() => {
        if(removeKey.remove) {
            if(onRemoveEvents) {
                onRemoveEvents()
                setOnRemoveEvents(undefined)
            }
            setRemoveKey({remove: false, key: ''})
        }
    }, [ items ])
    // Clears out the data after it's been put into the view
    // This way if you delete a submodule, it will not have the saved
    // data anymore
    useEffect(() => {
        if(count(data) > 0 && !firstRunEffect && !firstRun) {
            setData([])
        }
    })
    let z = 0
    return (
        <div>
            {
                Object.keys(items).map(v => {
                    const d = data[z]
                    z++
                    return (
                    <span key={`sm-${v}`}>
                        <ModuleComponentsIncrementProviderComponent
                            increment={`~${randomStr(10)}~`}
                            data={ d }
                        >
                        { items[v] }
                        </ModuleComponentsIncrementProviderComponent>
                    </span>
                    )
                })
            }
            <div className='align-middle'>
                <Button
                    disabled={!editable}
                    variant="contained"
                    className="corp mt-3 rounded add-mod"
                    onClick={() => {
                        addSubModule()
                        setData([])
                    }
                }>
                    <i className="my-2 fas fa-plus-circle"></i>
                </Button>
            </div>
        </div>
    )
}

export const getVal = (form: any, name: string, def: any = '') => form[name] || def

export const isChecked = (form: any, name: string, def: any = false): boolean => form[name] || def

export const onCheckChangeEvent = (e: any, setForm: any) => {
  const { name, checked } = e.target
  setForm((arr: any) => ({...arr, [name]: checked}))
}

export const handleInputReplace = (value: any, replace?: string) => {
    let v = value
    if (replace) {
        v = v.replace(new RegExp(replace), '')
    }
    return v
}

export const onTextChangeEvent = (e: any, setForm: any, replace?: string, max?: number) => {
  const { name, value } = e.target
  let v = replace? handleInputReplace(value, replace) : value
  if(max)
    v = String(v).substring(0, max)
  setForm((arr: any) => ({...arr, [name]: v }))
}