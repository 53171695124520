import { BaseDto } from "../dto/base.dto"
import HttpClient from "../helpers/client"
import { IResponse } from "../interfaces/response.interface"
import { ICalvingGroupForm } from "./herd-groups"


export class WidgetCommittedHerdsDto extends BaseDto
{
    public count: number = 0
    public groups: ICalvingGroupForm[] = []
    constructor(obj: any)
    {
        super()
        this.populate(obj, this)
    }
}

export function InvoicesPending<WidgetCommittedHerdsDto>(): Promise<WidgetCommittedHerdsDto>
{
    return new Promise<WidgetCommittedHerdsDto>((success, fail) => {
        try {
            return (new HttpClient()).get<WidgetCommittedHerdsDto>(`invoices/pending`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export class InvoicesPayDto extends BaseDto
{
    public success: boolean = false
    public url: string = ''
    constructor(obj: any)
    {
        super()
        this.populate(obj, this)
    }
}

export interface InvoicesPayHeartlandDto extends IResponse<any>
{

}

export function InvoicesPayAll<InvoicesPayDto>(): Promise<InvoicesPayDto>
{
    return new Promise<InvoicesPayDto>((success, fail) => {
        try {
            return (new HttpClient()).get<InvoicesPayDto>(`checkout/bulkpay`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function InvoicesPay<InvoicesPayDto>(gid: string, qty: number): Promise<InvoicesPayDto>
{
    return new Promise<InvoicesPayDto>((success, fail) => {
        try {
            return (new HttpClient()).get<InvoicesPayDto>(`checkout/${gid}/${qty}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function InvoicesPayGeneral<InvoicesPayDto>(order: any): Promise<InvoicesPayDto>
{
    return new Promise<InvoicesPayDto>((success, fail) => {
        try {
            return (new HttpClient()).post<InvoicesPayDto>(`checkout/order`, order).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export function AnnualFeePay<InvoicesPayDto>(): Promise<InvoicesPayDto>
{
    return new Promise<InvoicesPayDto>((success, fail) => {
        try {
            return (new HttpClient()).get<InvoicesPayDto>(`checkout/subscription`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}