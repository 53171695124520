import { useState } from "react"
import { HeartlandIFrameComponent } from "../../heartland-iframe"
import { ShoppingCartGetService } from "../../services/shopping-cart.service"
import { CattleLogoSpinner } from "../../../ui/views/spinner"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../../interfaces/component-state.interface"

const PaymentAnnualFee = () => {
    const [ cart, setCart ] = useState<IComponentState<any>>(ComponentStateDto)
    
    if(!cart.ready) {
        if(!cart.loading) {
            setCart(ComponentStateLoadingDto)
            ShoppingCartGetService({
                itemcodes: [
                    { sku: 'cpvmem', qty: 1 }
            ]}).then(r => {
                setCart({ ...ComponentStateReadyDto, data: r.success? r.data : {} })
            })
        }
    }

    return (
        cart.ready? <HeartlandIFrameComponent cart={ cart.data || {} } /> : <CattleLogoSpinner />
    )
}

export default PaymentAnnualFee