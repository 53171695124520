import { Button } from "@mui/material"
import { SSOHook } from "../../../../hooks/sso.hook"
import * as WI from "../../../../interfaces/wizard.interface"

/**
 * @description Instructions while before video
 */
export const WizardSignUpTestIntroduction = ({ setStartViewing }: WI.IWizardStartUpTestVideo) => {
    const { setTriggerSso } = SSOHook()

    return (
        <div className="video-inactive bkg">
            <h3>CattleProof Verified Training 101</h3>
            <p className="mb-2">Thank you for getting started with the verification process! We are sure you will love the program, but it is required that you answer a few questions related to the training manual. When you answer the questions correctly you will then be able to start the process of Herd Certifications.</p>
            <div className="align-middle mt-4">
                <div className="col-count-2 gapped col-c1-md">
                    <Button variant="outlined" onClick={() => setTriggerSso(true)}>Log Out</Button>
                    <Button variant="contained" className="corp" onClick={() => setStartViewing(true)}>Let's Start!</Button>
                </div>
            </div>
        </div>
    )
}