import { useState } from "react"
import { IContextChildren } from "../../../../interfaces/context-children.inteface"
import ImageCompanyLogo from "../../image-company-logo"
import './styles.scss'
import { Button } from "@mui/material"

export interface IViewMainWindow extends IContextChildren
{
    alertBody?: string | JSX.Element,
    hideLogo?: boolean,
    hideBkg?: boolean,
    expandable?: boolean,
    logoSize?: string,
    className?: string
    wrapperClassName?: string
}

export const viewMainWindowGreyBackgroundPreset = {
    logoSize:'small',
    className: 'p-4 my-4 bkg-light',
    wrapperClassName: 'full-height d-flex align-middle bkg-light'
}

export default function ViewMainWindow({
    alertBody,
    hideLogo,
    hideBkg,
    logoSize,
    children,
    className,
    wrapperClassName,
    expandable,
}: IViewMainWindow) {
    const [ expanded, setExpanded ] = useState<boolean>(false)
    const hide = (typeof hideLogo === "undefined")? false : hideLogo
    return (
        <div className={wrapperClassName || `full-height d-flex align-middle cattle-bkg-001 clouded`}>
            <div className={`${expanded? `expanded-view` : ''} modal-window-container ${ className || `p-4 my-4 rounded mx-2${hideBkg || ' bkg-white shadowed'}`}`}>
                { expandable? <div className="modal-window-header d-flex justify-content-end"><Button className="no-appearance" onClick={() => setExpanded(!expanded)}><i className={`zoom-in-out pointer fas fa-${expanded? 'compress' : 'expand'} fa-lg`}></i></Button></div> : null }
                <div className={expanded? 'align-middle' : ''} style={expanded? {height: 'calc(100% - 20px)'} :{} }>
                    <div>
                        { !hide? 
                        <div className={`align-middle mb-2 logo-central ${logoSize? logoSize : ''}`}>
                            <ImageCompanyLogo />
                        </div> : null }
                        { alertBody || null }
                        <div className="align-middle pb-4">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}